// import React, { useState } from "react";
// import { Box, Button, IconButton, Modal, TextField, /* other imports */ 
// Typography} from "@mui/material";
// import { Close } from "@mui/icons-material";
// import { Description } from "./OtpModalStyle";
// import ResetPassModal from "./ResetPassModal";
// const OTPModal = ({ open, handleClose }) => {
//     const [nextModalOpen, setNextModalOpen] = useState(false);

//     const handleNextClick = () => {
//       setNextModalOpen(true);
//     };
  
//     const handleNextModalClose = () => {
//       setNextModalOpen(false);
//     };
//   return (
//     <>
//     <Modal
//     open={open}
//     onClose={handleClose}
//     aria-labelledby="otp-modal-title"
//     aria-describedby="otp-modal-description"
//   >
//     <div
//       style={{
//         backgroundColor: "#FFFFFF",
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         bgcolor: "background.paper",
//         boxShadow: 24,
//         padding: "25px",
//         borderRadius: "16px",
//       }}
//     >
//       {/* Title and Close icon */}
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         marginLeft="100px"
//       >
//         <Typography
//           style={{
//             fontWeight: "500",
//             marginLeft: "8px",
//             color: "#004A80",
//             fontSize: "20px",
//           }}
//         >
//           Reset Your Password
//         </Typography>
//         <IconButton onClick={handleClose} style={{ cursor: "pointer" }}>
//           <Close />
//         </IconButton>
//       </Box>
  
     
  
//       {/* Description */}
//       <div>
//         <Description style={{ }}>
//           An email has been sent. It contains a code, use code to reset your password. Note: You can only request a new password once within 2 hours.
//         </Description>
//       </div>

   
// <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
// <TextField
// variant="outlined"
// type="text"
// inputProps={{ maxLength: 1 }}
// sx={{
//   width: "60px",
//   marginRight: "10px",
//   textAlign: "center",
//   bgcolor: "#DBE5ED", // Set your desired background color
// }}
// />

//   <TextField
//     variant="outlined"
//     type="text"
//     inputProps={{ maxLength: 1,  }}
//     sx={{
//         width: "60px",
//         marginRight: "10px",
//         textAlign: "center",
//         bgcolor: "#DBE5ED", // Set your desired background color
//       }}
//   />
//   <TextField
//     variant="outlined"
//     type="text"
//     inputProps={{ maxLength: 1,  }}
//     sx={{
//         width: "60px",
//         marginRight: "10px",
//         textAlign: "center",
//         bgcolor: "#DBE5ED", // Set your desired background color
//       }}
//   />
//   <TextField
//     variant="outlined"
//     type="text"
//     inputProps={{ maxLength: 1,  }}
//     sx={{
//         width: "60px",
//         marginRight: "10px",
//         textAlign: "center",
//         bgcolor: "#DBE5ED", // Set your desired background color
//       }}
//   />
// </div>
  
//       {/* Next Button */}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "13px",
//         }}
//       >
//         <Button
//           style={{
//             textTransform: "capitalize",
//             background: "#004A80",
//             color: "white",
//           }}
//            onClick={handleNextClick}
//         >
//           Next
//         </Button>
//       </div>
//     </div>
//   </Modal>
//     {/* New modal from a separate component */}
//     <ResetPassModal open={nextModalOpen} handleClose={handleNextModalClose} />
//   </>
//   );
// };

// export default OTPModal;


import React, { useState } from "react";
import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Description } from "../../styles/modalsStyles/OtpModalStyle";
import ResetPassModal from "./ResetPassModal";

const OTPModal = ({ open, handleClose }) => {
  const [nextModalOpen, setNextModalOpen] = useState(false);

  const handleNextClick = () => {
    setNextModalOpen(true);
  };

  const handleNextModalClose = () => {
    setNextModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="otp-modal-title"
        aria-describedby="otp-modal-description"
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "25px",
            borderRadius: "16px",
          }}
        >
          {/* Title and Close icon */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginLeft="100px"
          >
            <Typography
              style={{
                fontWeight: "500",
                marginLeft: "8px",
                color: "#004A80",
                fontSize: "20px",
              }}
            >
              Reset Your Password
            </Typography>
            <IconButton onClick={handleClose} style={{ cursor: "pointer" }}>
              <Close />
            </IconButton>
          </Box>

          {/* Description */}
          <div>
            <Description style={{}}>
              An email has been sent. It contains a code, use code to reset your password. Note: You can only request a new password once within 2 hours.
            </Description>
          </div>

          
 <div style={{ marginTop: "15px", display: "flex", justifyContent: "center" }}>
 <TextField
variant="outlined"
type="text"
inputProps={{ maxLength: 1 }}
sx={{
  width: "60px",
  marginRight: "10px",
  textAlign: "center",
  bgcolor: "#DBE5ED", // Set your desired background color
}}
/>

  <TextField
    variant="outlined"
    type="text"
    inputProps={{ maxLength: 1,  }}
    sx={{
        width: "60px",
        marginRight: "10px",
        textAlign: "center",
        bgcolor: "#DBE5ED", // Set your desired background color
      }}
  />
  <TextField
    variant="outlined"
    type="text"
    inputProps={{ maxLength: 1,  }}
    sx={{
        width: "60px",
        marginRight: "10px",
        textAlign: "center",
        bgcolor: "#DBE5ED", // Set your desired background color
      }}
  />
  <TextField
    variant="outlined"
    type="text"
    inputProps={{ maxLength: 1,  }}
    sx={{
        width: "60px",
        marginRight: "10px",
        textAlign: "center",
        bgcolor: "#DBE5ED", // Set your desired background color
      }}
  />
</div>

          {/* Next Button */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "13px",
            }}
          >
            <Button
              style={{
                textTransform: "capitalize",
                background: "#004A80",
                color: "white",
              }}
              onClick={handleNextClick}
            >
              Next
            </Button>
          </div>
        </div>
      </Modal>

      {/* New modal from a separate component */}
      <ResetPassModal open={nextModalOpen} handleClose={() => {
        handleNextModalClose(); // Close the next modal
        handleClose(); // Close the OTP modal
      }} />
    </>
  );
};

export default OTPModal;
