import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSingleVideoByIDQuery, useUpdateVideoDataMutation } from "../../features/video/VideoSlice";

export const useEditVideo = (id) => {
  const navigate = useNavigate();
  const [editVideoForm, setEditVideoForm] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
    subject: "",
    chapter: "",
    Videoname: "",
    description: "",
    uploadfile: "",
  });
  // console.log("editPdfForm", editPdfForm)

  const {
    data: singlepdf,
    isLoading,
    isSuccess,
  } = useGetSingleVideoByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  // const categoryId = singlechapter?.category?.categoryId;
  useEffect(() => {
    if (singlepdf) {
        setEditVideoForm({
        categoryId: singlepdf?.video?.categoryId,
        subcategoryId: singlepdf?.video?.subcategoryId,
        Addlevel: singlepdf?.video?.Addlevel,
        subject: singlepdf?.video?.subject,
        chapter: singlepdf?.video?.chapter,
        videoname: singlepdf?.video?.videoname,
        description: singlepdf?.video?.description,
        uploadfile: singlepdf?.video?.uploadfile,
        // subject: "65cc5de36ca1435880f630da",
        // uploadfile: "http://preparlyapi-env.eba-u4ipsxzx.us-east-1.elasticbeanstalk.com/uploads/chapter-Pg2UnV3cG-chapter.jpeg",

        // exambanner: singlechapter?.category?.exambanner,
        // Update with additional fields if needed
      });
    }
  }, [singlepdf]);
    // console.log(singlepdf, "singlepdfsinglepdfsinglepdfsinglepdfsinglepdf");

  const imageUrl = editVideoForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditVideoForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0] || null;
    setEditVideoForm({ ...editVideoForm, uploadfile: file });
  };

  const [UpdatePdfData] = useUpdateVideoDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("categoryId", editVideoForm?.categoryId);
      formData.append("subcategoryId", editVideoForm?.subcategoryId);
      // formData.append("Addlevel", editVideoForm.Addlevel);
      formData.append("subject", editVideoForm?.subject);
      formData.append("chapter", editVideoForm?.chapter);
      formData.append("Videoname", editVideoForm?.videoname);
      formData.append("description", editVideoForm?.description);
      formData.append("uploadfile", editVideoForm?.uploadfile);
      //   formData.append("exambanner", editVideoForm.exambanner);

      // Update with additional fields if needed

      const response = (
        await UpdatePdfData({
          id,
          data: formData,
        })
      ).data;
      console.log("responseresponseresponse", response);
      if (response.success === true) {
        window.alert(response.message);
        navigate("/viewVideo");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editVideoForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};
