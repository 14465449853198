import React, { useState } from "react";
import {
  Modal,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { SelectFieldsHeads, NameField } from "../../styles/modalsStyles/ForgotStyle";
import EmailIcon from "@mui/icons-material/Email";
import { Close } from "@mui/icons-material";
import OTPModal from "./OtpModal";

const ForgotPassModal = ({ open, handleClose }) => {
  const [otpModalOpen, setOTPOpen] = useState(false);

  const handleOTPClose = () => {
    setOTPOpen(false);
    handleClose(); // Close the ForgotPassModal when OTPModal is closed
  };

  const handleForgotSubmit = () => {
    // Logic for handling the Submit button click
    // Open OTP modal
    setOTPOpen(true);
  };

  return (
    <>
      {!otpModalOpen && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="forget-password-modal"
          aria-describedby="modal-to-reset-password"
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              padding: "25px",
              borderRadius: "16px",
            }}
          >
            {/* Add your content for the modal here */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontWeight: "500",
                  marginLeft: "8px",
                  color: "#004A80",
                  fontSize: "20px",
                }}
              >
                Reset Your Password
              </Typography>
              <Close onClick={handleClose} style={{ cursor: "pointer" }} />
            </Box>
            <div>
              <Typography style={{ marginLeft: "8px", fontSize: "13px" }}>
                Enter your email below to receive a link to reset your password.
              </Typography>
            </div>
            <div>
              <SelectFieldsHeads>Email Address</SelectFieldsHeads>
            </div>
            <div>
              <div>
                <NameField
                  style={{ border: "1px solid gray" }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          style={{
                            paddingBottom: "2px",
                            paddingTop: "2px",
                            borderRight: "1px solid gray",
                            borderRadius: "0px",
                            paddingLeft: "2px",
                            color: "#004A80",
                            "&:hover": {
                              background: "none", // Set background to none on hover
                            },
                          }}
                        >
                          <EmailIcon /> {/* Your message icon */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "13px",
              }}
            >
              <Button
                style={{
                  textTransform: "capitalize",
                  background: "#004A80",
                  color: "white",
                }}
                onClick={handleForgotSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {/* OTP Modal */}
      {otpModalOpen && (
        <OTPModal open={otpModalOpen} handleClose={handleOTPClose} />
      )}
    </>
  );
};

export default ForgotPassModal;
