import React, { useState, useEffect } from "react";
import {
  useGetSingleCategoryByIDQuery,
  useUpdateCategoryDataMutation,
} from "../../features/category/CategorySlice";
import { useNavigate } from "react-router-dom";

export const useEditCategory = (id) => {
  const navigate = useNavigate();
  const [editCategoryForm, setEditCategoryForm] = useState({
    examname: "",
    full_name: "",
    logo: "",
    exambanner: "",
    // Add more fields as needed
  });

  const {
    data: singlecategory,
    isLoading,
    isSuccess,
  } = useGetSingleCategoryByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  // this the edit category module
  // this the edit category module
  useEffect(() => {
    if (singlecategory) {
      setEditCategoryForm({
        examname: singlecategory?.category?.examname,

        full_name: singlecategory?.category?.full_name,

        Full_name: singlecategory?.category?.full_name,

        logo: singlecategory?.category?.logo,
        exambanner: singlecategory?.category?.exambanner,
        // Update with additional fields if needed
        // Update with additional fields if needed
      });
    }
  }, [singlecategory]);
  console.log("singlecategorysinglecategorysinglecategorysinglecategory", singlecategory)

  const imageUrl = editCategoryForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditCategoryForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    
    // Check if name is logo or exambanner
    if (name === 'logo') {
      setEditCategoryForm({ ...editCategoryForm, logo: file });
    } else if (name === 'exambanner') {
      setEditCategoryForm({ ...editCategoryForm, exambanner: file });
    }
  };
  const [UpdateCategoryData] = useUpdateCategoryDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("examname", editCategoryForm.examname);
      formData.append("full_name", editCategoryForm.full_name);
      formData.append("logo", editCategoryForm.logo);
      formData.append("exambanner", editCategoryForm.exambanner);

      // Update with additional fields if needed

      const response = (await UpdateCategoryData({
        id,
        data: formData,
      })).data;

      if (response.success === true) {
        window.alert(response.message);
        navigate("/viewExamTable");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editCategoryForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};