import React from 'react'
import AddBannerForm from '../../components/Bannercomponents/AddBannerForm'

const AddBanner = () => {
  return (
<>
<AddBannerForm/>
 </>
  )
}

export default AddBanner