import React from 'react'
import EditCategory from "../../../components/categorycomponents/addexam/EditCategory"


const EditCat  = () => {
  return (
 <>
<EditCategory/>
 </>
  )
}

export default EditCat