import { Button, IconButton, TablePagination } from "@mui/material";
import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import {
  SameStyleCells,
  SpansButtons,
} from "../../styles/mockteststyle/ViewMockTableStyle";
import ButtonsComponent from "../globalcomponents/SecondTopBarOutlet/ButtonsComponent";
import { useGetMocktest } from "../../customhook/MocktestHook/useGetMocktest";
// import { useMCQSTableFunction } from "../../customhook/MCQSHook/useMcqsTableFun";
import { useMocktestTableFunction } from "../../customhook/MocktestHook/useMockTableFun";

const ViewMockTable = () => {
  const { MocktestData, isLoading, isSuccess } = useGetMocktest();

  // console.log("MocktestData", MocktestData);
  const {
    handleActivate,
    handleDeActivate,
    handleView,
    handleDelete,
    handleEdit,
  } = useMocktestTableFunction();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <ButtonsComponent />
      <div style={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          style={{ border: "1px solid #DEDEDE" }}
        >
          <Table>
            <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Exam Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Field</TableCell>
                {/* <TableCell style={{ fontWeight: 'bold' }}>Level</TableCell> */}
                {/* <TableCell style={{ fontWeight: 'bold' }}>Subject</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>mockname</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Question</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {MocktestData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {row.categoryId
                      ? row.categoryId.examname
                      : "No AddExam Added"}
                  </TableCell>

                  <TableCell>
                    {row.subcategoryId
                      ? row.subcategoryId.subname
                      : "No AddField Added"}
                  </TableCell>
                  <TableCell>
                    {row.mockname ? row.mockname : "No mockname Added"}
                  </TableCell>
                  {/* <TableCell>
                    {row.subject ? row.subject.subject : "No Subject Added"}
                  </TableCell>
                  <TableCell>
                    {row.chapter ? row.chapter.chapter : "No Chapter Added"}
                  </TableCell> */}
                  <TableCell>
                    {row.questions && row.questions.length > 0
                      ? row.questions.map((question, index) => (
                          <div key={index}>
                            {question.question
                              ? question.question
                              : "No Question Added"}
                          </div>
                        ))
                      : "No Question Added"}
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row.id)}>
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          // count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ViewMockTable;

