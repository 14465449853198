import { useNavigate } from "react-router-dom";
import { useDeleteUserMutation } from "../../features/user/UserSlice";

export const useUserTableFunction = () => {
    const navigate = useNavigate();
    // const [activate] = useGetActivatMocktestMutation();
    // const [deactivate] = useGetDeActivateMocktestMutation();
    const [deleteData] = useDeleteUserMutation();
 
    const handleView = (id) => {
      navigate(`/ViewSingleUserCat/${id}`);
    };
    const handleEdit = (id) => {
      navigate(`/EditMocktest/${id}`);
    };
  
    const handleDelete = async (id) => {
      // try {
      //   const response = (await deleteData(id).unwrap()) ;
      //   if (response.data.success === true) {
      //     window.alert(response.data.message);
      //   }
      // } catch (error) {
      //   console.error("Error activating banner", error);
      // }

      try {
        const response = await deleteData(id).unwrap();
        if (response) {
          window.alert("Given User " + response.message);
        }
      } catch (error) {
        console.error("Error deleting  Data", error);
      }
    };
  
    // const handleActivate = async (id) => {
    //   try {
    //     const response = await activate(id).unwrap();
    //     if (response) {
    //       window.alert("Given MOCK " + response.message);
    //     }
    //   } catch (error) {
    //     console.error("Error activating banner", error);
    //   }
    // };
   
  
    // const handleDeActivate = async (id) => {
    //   try {
    //     const response = await deactivate(id).unwrap();
    //     if (response) {
    //       window.alert("Given MOCK " + response.message);
    //     }
    //   } catch (error) {
    //     console.error("Error activating banner", error);
    //   }
    // };
  
    return {
      handleView,
      handleEdit,
    //   handleActivate,
    //   handleDeActivate,
      handleDelete,
    };
  };
  