import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useGetSinglePdfByIDQuery } from "../../../features/pdf/PdfSlice";
import { useGetSingleMocktestByIDQuery } from "../../features/Mock Test/MocktestSlice";

const ViewSingleMockCat = () => {
  const params = useParams();
  const location = useLocation();

  //   console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleMocktestByIDQuery(
    params.id
  );
  // console.log(
  //   "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAa",
  //   data?.data
  // );
  console.log("data", data);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data) {
    return <div>Error loading data...</div>;
  }

  const { MockData } = data;
  // console.log("McqsData", McqsData);

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            uploadfile:
          </Typography>
          <img
            src={level?.uploadfile}
            alt="Logo"
            style={{
              width: "180px",
              height: "160px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid> */}
        {/* Right Side - Exam Name, Full Name, and Active Status */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>

          <Typography>{data?.data?.categoryId?.examname}</Typography>

          {/* <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>
          {data?.data?.categoryId?.map((category) => (
            <Typography key={category._id}>{category.examname}</Typography>
          ))||[]} */}

          <Typography variant="h6" color="primary">
            Field Name:
          </Typography>
          <Typography>{data?.data?.subcategoryId?.subname}</Typography>

          <Typography variant="h6" color="primary">
            Mock Name:
          </Typography>
          <Typography>{data?.data?.mockname}</Typography>

          {data?.data?.questions.map((questionData, index) => (
            <div key={index}>
              <Typography variant="h6" color="primary">
                Question:
              </Typography>
              <Typography>{questionData?.question}</Typography>

              <Typography variant="h6" color="primary">
                Correct Answer:
              </Typography>
              <Typography>{questionData?.correct_answer}</Typography>

              <Typography variant="h6" color="primary">
                Incorrect Answers:
              </Typography>
              <ul>
                {questionData?.incorrect_answer?.map((incorrect, idx) => (
                  <li key={idx}>{incorrect}</li>
                ))}
              </ul>
            </div>
          ))}


          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Status:
          </Typography>
          <Typography
            style={{
              color: data?.data?.isActive ? activeColor : inactiveColor,
            }}
          >
            {data?.data?.isActive ? "Active" : "Inactive"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewSingleMockCat;
