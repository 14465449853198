import { Button, IconButton, TablePagination } from "@mui/material";
import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import {
  SameStyleCells,
  SpansButtons,
} from "../../styles/userstyle/ViewUserStyle";
import ButtonsComponent from "../globalcomponents/SecondTopBarOutlet/ButtonsComponent";
import { useGetUser } from "../../customhook/User/UseGetUser";
import { useUserTableFunction } from "../../customhook/User/useUserTableFun";

const ViewPastPaperTable = () => {
  const { UserData, isLoading, isSuccess } = useGetUser();
  console.log("UserData", UserData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    handleView,
    handleEdit,
  //   handleActivate,
  //   handleDeActivate,
    handleDelete,
  } = useUserTableFunction();
  // Sample data for the table (Replace this with your actual data)
  const rows = [
    {
      id: 1,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
    {
      id: 2,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
    {
      id: 3,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
    {
      id: 4,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
    {
      id: 5,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
    // Five more rows
    {
      id: 6,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
    {
      id: 7,
      examName: "",
      field: "",
      level: "",
      subject: "",
      chapter: "",
      pdf: "",
      status: "",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <ButtonsComponent />
      <div style={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          style={{ border: "1px solid #DEDEDE" }}
        >
          <Table>
            <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
              <TableRow>
                <TableCell></TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>ID</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>UserName</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                {/* <TableCell style={{ fontWeight: 'bold' }}></TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>Role</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Phone Number
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>Status</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {UserData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {row.username ? row.username : "No Username Added"}
                  </TableCell>
                  <TableCell>
                  {row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : "No Name Added"}
                  </TableCell>
                  <TableCell>
                    {row.role ? row.role : "No Role Added"}
                  </TableCell>
                  <TableCell>
                    {row.email ? row.email : "No Email Added"}
                  </TableCell>
                  <TableCell>
                    {row.phonenumber
                      ? row.phonenumber
                      : "No Phone Number Added"}
                  </TableCell>
                  {/* <TableCell>
                    {row.questions && row.questions.length > 0
                      ? row.questions.map((question, index) => (
                          <div key={index}>
                            {question.question
                              ? question.question
                              : "No Question Added"}
                          </div>
                        ))
                      : "No Question Added"}
                  </TableCell> */}
                  {/* <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell> */}
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row.id)}>
                        <i
                          className="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {/* <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const allFieldsFilled = (
                row.examName &&
                row.field &&
                row.level &&
                row.subject &&
                row.chapter &&
                row.pdf &&
                row.status
              );
          
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: '#EEEEEE' }} />
                  </TableCell>
                  <TableCell>{row.examName}</TableCell>
                  <TableCell>{row.field}</TableCell>
                  <TableCell>{row.level}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{row.chapter}</TableCell>
                  <TableCell>{row.pdf}</TableCell>
                  <TableCell style={{ color: 'green', fontWeight: 'bold' }}>{row.status}</TableCell>
                  <TableCell>
                    {allFieldsFilled && (
                      <>
                        <SpansButtons>
                          <i className="fas fa-edit"></i>
                        </SpansButtons>
                        <SpansButtons>
                          <i className="fas fa-eye"></i>
                        </SpansButtons>
                        <SpansButtons>
                          <i className="fas fa-trash"></i>
                        </SpansButtons>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          }
          
          
          
          
          
          </TableBody> */}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ViewPastPaperTable;

// <div style={{ padding: "20px" }}>
//         <TableContainer component={Paper} style={{ border: "1px #DEDEDE" }}>
//           <Table>
//             <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
//               <TableRow>
//                 <TableCell></TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Exam Name</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Field</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Level</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Subject</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Chapter</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>PDF</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
//                 <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {/* Table content goes here */}
//               {/* Example row 1*/}

//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <SameStyleCells>MDCAT</SameStyleCells>
//                 <SameStyleCells></SameStyleCells>
//                 <SameStyleCells>Part 1</SameStyleCells>
//                 <SameStyleCells>English</SameStyleCells>
//                 <SameStyleCells>Young Seagull</SameStyleCells>
//                 <SameStyleCells>PDF</SameStyleCells>
//                 <TableCell style={{ color: "green", fontWeight: "bold" }}>
//                   Active
//                 </TableCell>

//                 <TableCell>
//                   <SpansButtons>
//                     <i className="fas fa-edit"></i>
//                   </SpansButtons>

//                   <SpansButtons>
//                     <i className="fas fa-eye"></i>
//                   </SpansButtons>

//                   <SpansButtons>
//                     <i className="fas fa-trash"></i>
//                   </SpansButtons>
//                 </TableCell>
//               </TableRow>

//               {/* Example row 2 start*/}

//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <SameStyleCells>MDCAT</SameStyleCells>
//                 <SameStyleCells></SameStyleCells>
//                 <SameStyleCells>Part 1</SameStyleCells>
//                 <SameStyleCells>English</SameStyleCells>
//                 <SameStyleCells>Young Seagull</SameStyleCells>
//                 <SameStyleCells>PDF</SameStyleCells>
//                 <TableCell style={{ color: "green", fontWeight: "bold" }}>
//                   Active
//                 </TableCell>

//                 <TableCell>
//                   <SpansButtons>
//                     <i className="fas fa-edit"></i>
//                   </SpansButtons>

//                   <SpansButtons>
//                     <i className="fas fa-eye"></i>
//                   </SpansButtons>

//                   <SpansButtons>
//                     <i className="fas fa-trash"></i>
//                   </SpansButtons>
//                 </TableCell>
//               </TableRow>

//               {/* Example row 3 start*/}

//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE", background: "" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//               </TableRow>

//               {/* Example row 4 start*/}

//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE", background: "" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//               </TableRow>

//               {/* Example row 5 start*/}

//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE", background: "" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//               </TableRow>

//               {/* Example row 6 start*/}

//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE", background: "" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//               </TableRow>
//               {/* Example row 7 start*/}
//               <TableRow>
//                 <TableCell>
//                   <Checkbox
//                     style={{ color: "#EEEEEE", background: "" }} // Change 'blue' to the desired color
//                     // Other props as needed
//                   />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
