import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditCategory } from "../../../customhook/category/useEditCategory";
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEditMock } from "../../customhook/MocktestHook/useEditMocktest";

import {
  MySelect,
  SelectFieldsHeads,
} from "../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";

import { FormControl, MenuItem, Select } from "@mui/material";
import { useGetCategory } from "../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../customhook/field-category/useGetSubCategory";

const EditMocktest = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();

  // console.log("ChapterData", ChapterData);

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editMocktestForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,
    handleExam,
  } = useEditMock(id || "");
  console.log("editMocktestForm", editMocktestForm);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      marginY="1rem"
    >
      <Paper
        elevation={3}
        style={{ width: "300px", padding: "10px", margin: "0 0" }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Edit Mock Test
        </Typography>
        <form onSubmit={handleSubmit}>
          <div>
            <Box>
              <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
            </Box>
            <div>
              {/* sssssss */}
              <FormControl sx={{ mb: 1 }} size="">
                <Select
                  style={{ width: "283px" }}
                  value={editMocktestForm?.categoryId}
                  // defaultValue={editMcqsForm?.categoryId}
                  // onChange={handleChange}  handleExam
                  onChange={handleExam}
                  name="categoryId"
                >
                  {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                  {CategoryData &&
                    CategoryData?.map(
                      (CategoryData) => (
                        // category?.categoryId?.map((exam) => (
                        <MenuItem
                          key={CategoryData?.id}
                          value={CategoryData?.id}
                        >
                          {CategoryData?.examname}
                        </MenuItem>
                      )
                      // ))}
                      // ))
                    )}
                </Select>
              </FormControl>
            </div>
            <div>
              <Box>
                <SelectFieldsHeads>Choose Field</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editMocktestForm?.subcategoryId}
                    defaultValue={editMocktestForm?.categoryId}
                    onChange={handleExam}
                    name="subcategoryId"
                    required
                  >
                    {SubCategoryData &&
                      SubCategoryData?.map(
                        (category) => (
                          // category?.subcategoryId?.map((exam) => (
                          <MenuItem key={category?.id} value={category?.id}>
                            {category?.subname}
                          </MenuItem>
                        )
                        // ))
                      )}
                    {/* {SubLevelData &&
                        SubLevelData.map((Examname) => (
                          <MenuItem key={Examname?.id} value={Examname?.id}>
                            {Examname?.examname}
                          </MenuItem>
                        ))} */}
                  </Select>
                </FormControl>
              </div>
            </div>
            <Box mb={1}>
              <TextField
                fullWidth
                label="mockname"
                id="mockname"
                name="mockname"
                value={editMocktestForm?.mockname}
                // onChange={handleChange}
                onChange={handleExam}
                required
              />
            </Box>

            {editMocktestForm?.questions &&
              editMocktestForm?.questions.map((mocktest, index) => (
                <div key={index}>
                  <Box mb={1}>
                    <TextField
                      fullWidth
                      label={`Question ${index + 1}`}
                      name={`question_${index}`}
                      value={mocktest?.question}
                      onChange={(e) => handleExam(e, index)} // assuming handleExam handles question change
                      required
                    />
                  </Box>
                  <Box mb={1}>
                    <TextField
                      fullWidth
                      label={`Correct Answer ${index + 1}`}
                      name={`correct_answer_${index}`}
                      value={mocktest?.correct_answer}
                      onChange={(e) => handleExam(e, index)} // assuming handleExam handles correct answer change
                      required
                    />
                  </Box>
                  {/* Render incorrect answers */}
                  {/* {mocktest?.incorrect_answer?.map((incorrect_answer, i) => ( */}
                    <Box mb={1}>
                      <TextField
                        fullWidth
                        label='Incorrect Answer'
                        // name={`incorrect_answer_${index}_${i}`}
                        value={mocktest?.incorrect_answer}
                        onChange={(e) => handleExam(e, index)} // assuming handleExam handles incorrect answer change
                        required
                      />
                    </Box>
                  {/* ))} */}
                </div>
              ))}
          </div>
          <Box textAlign="center" mt={2} mb={0}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="custom-btn"
            >
              Update
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default EditMocktest;
