import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const categoryApiSlice  = createApi({
  reducerPath: 'categoryApiSlice',
  tagTypes: ["CATEGORYDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  }),
  endpoints: (builder) => ({
    addCategory: builder.mutation({
      query: (data) => ({
        url: 'category',
        method: 'POST',
        body: data,
      }),

      invalidatesTags: ["CATEGORYDATA"],
    
    }),

    getSingleCategoryByID: builder.query({
      query: (id) => ({
        url: `category/${id}`,
        method: "GET",
      }),
      providesTags: ["CATEGORYDATA"],
   
    }),

    getCategoryData: builder.query({
      query: () => ({
        url: 'category/admin',
        method: 'GET',
      }),
      providesTags: ["CATEGORYDATA"],
    }),

    UpdateCategoryData: builder.mutation({
      query: (params) => ({
        url: `category/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivateCategory: builder.mutation({
      query: (id) => ({
        url: `category/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["CATEGORYDATA"],
    }),

    getDeActivateCategory: builder.mutation({
      query: (id) => ({
        url: `category/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["CATEGORYDATA"],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CATEGORYDATA"],
     
    }),
  }),
});

export const {useUpdateCategoryDataMutation,useDeleteCategoryMutation,useGetSingleCategoryByIDQuery, useAddCategoryMutation, useGetCategoryDataQuery, useGetActivateCategoryMutation ,useGetDeActivateCategoryMutation,} = categoryApiSlice;
