import { AppBar, Avatar, Icon, IconButton, InputBase, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Box, Tooltip, Menu, MenuItem, Divider, ListItemIcon } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

export const MyAvatar = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          // elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small"  />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};


  export  const MyAppBar = styled(AppBar)({
    backgroundColor: '#F5F5F5', // Change the background color to your preference
    boxShadow:"none",
  });
  
  export  const MyToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
   
    // Add any custom styling for the Toolbar here
  });

  
  export  const MyIconsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
  });
  
 export   const MyIcon = styled(IconButton)({
    marginRight: '16px', // Adjust the margin between icons
    color:"#004A80"
  });
  
  export  const MySearch = styled('div')({
    marginTop:"2px",
    display: 'flex',
    padding:'3px',
    alignItems: 'center',
    position: 'relative',
    height:"35px",
    borderRadius: '24px',
    border:"1.7px solid #1E5076",
    marginLeft:"20px",
    backgroundColor: '#F6F6F6',
  
   
    color:"black",
    '&:hover': {
      backgroundColor: '#F6F6F6',
      color:'black'
    },
    marginRight: '16px',
    width: '20%',
  });
  
  export  const MySearchIcon = styled('div')({
    padding: '9px',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'#004A80'
  });
  
  export  const MyInputBase = styled(InputBase)({
    color: 'inherit',
    padding: '0px',
    marginLeft:"10px",
    paddingRight: '0px',
    transition: 'width',
    width: '90%',
  });