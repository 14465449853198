import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Checkbox,
  IconButton,
  Button, // Import IconButton from Material UI
} from "@mui/material";
// import AddExamButtonComp from "../../../components/globalcomponents/SecondTopBarOutlet/AddExamTopButtons";
import AddFieldButtonComp from "../../globalcomponents/SecondTopBarOutlet/AddExamTopButtons";
// import { useGetCategory } from "../../../customhook/category/useGetSubCategory"; // Replace with your correct path
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";
// import { useGetDashboardDataQuery } from "../../../features/level/AddLevelSlice";
import { useGetDashboard } from "../../../customhook/level/useGetLevel";

// Import icons for edit and delete
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SpansButtons } from "../../../styles/categorycompstyles/addexamstyle/ViewExamStyle";
// import { useSubCategoryTableFunction } from "../../../customhook/field-category/useSubCategoryTableFun";
import { useSubLevelTableFunction } from "../../../customhook/level/useSubLevelTableFun";

const ViewLevelTable = () => {
  // Fetching data using your hook
  const { SubLevelData, isLoading, isSuccess } = useGetDashboard();
  const {
    handleActivate,
    handleDeActivate,
    handleView,
    handleDelete,
    handleEdit,
  } = useSubLevelTableFunction();
  // const [getdashboard, setgetdashboard] = useState(getdashboard); // Initialize with the data from useGetSubCategory

  // console.log(SubLevelData, "SubLevelData");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set rows per page to 5

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <AddFieldButtonComp />
      <div style={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          style={{ border: "1px solid #DEDEDE" }}
        >
          <Table>
            <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Exam Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Choose level</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Field Name</TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>
                  Field Name
                </TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Loop through the data and display rows */}
              {SubLevelData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row._id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {row.categoryId?.examname || "Handle this case"}
                  </TableCell>
                  <TableCell>
                    {row.subcategoryId?.subname || "Handle this case"}
                  </TableCell>
                  <TableCell>{row.Addlevel}</TableCell>
                  {console.log("ROW",row)}
                  {/* {SubLevelData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>{row.Addlevel}</TableCell>
                  <TableCell>
                    {typeof row.categoryId.examname === "object"
                      ? "Handle this case"
                      : row.categoryId.examname}
                  </TableCell>
                  <TableCell>
                    {typeof row.subcategoryId.subname === "object"
                      ? "Handle this case"
                      : row.subcategoryId.subname}
                  </TableCell> */}

                  {/* <TableCell>{row.subname}</TableCell> */}
                  {/* Display logo image */}
                  {/* <TableCell>
                    <img
                      src={row.logo}
                      alt="Logo"
                      style={{ width: "50px", height: "auto" }}
                    />
                  </TableCell> */}
                  {/* Display banner image */}
                  {/* <TableCell>
                    <img
                      src={row.exambanner}
                      alt="Banner"
                      style={{ width: "50px", height: "auto" }}
                    />
                  </TableCell> */}

                  {/* ... rest of your table cell data ... */}
                  {/* Action cell with edit and delete icons */}
                  <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row)}>
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Table pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={SubLevelData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ViewLevelTable;
