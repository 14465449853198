import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditCategory } from "../../../customhook/category/useEditCategory";
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import { useEditSubject } from "../../../customhook/subject/useEditSubject";
// import { useEditChapter } from "../../../customhook/chapter/useEditChapter";
// import { useEditPdf } from "../../../customhook/PDFHook/useEditPdf";
import { useEditMcqs } from "../../customhook/MCQSHook/useEditMcqs";

import {
  MySelect,
  SelectFieldsHeads,
} from "../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";

import { FormControl, MenuItem, Select } from "@mui/material";
// import { useGetCategory } from "../../../customhook/category/useGetCategory";
// import useChapterForm from "../../../customhook/chapter/useAddChapter";
import usePdfForm from "../../customhook/PDFHook/useAddPDF";

// import useSubjectForm from "../../../customhook/subject/useAddSubject";
import { useGetCategory } from "../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../customhook/field-category/useGetSubCategory";
import { useGetDashboard } from "../../customhook/level/useGetLevel";
import { useGetSubject } from "../../customhook/subject/useGetSubject";
import { useGetChapter } from "../../customhook/chapter/useGetChapter";
import { useGetPdf } from "../../customhook/PDFHook/useGetPDF";
import { useGetMcqs } from "../../customhook/MCQSHook/useGetMcqs";
// import { SelectFieldsHeads } from "../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";

const EditMcqs = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();
  const { SubLevelData } = useGetDashboard();
  const { SubjectData } = useGetSubject();
  const { ChapterData } = useGetChapter();
  // const { PdfData } = useGetPdf();
  // const { McqsData } = useGetMcqs();

  // console.log("ChapterData", ChapterData);

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editMcqsForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,handleExam
  } = useEditMcqs(id || "");
  // console.log("editMcqsForm", editMcqsForm);
  //   console.log("editMcqsForm",editMcqsForm)
  // console.log("abdarkhankhel",issuccess)
  // console.log("editMcqsForm?.uploadfile", editMcqsForm?.uploadfile);

  if (isloading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (issuccess) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        marginY="1rem"
      >
        <Paper
          elevation={3}
          style={{ width: "300px", padding: "10px", margin: "0 0" }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Edit PDF
          </Typography>
          <form onSubmit={handleSubmit}>
            <div>
              <Box>
                <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
              </Box>
              <div>
                {/* sssssss */}
                <FormControl sx={{ mb: 1 }} size="">
                <Select
                  style={{width:"283px"}}
                    value={editMcqsForm?.categoryId}
                    // defaultValue={editMcqsForm?.categoryId}
                    // onChange={handleChange}  handleExam
                    onChange={handleExam}  
                    name="categoryId"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {CategoryData &&
                      CategoryData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.examname}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Box>
                  <SelectFieldsHeads>Choose Field</SelectFieldsHeads>
                </Box>
                <div>
                  <FormControl sx={{ mb: 1 }} size="">
                    <Select
                      style={{ width: "283px" }}
                      value={editMcqsForm?.subcategoryId}
                      defaultValue={editMcqsForm?.categoryId}
                      onChange={handleExam} 
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData?.map(
                          (category) => (
                            // category?.subcategoryId?.map((exam) => (
                            <MenuItem key={category?.id} value={category?.id}>
                              {category?.subname}
                            </MenuItem>
                          )
                          // ))
                        )}
                      {/* {SubLevelData &&
                        SubLevelData.map((Examname) => (
                          <MenuItem key={Examname?.id} value={Examname?.id}>
                            {Examname?.examname}
                          </MenuItem>
                        ))} */}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <Box>
                <SelectFieldsHeads>Choose Level</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editMcqsForm?.Addlevel}
                    defaultValue={editMcqsForm?.Addlevel}
                    // onChange={handleChange}
                    onChange={handleExam} 
                    name="Addlevel"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {SubLevelData &&
                      SubLevelData?.map((CategoryData) => (
                        <MenuItem
                          key={CategoryData?.id}
                          value={CategoryData?.id}
                        >
                          {CategoryData?.Addlevel}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <Box>
                <SelectFieldsHeads>Choose Subject</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editMcqsForm?.subject}
                    defaultValue={editMcqsForm?.subject}
                    // onChange={handleChange}
                    onChange={handleExam} 
                    name="subject"
                  >
                    {SubjectData &&
                      SubjectData?.map((SubjectData) => (
                        // category?.categoryId?.map((exam) => (
                        <MenuItem key={SubjectData?.id} value={SubjectData?.id}>
                          {SubjectData?.subject}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <Box>
                <SelectFieldsHeads>Choose Chapter</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editMcqsForm?.chapter}
                    // defaultValue={editMcqsForm?.chapter?.chapter}
                    // onChange={handleChange}
                    onChange={handleExam} 
                    name="chapter"
                  >
                    {ChapterData &&
                      ChapterData?.map((ChapterData) => (
                        // category?.categoryId?.map((exam) => (
                        <MenuItem key={ChapterData?.id} value={ChapterData?.id}>
                          {ChapterData?.chapter}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <Box mb={1}>
                <TextField
                  fullWidth
                  label="question"
                  id="question"
                  name="question"
                  value={editMcqsForm?.question}
                  // onChange={handleChange}
                  onChange={handleExam} 
                  required
                />
              </Box>
              <Box mb={1}>
                <TextField
                  fullWidth
                  label="Correct_Answer"
                  id="correct_answer"
                  name="correct_answer"
                  value={editMcqsForm?.correct_answer}
                  // onChange={handleChange}
                  onChange={handleExam} 
                  required
                />
              </Box>
              {editMcqsForm.incorrect_answers.map((answer, index) => (
                <Box key={index} mb={1}>
                  <TextField
                    fullWidth
                    label={`Incorrect Answer ${index + 1}`}
                    value={answer}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />
                </Box>
              ))}
              {/*      
                {editMcqsForm &&
                Array.isArray(editMcqsForm.incorrect_answer) &&
                editMcqsForm.incorrect_answer.map((item, index) => (
                  <Box mb={1} key={index}>
                    <TextField
                      fullWidth
                      label="Incorrect_Answer"
                      id="incorrect_answer"
                      name="incorrect_answer"
                      value={item}
                      onChange={handleChange}
                      required
                    />
                  </Box>
                ))} */}
            </div>

            <Box textAlign="center" mt={2} mb={0}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="custom-btn"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }
};

export default EditMcqs;
