import React from "react";
import { useGetVideoDataQuery } from "../../features/video/VideoSlice";

  

export const useGetVedio = () => {
  const { data, isSuccess, isLoading, isError } = useGetVideoDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const transformedDataNew = React.useMemo(() => {
    const getVideoData = data || [];

    return getVideoData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
      Addlevel: catData?.Addlevel, // Assuming catdescription is an array
      subject: catData?.subject, // Assuming catdescription is an array
      chapter: catData?.chapter, // Assuming catdescription is an array
      videoname: catData?.videoname, // Assuming catdescription is an array
      description: catData?.description, // Assuming catdescription is an array
      uploadfile: catData?.uploadfile,
    //   logo: catData?.logo,
    //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);



  return {
    VideoData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
