import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useGetSinglePdfByIDQuery } from "../../../features/pdf/PdfSlice";
import { useGetSingleMcqsByIDQuery } from "../../features/mcqs/mcqsSlice";

const ViewSingleMcqsCat = () => {
  const params = useParams();
  const location = useLocation();

  //   console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleMcqsByIDQuery(params.id);
  // console.log(
  //   "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAa",
  //   data?.data
  // );
  console.log("data", data?.data?.subject);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data) {
    return <div>Error loading data...</div>;
  }

  const { McqsData } = data;
  // console.log("McqsData", McqsData);

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            uploadfile:
          </Typography>
          <img
            src={level?.uploadfile}
            alt="Logo"
            style={{
              width: "180px",
              height: "160px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid> */}
        {/* Right Side - Exam Name, Full Name, and Active Status */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>

          <Typography>{data?.data?.categoryId?.examname}</Typography>

          {/* <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>
          {data?.data?.categoryId?.map((category) => (
            <Typography key={category._id}>{category.examname}</Typography>
          ))||[]} */}

          <Typography variant="h6" color="primary">
            Field Name:
          </Typography>
          {/* {McqsData?.subcategoryId?.map((subcategoryId) => ( */}
          <Typography>{data?.data?.subcategoryId.subname}</Typography>
          {/* ))} */}

          <Typography variant="h6" color="primary">
            Level Name:
          </Typography>
          {/* {McqsData?.Addlevel && ( */}
          <Typography>{data?.data?.addlevel?.Addlevel}</Typography>
          {/* )} */}

          {/* <Typography
                variant="h6"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Field Name:
              </Typography> */}
          {/* <Typography>{level.subcategoryId[0].subname}</Typography> */}
          {/* <Typography>{level?.subcategoryId}</Typography> */}

          {/* {location?.state?.subcategoryId?.map((name)=>(
                <Typography>
                {name.subname}
                </Typography>
              ))} */}

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Subject Name:
          </Typography>
          {/* <Typography>{level?.subject}</Typography> */}

          <Typography>{data?.data?.subject.subject}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Chapter Name:
          </Typography>
          {/* <Typography>{level?.subject}</Typography> */}

          <Typography>{data?.data?.chapter?.chapter}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Question :
          </Typography>
          {/* <Typography>{level?.subject}</Typography> */}

          <Typography>{data?.data?.question}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Correct Answer:
          </Typography>
          {/* <Typography>{level?.subject}</Typography> */}

          <Typography>{data?.data?.correct_answer}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Incorrect Attempts:
          </Typography>
          {/* <Typography>{level?.subject}</Typography> */}

          <ul>
            {data?.data?.incorrect_answer.map((answer, index) => (
              <li key={index}>
                <Typography>{answer}</Typography>
              </li>
            ))}
          </ul>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Status:
          </Typography>
          <Typography
            style={{
              color: data?.data?.isActive ? activeColor : inactiveColor,
            }}
          >
            {data?.data?.isActive ? "Active" : "Inactive"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewSingleMcqsCat;
