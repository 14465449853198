import { TextField, Typography,Avatar,InputAdornment } from "@mui/material";
import { React } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";
import addpdfimg from "../../assets/addpdfimg.png";

import pdfupload from "../../assets/pdfupload.png"

import {
  FirstDiv,
  MyTextField,
  InnerMainDiv,
  DescDiv,
  SubMainDiv,
  MySelect,
  TitleDiv,
  ButtonsDiv,
  ImageDiv,
  SelectFieldsHeads,
  PdfNameField,
  PdfDescField,
  UploadPDfTextField
} from "../../styles/userstyle/AddUserStyle";
import { useRef } from "react";

const AddUserForm = () => {


  return (
    <>
      <FirstDiv style={{ backgroundColor: "#F6F6F6" }}>
        <TitleDiv>Add USER</TitleDiv>
        <DescDiv>
        Categorizing by selecting the exam, subject, and chapter,  add the respective MCQs.
        </DescDiv>

        <InnerMainDiv style={{ display: "flex" }}>
          <div>
          <div>
          <SelectFieldsHeads
         
          >
          Name
          </SelectFieldsHeads>
        </div>
        <div>
        <PdfNameField id="outlined-basic" label="" variant="outlined" />
        </div>

        <div>
        <SelectFieldsHeads
       
        >
          Phone Number
        </SelectFieldsHeads>
      </div>
      <div>
      <PdfNameField id="outlined-basic" label="" variant="outlined" />
      </div>


      <div>
      <SelectFieldsHeads
     
      >
        Email
      </SelectFieldsHeads>
    </div>
    <div>
    <PdfNameField id="outlined-basic" label="" variant="outlined" />
    </div>

    <div>
    <SelectFieldsHeads
   
    >
      Password
    </SelectFieldsHeads>
  </div>
  <div>
  <PdfNameField id="outlined-basic" label="" variant="outlined" />
  </div>

          

    
          </div>

          <SubMainDiv style={{}}>
            <div>
              <SelectFieldsHeads
             
              >
                CNIC
              </SelectFieldsHeads>
            </div>
            <div>
            <PdfNameField id="outlined-basic" label="" variant="outlined" />
            </div>

           
          
            
            <div>
            <SelectFieldsHeads
        
            >
              Role
           </SelectFieldsHeads>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <MySelect style={{}}>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </MySelect>
            </FormControl>
          </div>
    
          <ButtonsDiv style={{}}>
          <div>
            <Button
              style={{ marginLeft: "5px", backgroundColor: "#004A80" }}
              variant="contained"
            >
              SUBMIT
            </Button>
         
          </div>

         
        </ButtonsDiv>
          </SubMainDiv>
        </InnerMainDiv>
        
      </FirstDiv>
    </>
  );
};

export default AddUserForm;
