import { useState } from "react";
import { useAddmcqsMutation } from "../../features/mcqs/mcqsSlice";

const useMcqsForm = () => {

  
  const [formData, setFormData] = useState({
    categoryId: "",
    subcategoryId: "",
    addlevel: "",
    subject: "",
    chapter: "",
    question: "",
    correct_answer: "",
    incorrect_answer: [],
  });
// console.log(formData,"formData formData")
  const [addmcqs, { isLoading, isError, isSuccess, error }] =
  useAddmcqsMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
//    const handleImageChange = (event) => {
//     const imageFile = event.target.files[0];
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       uploadfile: imageFile,
//     }));
//   };
const handleRemoveClick = (index) => {
  const updatedIncorrectAnswers = [...formData.incorrect_answer];
  updatedIncorrectAnswers.splice(index, 1);
  setFormData((prevFormData) => ({
    ...prevFormData,
    incorrect_answer: updatedIncorrectAnswers,
  }));
};
const handleAddClick = () => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    incorrect_answer: [...prevFormData.incorrect_answer, ""],
  }));
};
const handleIncorrectAnswerChange = (index, value) => {
  const updatedIncorrectAnswers = [...formData.incorrect_answer];
  updatedIncorrectAnswers[index] = value;
  setFormData((prevFormData) => ({
    ...prevFormData,
    incorrect_answer: updatedIncorrectAnswers,
  }));
};
  const handleIconChange = (event) => {
    const iconFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      exambanner: iconFile,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
   
    try {
      let FormValues = { ...formData };
  // console.log(formData,'-------');
      // Exclude addlevel if categoryId is "MDCAT"
      if (FormValues?.addlevel === "") {
        delete FormValues.addlevel;
      } else {
        // If addlevel exists and is not empty, wrap it in an object
        FormValues.addlevel = { key: FormValues.addlevel };
      }
  
      const response = await addmcqs(FormValues).unwrap();
      console.log(response);
    }
    catch (error) {
      console.log(error);
    }
  };

  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    handleAddClick,
    handleRemoveClick,
    handleIncorrectAnswerChange,
    // handleImageChange,
    handleIconChange,
    handleSubmit,
  };
};

export default useMcqsForm;