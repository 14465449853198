import React from "react";
// import { useGetSubjectDataQuery } from "../../features/subject/AddSubjectSlice";
// import { useGetChapterDataQuery } from "../../features/chapters/ChapterSlice";
// import { useGetPdfDataQuery } from "../../features/pdf/PdfSlice";
import { useGetMcqsDataQuery } from "../../features/mcqs/mcqsSlice";

  

export const useGetMcqs = () => {
  const { data, isSuccess, isLoading, isError } = useGetMcqsDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
// console.log("data", data)
  const transformedDataNew = React.useMemo(() => {
    const getMcqsData = data || [];

    return getMcqsData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
      addlevel: catData?.addlevel, // Assuming catdescription is an array
      subject: catData?.subject, // Assuming catdescription is an array
      chapter: catData?.chapter, // Assuming catdescription is an array
      pdfname: catData?.pdfname, // Assuming catdescription is an array
      description: catData?.description, // Assuming catdescription is an array
      question: catData?.question, // Assuming catdescription is an array
      // uploadfile: catData?.uploadfile,
    //   logo: catData?.logo,
    //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);



  return {
    McqsData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
