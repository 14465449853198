import { useState } from "react";
import { useAddpaperMutation } from "../../features/Pastpaper/PastPaperSilce";

const usePastpaperForm = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    subcategoryId: "",
    papername: "",
    questions: [
      {
        question: "",
        correct_answer: "",
        incorrect_answer: ["", "", ""], // Fixed 3 incorrect answers
      },
    ],
  });
  // console.log(formData,"formData formData")
  const [addpastpaper, { isLoading, isError, isSuccess, error }] =
    useAddpaperMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleQuestionChange = (index, value) => {
    setFormData((prevFormData) => {
      const updatedQuestions = [...prevFormData.questions];
      updatedQuestions[index].question = value;
      return { ...prevFormData, questions: updatedQuestions };
    });
  };

  const handleCorrectAnswerChange = (index, value) => {
    setFormData((prevFormData) => {
      const updatedQuestions = [...prevFormData.questions];
      updatedQuestions[index].correct_answer = value;
      return { ...prevFormData, questions: updatedQuestions };
    });
  };

  const handleIncorrectAnswerChange = (questionIndex, answerIndex, value) => {
    setFormData((prevFormData) => {
      const updatedQuestions = [...prevFormData.questions];
      updatedQuestions[questionIndex].incorrect_answer[answerIndex] = value;
      return { ...prevFormData, questions: updatedQuestions };
    });

  };

  const handleAddQuestion = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      questions: [
        ...prevFormData.questions,
        {
          question: "",
          correct_answer: "",
          incorrect_answer: ["", "", ""],
        },
      ],
    }));
  };

  const handleRemoveQuestion = (indexToRemove) => {
    setFormData((prevFormData) => {
      const updatedQuestions = prevFormData.questions.filter(
        (_, index) => index !== indexToRemove
      );
      return { ...prevFormData, questions: updatedQuestions };
    });
  };

  const handleIconChange = (event) => {
    const iconFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      exambanner: iconFile,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
    

      const FormValues = {
        categoryId: formData.categoryId,
        subcategoryId: formData.subcategoryId,
        papername: formData.papername,
        questions: formData.questions,
      };

      const response = await addpastpaper(FormValues).unwrap();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    handleQuestionChange,
    handleCorrectAnswerChange,
    handleIncorrectAnswerChange,
    handleAddQuestion,
    handleRemoveQuestion,
    handleIconChange,
    handleSubmit,
  };
};

export default usePastpaperForm;
