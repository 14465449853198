import React from "react";
import { useGetSubCategoryDataQuery } from "../../features/field/FieldSlice";

  

export const useGetSubCategory = () => {
  const { data, isSuccess, isLoading, isError } = useGetSubCategoryDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );


  const transformedDataNew = React.useMemo(() => {
    const getSubCategoryData = data || [];

    return getSubCategoryData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subname: catData?.subname, // Assuming catdescription is an array
      // logo: catData?.logo,
      // exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);



  return {
    SubCategoryData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
