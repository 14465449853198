import React from "react";
import { useGetPdfDataQuery } from "../../features/pdf/PdfSlice";

  

export const useGetPdf = () => {
  const { data, isSuccess, isLoading, isError } = useGetPdfDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const transformedDataNew = React.useMemo(() => {
    const getPdfData = data || [];

    return getPdfData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
      Addlevel: catData?.Addlevel, // Assuming catdescription is an array
      subject: catData?.subject, // Assuming catdescription is an array
      chapter: catData?.chapter, // Assuming catdescription is an array
      pdfname: catData?.pdfname, // Assuming catdescription is an array
      description: catData?.description, // Assuming catdescription is an array
      uploadfile: catData?.uploadfile,
      status:catData?.isActive
    }));
  }, [data]);



  return {
    PdfData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
