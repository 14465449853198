import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useGetSinglesubCategoryByIDQuery, useUpdateSubCategoryDataMutation } from "../../features/field/FieldSlice";
import {
  useGetSingleLevelByIDQuery,
  useUpdateLevelDataMutation,
} from "../../features/level/AddLevelSlice";

export const useEditSubLevel = (id) => {
  const navigate = useNavigate();
  const [editSubCategoryForm, setEditSubCategoryForm] = useState({
    categoryId: "",
    subcategoryId:"",
    Addlevel: "",

    // logo: "",
    // exambanner: "",
    // Add more fields as needed
  });
  
  const {
    data: singlecategory,
    isLoading,
    isSuccess,
  } = useGetSingleLevelByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  console.log(singlecategory, "singlecategory");
  // console.log(singlecategory, "this si single category");
  useEffect(() => {
    if (singlecategory) {
      setEditSubCategoryForm({
        categoryId: singlecategory?.level.categoryId[0]?._id,
        subcategoryId: singlecategory?.level.subcategoryId[0]?._id,
        Addlevel: singlecategory?.level?.Addlevel,
        // logo: singlecategory?.category?.logo,
        // exambanner: singlecategory?.category?.exambanner,
        // Update with additional fields if needed
      });
    }
  }, [singlecategory]);

  // console.log(editSubCategoryForm,"editSubCategoryForm")

  const imageUrl = editSubCategoryForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditSubCategoryForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0] || null;
    setEditSubCategoryForm({ ...editSubCategoryForm, exambanner: file });
  };

  const [UpdateSubLevelData] = useUpdateLevelDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const formData = new FormData();
      // console.log("formDataformDataformDataformData",formData)
      // formData.append("categoryId", editSubCategoryForm.categoryId);
      // formData.append("subcategoryId", editSubCategoryForm.subcategoryId);
      // formData.append("Addlevel", editSubCategoryForm.Addlevel);
      //   formData.append("logo", editSubCategoryForm.logo);
      //   formData.append("exambanner", editSubCategoryForm.exambanner);

      // Update with additional fields if needed

      const response = (
        await UpdateSubLevelData({
          id,
          data: editSubCategoryForm,
        })
      ).data;

      if (response.success === true) {
        window.alert(response.message);
        navigate("/ViewLevelTable");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  return {
    editSubCategoryForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};