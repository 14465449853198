import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetSingleBannerByIDQuery,
  useUpdateBannerDataMutation,
} from "../../features/banner/BannerSlice";

export const useEditBanner = (id) => {
  const navigate = useNavigate();
  const [editBannerForm, setEditBannerForm] = useState({
    title: "",
    name: "",
    description: "",
    bannerImage: [], // This will hold both URLs and File objects
  });

  const {
    data: singleBanner,
    isLoading,
    isSuccess,
  } = useGetSingleBannerByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (singleBanner) {
      setEditBannerForm({
        title: singleBanner?.banner?.title,
        name: singleBanner?.banner?.name,
        description: singleBanner?.banner?.description,
        bannerImage: singleBanner?.banner?.bannerImage || [],
      });
    }
  }, [singleBanner]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditBannerForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setEditBannerForm((prevData) => ({
      ...prevData,
      bannerImage: [...prevData.bannerImage, ...files],
    }));
  };

  const handleDeleteImage = (index) => {
    setEditBannerForm((prevData) => ({
      ...prevData,
      bannerImage: prevData.bannerImage.filter((_, i) => i !== index),
    }));
  };

  const [updateBannerData, { isError, isSuccess: updateSucess }] =
    useUpdateBannerDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("title", editBannerForm?.title);
      formData.append("name", editBannerForm?.name);
      formData.append("description", editBannerForm?.description);

      editBannerForm?.bannerImage.forEach((item) => {
        if (item instanceof File) {
          formData.append("bannerImage", item);
        } else {
          formData.append("existingBannerImage", item); // Handle existing images
        }
      });

      const response = await updateBannerData({ id, data: formData }).unwrap();
      // console.log("first", response)
      if (response?.success === true) {
        window.alert(response.message);
        navigate("/ViewBanner");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  return {
    editBannerForm,
    handleChange,
    handleFileChange,
    handleDeleteImage,
    handleSubmit,
    isLoading,
    isSuccess,
    isError,
    updateSucess,
  };
};
