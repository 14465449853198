import React from 'react'
import ViewPastPaperTable from '../../components/pastpapercomponents/ViewPastPaperTable'

const ViewPastPaper = () => {
  return (
  <>
  <ViewPastPaperTable/>
  </>
  )
}

export default ViewPastPaper