import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEditPastpaper } from "../../customhook/PastpaperHook/useEditPastpaper";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useGetCategory } from "../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../customhook/field-category/useGetSubCategory";

const EditPastpaper = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editPastpaperForm,
    handleChange,
    handleExam,
    handleSubmit,
    isLoading,
    isSuccess,
  } = useEditPastpaper(id || "");

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      marginY="1rem"
    >
      <Paper elevation={3} style={{ width: "300px", padding: "10px" }}>
        <Typography variant="h5" align="center" gutterBottom>
          Edit Past paper
        </Typography>
        <form onSubmit={handleSubmit}>
          <div>
            <Box>
              <Typography variant="body1">Choose Exam</Typography>
            </Box>
            <FormControl sx={{ mb: 1 }} fullWidth>
              <Select
                value={editPastpaperForm.categoryId}
                onChange={handleExam}
                name="categoryId"
              >
                {CategoryData?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.examname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <Typography variant="body1">Choose Field</Typography>
            </Box>
            <FormControl sx={{ mb: 1 }} fullWidth>
              <Select
                value={editPastpaperForm.subcategoryId}
                onChange={handleExam}
                name="subcategoryId"
              >
                {SubCategoryData?.map((subcategory) => (
                  <MenuItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.subname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Mock Name"
              name="papername"
              value={editPastpaperForm.papername}
              onChange={handleExam}
              required
              sx={{ mb: 2 }}
            />
            {editPastpaperForm.questions.map((question, index) => (
              <div key={index}>
                <TextField
                  fullWidth
                  label={`Question ${index + 1}`}
                  name="question"
                  value={question.question}
                  onChange={(e) => handleChange(e, index)}
                  required
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label={`Correct Answer ${index + 1}`}
                  name="correct_answer"
                  value={question.correct_answer}
                  onChange={(e) => handleChange(e, index)}
                  required
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label={`Incorrect Answer ${index + 1}`}
                  name="incorrect_answer"
                  value={question.incorrect_answer}
                  onChange={(e) => handleChange(e, index)}
                  required
                  sx={{ mb: 1 }}
                />
              </div>
            ))}
          </div>
          <Box textAlign="center" mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default EditPastpaper;
