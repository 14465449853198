import React from "react";
// import { useGetSubjectDataQuery } from "../../features/subject/AddSubjectSlice";
import { useGetChapterDataQuery } from "../../features/chapters/ChapterSlice";

  

export const useGetChapter = () => {
  const { data, isSuccess, isLoading, isError } = useGetChapterDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

// console.log("Data", data)
  const transformedDataNew = React.useMemo(() => {
    const getChapterData = data || [];

    return getChapterData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
      Addlevel: catData?.Addlevel, // Assuming catdescription is an array
      subject: catData?.subject, // Assuming catdescription is an array
      chapter: catData?.chapter, // Assuming catdescription is an array
      uploadfile: catData?.uploadfile,
    //   logo: catData?.logo,
    //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);



  return {
    ChapterData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
