import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const ChapterSlice = createApi({
  reducerPath: "ChapterSlice",
  tagTypes: ["CHAPTERDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addchapter: builder.mutation({
      query: (data) => ({
        url: "chapter",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["CHAPTERDATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),
     getSingleChapterByID: builder.query({
      query: (id) => ({
        url: `chapter/${id}`,
        method: "GET",
      }),
      providesTags: ["CHAPTERDATA"],
   
    }),

    getChapterData: builder.query({
      query: () => ({
        url: 'chapter/admin',
        method: 'GET',
      }),
      providesTags: ["CHAPTERDATA"],
    }),

    UpdateChapterData: builder.mutation({
      query: (params) => ({
        url: `Chapter/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivateSubject: builder.mutation({
      query: (id) => ({
        url: `chapter/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["CHAPTERDATA"],
    }),

    getDeActivateSubject: builder.mutation({
      query: (id) => ({
        url: `chapter/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["CHAPTERDATA"],
    }),

    deleteChapter: builder.mutation({
      query: (id) => ({
        url: `chapter/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CHAPTERDATA"],
     
    }),
}),
});
export const { useAddchapterMutation, useGetChapterDataQuery, useGetSingleChapterByIDQuery, useUpdateChapterDataMutation, useGetActivateSubjectMutation, useGetDeActivateSubjectMutation , useDeleteChapterMutation  } = ChapterSlice;