import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useGetSingleChapterByIDQuery, useUpdateChapterDataMutation } from "../../features/chapters/ChapterSlice";
import { useGetSinglePdfByIDQuery, useUpdatePdfDataMutation} from "../../features/pdf/PdfSlice";

export const useEditPdf = (id) => {
  const navigate = useNavigate();
  const [editPdfForm, setEditPdfForm] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
    subject: "",
    chapter: "",
    pdfname: "",
    description: "",
    uploadfile: "",
  });
  // console.log("editPdfForm", editPdfForm)

  const {
    data: singlepdf,
    isLoading,
    isSuccess,
  } = useGetSinglePdfByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  // const categoryId = singlechapter?.category?.categoryId;
  useEffect(() => {
    if (singlepdf) {
      setEditPdfForm({
        categoryId: singlepdf?.level?.categoryId?._id,
        subcategoryId: singlepdf?.level?.subcategoryId?._id,
        Addlevel: singlepdf?.level?.Addlevel?._id,
        subject: singlepdf?.level?.subject?._id,
        chapter: singlepdf?.level?.chapter,
        pdfname: singlepdf?.level?.pdfname,
        description: singlepdf?.level?.description,
        uploadfile: singlepdf?.level?.uploadfile,
        // subject: "65cc5de36ca1435880f630da",
        // uploadfile: "http://preparlyapi-env.eba-u4ipsxzx.us-east-1.elasticbeanstalk.com/uploads/chapter-Pg2UnV3cG-chapter.jpeg",

        // exambanner: singlechapter?.category?.exambanner,
        // Update with additional fields if needed
      });
    }
  }, [singlepdf]);
    // console.log(singlepdf, "singlepdfsinglepdfsinglepdfsinglepdfsinglepdf");

  const imageUrl = editPdfForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditPdfForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0] || null;
    setEditPdfForm({ ...editPdfForm, uploadfile: file });
  };

  const [UpdatePdfData] = useUpdatePdfDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("categoryId", editPdfForm.categoryId);
      formData.append("subcategoryId", editPdfForm.subcategoryId);
      // formData.append("Addlevel", editPdfForm.Addlevel);
      formData.append("subject", editPdfForm.subject);
      formData.append("chapter", editPdfForm.chapter);
      formData.append("pdfname", editPdfForm.pdfname);
      formData.append("description", editPdfForm.description);
      formData.append("uploadfile", editPdfForm.uploadfile);
      //   formData.append("exambanner", editPdfForm.exambanner);

      // Update with additional fields if needed

      const response = (
        await UpdatePdfData({
          id,
          data: formData,
        })
      ).data;
      console.log("responseresponseresponse", response);
      if (response.success === true) {
        window.alert(response.message);
        navigate("/view");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editPdfForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};
