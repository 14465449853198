import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditCategory } from "../../../customhook/category/useEditCategory";
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import { useEditSubject } from "../../../customhook/subject/useEditSubject";
// import { useEditChapter } from "../../../customhook/chapter/useEditChapter";
import { useEditPdf } from "../../../customhook/PDFHook/useEditPdf";

import {
  MySelect,
  SelectFieldsHeads,
} from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
import { FormControl, MenuItem, Select } from "@mui/material";
// import { useGetCategory } from "../../../customhook/category/useGetCategory";
// import useChapterForm from "../../../customhook/chapter/useAddChapter";
import usePdfForm from "../../../customhook/PDFHook/useAddPDF";

// import useSubjectForm from "../../../customhook/subject/useAddSubject";
import { useGetCategory } from "../../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";
import { useGetDashboard } from "../../../customhook/level/useGetLevel";
import { useGetSubject } from "../../../customhook/subject/useGetSubject";
import { useGetChapter } from "../../../customhook/chapter/useGetChapter";
import { useGetPdf } from "../../../customhook/PDFHook/useGetPDF";

const EditPdf = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();
  const { SubLevelData } = useGetDashboard();
  const { SubjectData } = useGetSubject();
  const { ChapterData } = useGetChapter();
  const { PdfData } = useGetPdf();

  // console.log(PdfData, "PdfDataPdfDataPdfData");

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editPdfForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,
  } = useEditPdf(id || "");
  // console.log("editPdfForm" , editPdfForm);
  //   console.log("formData",formData)
  // console.log("abdarkhankhel",issuccess)
  // console.log("editPdfForm?.uploadfile", editPdfForm?.uploadfile);

  if (isloading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (issuccess) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        marginY="1rem"
      >
        <Paper
          elevation={3}
          style={{ width: "300px", padding: "10px", margin: "0 0" }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Edit PDF
          </Typography>
          <form onSubmit={handleSubmit}>
            <div>
              <Box>
                <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                  style={{width:"283px"}}
                    value={editPdfForm?.categoryId}
                    defaultValue={editPdfForm?.categoryId}
                    onChange={handleChange}
                    name="categoryId"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {CategoryData &&
                      CategoryData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.examname}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Box>
                  <SelectFieldsHeads>Choose Field</SelectFieldsHeads>
                </Box>
                <div>
                  <FormControl sx={{ mb: 1 }} size="">
                    <Select
                      style={{width:"283px"}}
                      value={editPdfForm?.subcategoryId}
                      defaultValue={editPdfForm?.categoryId}
                      onChange={handleChange}
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData?.map(
                          (category) => (
                            // category?.subcategoryId?.map((exam) => (
                            <MenuItem key={category?.id} value={category?.id}>
                              {category?.subname}
                            </MenuItem>
                          )
                          // ))
                        )}
                      {/* {SubLevelData&&
                      SubLevelData.map((Examname) => (
                        <MenuItem key={Examname?.id} value={Examname?.id}>
                          {Examname?.examname}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <Box>
                <SelectFieldsHeads>Choose Level</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{width:"283px"}}
                    value={editPdfForm?.Addlevel}
                    defaultValue={editPdfForm?.Addlevel}
                    onChange={handleChange}
                    name="Addlevel"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {SubLevelData &&
                      SubLevelData?.map(
                        (CategoryData) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.Addlevel}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </div>
              <Box>
                <SelectFieldsHeads>Choose Subject</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{width:"283px"}}
                    value={editPdfForm?.subject}
                    defaultValue={editPdfForm?.subject}
                    onChange={handleChange}
                    name="subject"
                  >
                    {SubjectData &&
                      SubjectData?.map((SubjectData) => (
                        // category?.categoryId?.map((exam) => (
                        <MenuItem key={SubjectData?.id} value={SubjectData?.id}>
                          {SubjectData?.subject}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>


              <Box>
                <SelectFieldsHeads>Choose Chapter</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{width:"283px"}}
                    value={editPdfForm?.chapter}
                    defaultValue={editPdfForm?.chapter}
                    onChange={handleChange}
                    name="chapter"
                  >
                    {ChapterData &&
                      ChapterData?.map((ChapterData) => (
                        // category?.categoryId?.map((exam) => (
                        <MenuItem key={ChapterData?.id} value={ChapterData?.id}>
                          {ChapterData?.chapter}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {/* <Box mt={1}>
                <TextField
                  fullWidth
                  label="Addlevel"
                  id="Addlevel"
                  name="Addlevel"
                  value={editSubCategoryForm?.Addlevel}
                  onChange={handleChange}
                  required
                />
              </Box> */}


              <Box mb={1}>
                <TextField
                  fullWidth
                  label="PDFName"
                  id="pdfname"
                  name="pdfname"
                  value={editPdfForm?.pdfname}
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box mb={1}>
                <TextField
                  fullWidth
                  label="PDFdescription"
                  id="description"
                  name="description"
                  value={editPdfForm?.description}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box mb={1}>
                <TextField
                  fullWidth
                  type="file"
                  id="uploadfile"
                  name="uploadfile"
                  onChange={handleFileChange}
                />
                {editPdfForm?.uploadfile && (
                  <img
                    src={editPdfForm?.uploadfile}
                    alt="Logo Preview"
                    className="preview-image"
                    style={{ marginTop: "4px", width: "100%", height: "120px" }}
                  />
                )}
              </Box>
            </div>

            <Box textAlign="center" mt={2} mb={0}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="custom-btn"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }
};

export default EditPdf;