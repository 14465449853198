// import React from 'react';
// import { useParams } from 'react-router-dom';
// import { useGetSingleCategoryByIDQuery } from '../../../features/category/CategorySlice';
// import CircularProgress from '@mui/material/CircularProgress';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';

// const ViewSingleCat = () => {
//   const { id } = useParams();
//   const { data, isLoading, isSuccess } = useGetSingleCategoryByIDQuery(id);

//   if (isLoading) {
//     return (
//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (!isSuccess || !data.category) {
//     return <div>Error loading data...</div>;
//   }

//   const { category } = data;
//   const activeColor = '#4CAF50'; // Green
//   const inactiveColor = '#f44336'; // Red

//   return (
//     <Grid container spacing={3} justifyContent="center" style={{ padding: '20px' }}>
//       <Grid item xs={12} sm={6}>
//         <Paper elevation={3} style={{ padding: '20px', textAlign: 'left' }}>
//           <Typography variant="h6" color="primary">
//             Exam Name:
//           </Typography>
//           <Typography>{category.examname}</Typography>

//           <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
//             Full Name:
//           </Typography>
//           <Typography>{category.Full_name}</Typography>

//           <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
//             Logo:
//           </Typography>
//           <img
//             src={category.logo}
//             alt="Logo"
//             style={{ width: '150px', height: 'auto', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
//           />

//           <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
//             Banner:
//           </Typography>
//           <img
//             src={category.exambanner}
//             alt="Banner"
//             style={{
//               width: '150px',
//               height: 'auto',
//               borderRadius: '8px',
//               marginTop: '10px',
//               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//             }}
//           />

//           <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
//             Status:
//           </Typography>
//           <Typography style={{ color: category.isActive ? activeColor : inactiveColor }}>
//             {category.isActive ? 'Active' : 'Inactive'}
//           </Typography>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default ViewSingleCat;
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleCategoryByIDQuery } from '../../../features/category/CategorySlice';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const ViewSingleCat = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetSingleCategoryByIDQuery(id);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.category) {
    return <div>Error loading data...</div>;
  }

  const { category } = data;
  const activeColor = '#4CAF50'; // Green
  const inactiveColor = '#f44336'; // Red

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      {/* Full Width - Logo, Exambanner, Examname, Full_name, and Active Status */}
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px', textAlign: 'left' }}>
          <Grid container spacing={3}>
            {/* Left Side - Logo and Exambanner */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Logo:
              </Typography>
              <img
                src={category.logo}
                alt="Logo"
                style={{ width: '150px', height: 'auto', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                crossOrigin="anonymous"
              />

              <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
                Banner:
              </Typography>
              <img
                src={category.exambanner}
                alt="Banner"
                style={{
                  width: '150px',
                  height: 'auto',
                  borderRadius: '8px',
                  marginTop: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                crossOrigin="anonymous"
              />
            </Grid>

            {/* Right Side - Exam Name, Full Name, and Active Status */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Exam Name:
              </Typography>
              <Typography>{category.examname}</Typography>

              <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
                Full Name:
              </Typography>
              <Typography>{category.full_name}</Typography>

              <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
                Status:
              </Typography>
              <Typography style={{ color: category.isActive ? activeColor : inactiveColor }}>
                {category.isActive ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ViewSingleCat;
