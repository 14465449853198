import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  MenuItem,
} from "@mui/material";
import view from "../../../assets/view.png";
import edit from "../../../assets/edit.png";
import {
  LoginTextFields,
  ParaDiv,
  UploadPDfTextField,
  HeadDiv,
  containerStyle,
  rowStyle,
  ButtonsMainDiv,
  ButtonsInnerDiv,
  TextFields,
} from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
import pdfupload from "../../../assets/pdfupload.png";
import examfield from "../../../assets/examfield.png";
import { useRef, useState } from "react";
import {
  MySelect,
  SelectFieldsHeads,
} from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
import ButtonComponent from "../ButtonComp/ButtonComp";
import FieldCategoryForm from "../../../customhook/field-category/useAddSubCategory";
import { useGetCategory } from "../../../customhook/category/useGetCategory";
const AddFields = ({ onPageChange }) => {
  const { CategoryData } = useGetCategory();
  // console.log("CategoryData", CategoryData);
  const [activePage, setActivePage] = useState("AddFields");

  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleImageChange,
    handleIconChange,
    isError,
    isLoading,
    isSuccess,
    error,
  } = FieldCategoryForm(); // Use the custom hook  const fileInputRef = useRef(null);

  // const handleFileUploadClick = () => {
  //   fileInputRef.current.click();
  // };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    // Do something with the selected file, like upload it
    // console.log("Selected file:", file);
  };
  return (
    <>
      <Box sx={{ margin: "20px" }}>
        <Grid container>
          <Grid
            style={{ background: "#F6F6F6", margin: "0px" }}
            item
            xs={12}
            sm={12}
            md={6}
          >
            <div style={{ margin: "10px" }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{
                    background: "#094C7D",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    textTransform: "capitalize",

                    color: "#FFFFFF",
                    paddingLeft: "18px",
                  }}
                  href="/ViewSingleFieldCat"
                >
                  <img
                    src={edit}
                    alt="Your Image"
                    style={{ marginRight: "8px", width: "15px" }}
                  />
                  Edit
                </Button>

                <Button
                  style={{
                    background: "#E8E8E8",
                    textTransform: "capitalize",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    color: "#094C7D",
                  }}
                  variant=""
                  href="/ViewFieldTable"
                >
                  <img
                    src={view}
                    alt="Your Image"
                    style={{ marginRight: "6px", width: "18px" }}
                  />
                  View
                </Button>
              </div>
            </div>
            <HeadDiv style={{}}>
              <div>
                <Typography
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "24px",
                    fontWeight: "bolder",
                    color: "#06375A",
                  }}
                >
                  Add Field
                </Typography>
              </div>
              <ParaDiv style={{}}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    letterSpacing: "1px",
                  }}
                >
                  Under each Exam, we'll provide the exam name and accompany it
                  with a visual representation.
                </Typography>
              </ParaDiv>
            </HeadDiv>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div>
                    <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
                  </div>
                  <div>
                    <FormControl sx={{ m: 1 }} size="" required>
                      <MySelect
                        value={formData?.categoryId}
                        onChange={handleInputChange}
                        name="categoryId"
                        required
                      >
                        {CategoryData &&
                          CategoryData.map((Examname) => (
                            <MenuItem key={Examname?.id} value={Examname?.id}>
                              {Examname?.examname}
                            </MenuItem>
                          ))}
                      </MySelect>
                    </FormControl>
                  </div>

                  {/* <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Segoe UI",
                        marginLeft: "10px",
                      }}
                    >
                      Enter Field Name
                    </div>
                    <TextFields />
                  </div> */}
                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Segoe UI",
                      }}
                    >
                      Enter Field Name
                    </div>
                    <TextFields
                      type="text"
                      name="subname"
                      value={formData?.subname}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                {/* <div
                  style={{
                    marginTop: "13px",
                    display: "",
                    justifyContent: "",
                    alignItems: "",
                    flexDirection: "",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Exam Banner
                  </div>
                  <UploadPDfTextField
                    type="file"
                    name="exambanner"
                    onChange={handleImageChange}
                  />
                </div> */}

                {/* <div
                  style={{
                    marginTop: "13px",
                    display: "",
                    justifyContent: "",
                    alignItems: "",
                    flexDirection: "",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Upload Exam Banner
                  </div>
                  <UploadPDfTextField
                    type="file"
                    name="exambanner"
                    onChange={handleIconChange}
                  />
                </div> */}

                <div style={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    style={{
                      margin: "15px",
                      background: "#004A80",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      margin: "15px",
                      background: "#004A80",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                  >
                    ADD MORE
                  </Button>
                </div>
              </div>
            </form>
            {/* Add inline styles for the messages */}
            {isLoading && (
              <p style={{ color: "blue", fontWeight: "bold" }}>Loading...</p>
            )}
            {isError && (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Error: {error && error.error}
              </p>
            )}
            {isSuccess && (
              <p style={{ color: "green", fontWeight: "bold" }}>Success!</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonsMainDiv>
              <ButtonsInnerDiv>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddExams"
                      active={activePage === "AddExams"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddFields"
                      active={activePage === "AddFields"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddLevels"
                      active={activePage === "AddLevels"} // Pass active prop
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddSubjects"
                      active={activePage === "AddSubjects"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddChapters"
                      active={activePage === "AddChapters"} // Pass active prop
                    />
                  </div>
                </div>
              </ButtonsInnerDiv>
            </ButtonsMainDiv>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddFields;
