import { Button, IconButton, TablePagination, Typography } from "@mui/material";
import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import { SameStyleCells, SpansButtons } from "../../styles/videostyle/ViewVideoFormStyle";
import ButtonsComponent from "../../components/globalcomponents/SecondTopBarOutlet/ButtonsComponent";
import { useGetVedio } from "../../customhook/vedioHook/useGetVedio";
import { useVideoTableFunction } from "../../customhook/vedioHook/useVideoTableFun";

const ViewVideoTable = () => {
  
  const { VideoData, isLoading, isSuccess } = useGetVedio();
  // console.log("VideoData", VideoData);


  const {
    handleActivate,
    handleDeActivate,
    handleView,
    handleDelete,
    handleEdit,
  } = useVideoTableFunction();


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <ButtonsComponent />
      <div style={{ padding: '20px' }}>
      <TableContainer component={Paper} style={{ border: '1px solid #DEDEDE' }}>
        <Table>
          <TableHead style={{ background: '#F3F3F3', boxShadow: 'none' }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Exam Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Field</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Level</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Subject</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Chapter</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Video Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {/* Loop through the data and display rows */}
              {VideoData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {typeof row?.categoryId?.examname === "No ExamName"
                      ? "Handle this case"
                      : row?.categoryId?.examname}
                  </TableCell>
                  <TableCell>
                    {typeof row?.subcategoryId?.subname === "No FiledName"
                      ? "Handle this case"
                      : row?.subcategoryId?.subname}
                  </TableCell>
                  <TableCell>
                    {row.Addlevel ? row.Addlevel.Addlevel : "No Addlevel"}
                  </TableCell>
                  <TableCell>
                    {row.subject ? row.subject.subject : "No SubjectName"}
                  </TableCell>
                  <TableCell>
                    {row.chapter ? row.chapter.chapter : "No ChapterName"}
                  </TableCell>
                  <TableCell>
                    <a
                      href={row?.uploadfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography>{row?.videoname}</Typography>
                    </a>
                  </TableCell>

                  {/*  <TableCell>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginRight: "5px" }}>
                          {row?.chapter}
                        </span>
                        <img
                          src={row.uploadfile}
                          alt="uploadfile"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </li>
                    </ul>
                  </TableCell> */}

                  {/* ... rest of your table cell data ... */}
                  {/* Action cell with edit and delete icons */}
                  <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row.id)}>
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={VideoData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </>
  );
};

export default ViewVideoTable;
