import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEditCategory } from "../../../customhook/category/useEditCategory";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editCategoryForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,
  } = useEditCategory(id || "");
// console.log("editCategoryForm?.Full_name",editCategoryForm?.Full_name)

  if (isloading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (issuccess) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        marginY="1rem"
      >
        <Paper elevation={3} style={{ width: "300px", padding: "10px", margin: "0 0" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Edit Category
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mb={1}>
              <TextField
                fullWidth
                label="Exam Name"
                id="examName"
                name="examname"
                value={editCategoryForm?.examname}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={1}>
              <TextField
                fullWidth
                label="Full Name"
                id="fullName"
                name="full_name"
                value={editCategoryForm?.full_name}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={1}>
              <TextField
                fullWidth
                type="file"
             
                id="logo"
                name="logo"
                onChange={handleFileChange}
              />
              {editCategoryForm?.logo && (
                <img
                  src={editCategoryForm?.logo}
                  alt="Logo Preview"
                  className="preview-image"
                  style={{ marginTop: "4px", width: "100%", height: "120px" }}
                  crossOrigin="anonymous"
                />
              )}
            </Box>
            <Box mb={1}>
              <TextField
                fullWidth
                type="file"
             
                id="exambanner"
                name="exambanner"
                onChange={handleFileChange}
              />
              {editCategoryForm?.exambanner && (
                <img
                  src={editCategoryForm?.exambanner}
                  alt="Exam Banner Preview"
                  className="preview-image"
                  style={{ marginTop: "4px", width: "100%", height: "120px" }}
                  crossOrigin="anonymous"
                />
              )}
            </Box>
            <Box textAlign="center" mt={2} mb={0}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="custom-btn"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }
};

export default EditCategory;
