import { Select, TextField, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';          
    
export const FirstDiv = styled ("div")({
    margin: "20px", padding: "20px",

    "@media (max-width: 820px)": {
      margin:"10px",
      padding:"10px"
          
        },

    "@media (max-width: 767px)": {
    margin:"0px",
    padding:"0px"
        
      },
})


export const TitleDiv = styled ("div")({
    fontFamily: "Poppins",
    fontWeight: "800",
    fontSize: "26px",
    color: "#06375A",
    marginLeft:"30px",
    "@media (max-width: 767px)": {
        marginLeft:"0px",
        
      },
})

export const SelectFieldsHeads = styled(Typography)({
  fontFamily: "Segoe UI",
  fontWeight: "600",
  fontSize: "20px",
  marginTop: "2px",
  marginLeft: "15px",
})


export const InnerMainDiv = styled ("div")({
    marginLeft:"30px",
    "@media (max-width: 767px)": {
    flexDirection:"column",
    marginLeft:"0px",
        
      },
})

export const DescDiv = styled ("div")({
    width:"400px",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    color: "#1F1E24",

    lineHeight: "27.5px",
    marginLeft:"30px",
    "@media (max-width: 767px)": {
      width:"290px",
      fontSize:"15px",
      marginLeft:"0px",
        
      },
})


export const SubMainDiv = styled ("div")({
    marginLeft:"50px",
    "@media (max-width: 768px)": {
     marginLeft:"0px"
        
      },
})

export const MySelect = styled (Select)({
   width:"340px",

   borderRadius: "10px",
   background: "#E9E9E9",
   height: "45px",
   "& fieldset": { border: "none",  outline: 'none',
    },


    "@media (max-width: 820px)": {
      width:"275px",
      marginLeft:"2px",
         
       },

    "@media (max-width: 768px)": {
     width:"280px",
     marginLeft:"2px",
      },
})

export const PdfNameField = styled (TextField)({
  width:"340px",
  marginLeft:"8px",
  marginTop:"8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  marginBottom:"6px",
  display:"flex",

  justifyContent:"center",
  height: "45px",
  "& fieldset": { border: "none",  outline: 'none',
   },

   "@media (max-width: 820px)": {
    width:"275px",
    marginLeft:"2px",
       
     },

   "@media (max-width: 768px)": {
    width:"280px",
    marginLeft:"2px",
       
     },
})

export const PdfDescField = styled (TextField)({
  width:"340px",
  marginLeft:"8px",
  marginTop:"8px",
  borderRadius: "10px",
  background: "#E9E9E9",
 marginBottom:"7px",
 display:"flex",
 alignItems:"start",
 justifyContent:"center",
  height: "45px",
  
  "& fieldset": { border: "none",  outline: 'none',
   },

   "@media (max-width: 820px)": {
    width:"275px",
    marginLeft:"2px",
       
     },

   "@media (max-width: 768px)": {
    width:"280px",
    marginLeft:"2px",
       
     },
})



export const ButtonsDiv = styled ("div")({
    marginTop:"" ,display:"flex",justifyContent:"space-evenly", alignItems:"center",
    "@media (max-width: 767px)": {
        marginTop:"20px",
    flexDirection:"column",
   
        
      },
})


export const ImageDiv = styled ("div")({
   
    "@media (max-width: 767px)": {
        marginTop:"30px",

   
        
      },
})

export const UploadPDfTextField = styled (TextField)({
  width:"340px",
marginTop:"10px",
marginLeft:"8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  height: "45px",
 
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  "& fieldset": { border: "none",  outline: 'none',
   },

   "@media (max-width: 820px)": {
    width:"277px",
    marginLeft:"2px",
       
     },


   "@media (max-width: 768px)": {
    width:"280px",
    marginLeft:"2px",
    
       
     },
})