import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, TextField, Typography, InputAdornment } from "@mui/material";
import view from "../../../assets/view.png";
import edit from "../../../assets/edit.png";
import {
  LoginTextFields,
  ParaDiv,
  UploadPDfTextField,
  HeadDiv,
  containerStyle,
  rowStyle,
  ButtonsMainDiv,
  ButtonsInnerDiv,
  TextFields,
} from "../../../styles/categorycompstyles/addexamstyle/AddExamsStyle";
import pdfupload from "../../../assets/pdfupload.png";
import examfield from "../../../assets/examfield.png";
import { useRef, useState } from "react";
import ButtonComponent from "../ButtonComp/ButtonComp";
import useCategoryForm from "../../../customhook/category/useAddCategory"; // Import the custom hook
import { Link } from "react-router-dom";

const AddExams = ({ onPageChange }) => {
  const [activePage, setActivePage] = useState("AddExams");

  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleImageChange,
    handleIconChange,
    isError,
    isLoading,
    isSuccess,
    error

  } = useCategoryForm(); // Use the custom hook

  return (
    <>
      <Box sx={{ margin: "20px" }}>
        <Grid container>
          <Grid
            style={{ background: "#F6F6F6", margin: "0px" }}
            item
            xs={12}
            sm={12}
            md={6}
          >
            <div style={{ margin: "10px" }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{
                    background: "#094C7D",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    textTransform: "capitalize",

                    color: "#FFFFFF",
                    paddingLeft: "18px",
                  }}
                  href="#contained-buttons"
                >
                  <img
                    src={edit}
                    alt="Your Image"
                    style={{ marginRight: "8px", width: "15px" }}
                  />
                  Edit
                </Button>

                <Link
                to="/viewExamTable" // Replace this with the actual path or URL
                style={{
                  textDecoration: 'none', // Remove underline if necessary
                  background: '#E8E8E8',
                  textTransform: 'capitalize',
                  borderTopRightRadius: '20px',
                  borderBottomRightRadius: '20px',
                  color: '#094C7D',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '6px 12px', // Adjust padding as needed
                }}
              >
                <img
                  src={view}
                  alt="Your Image"
                  style={{ marginRight: '6px', width: '18px' }}
                />
                View
              </Link>
              </div>
            </div>
            <HeadDiv style={{}}>
              <div>
                <Typography
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "24px",
                    fontWeight: "bolder",
                    color: "#06375A",
                  }}
                >
                  Add Exam
                </Typography>
              </div>
              <ParaDiv style={{}}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    letterSpacing: "1px",
                  }}
                >
                  Under each Exam, we'll provide the exam name and accompany it
                  with a visual representation.
                </Typography>
              </ParaDiv>
            </HeadDiv>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Segoe UI",
                      }}
                    >
                      Exam Name (Abbreviation)
                    </div>
                    <TextFields
                      type="text"
                      name="examname"
                      value={formData?.examname}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Segoe UI",
                      }}
                    >
                      Full Form
                    </div>
                    <TextFields
                      type="text"
                      name="full_name"
                      value={formData?.full_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "13px",
                    display: "",
                    justifyContent: "",
                    alignItems: "",
                    flexDirection: "",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Exam Logo
                  </div>
                  <UploadPDfTextField
                    type="file"
                    name="logo"
                    onChange={handleImageChange}
                    required
                  />
                </div>

                <div
                  style={{
                    marginTop: "13px",
                    display: "",
                    justifyContent: "",
                    alignItems: "",
                    flexDirection: "",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Exam Banner
                  </div>
                  <UploadPDfTextField
                    type="file"
                    name="exambanner"
                    onChange={handleIconChange}
                    required
                  />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    style={{
                      margin: "15px",
                      background: "#004A80",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      margin: "15px",
                      background: "#004A80",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                  >
                    ADD MORE
                  </Button>
                </div>

              </div>
            </form>
            {/* Add inline styles for the messages */}
      {isLoading && (
        <p style={{ color: 'blue', fontWeight: 'bold' }}>Loading...</p>
      )}
      {isError && (
        <p style={{ color: 'red', fontWeight: 'bold' }}>
          Error: {error && error.error}
        </p>
      )}
      {isSuccess && (
        <p style={{ color: 'green', fontWeight: 'bold' }}>Success!</p>
      )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonsMainDiv>
              <ButtonsInnerDiv>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddExams"
                      active={activePage === "AddExams"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddFields"
                      active={activePage === "AddFields"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddLevels"
                      active={activePage === "AddLevels"} // Pass active prop
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddSubjects"
                      active={activePage === "AddSubjects"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddChapters"
                      active={activePage === "AddChapters"} // Pass active prop
                    />
                  </div>
                </div>
              </ButtonsInnerDiv>
            </ButtonsMainDiv>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                height: "58vh",
              }}
            >
              <div style={{ flexGrow: 0 }}></div> {/* Spacer */}
              <img
                src={examfield}
                alt="Your Image"
                style={{ width: "300px" }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default AddExams;


























// import React from "react";
// import useCategoryForm from "../../../customhook/category/useAddCategory"; // Import the custom hook

// const MyForm = () => {
//   const {
//     formData,
//     handleSubmit,
//     handleInputChange,
//     handleImageChange,
//     handleIconChange,
//     isLoading,
//   } = useCategoryForm(); // Use the custom hook

//   return (
//     <form onSubmit={handleSubmit}>
//       <input
//         type="text"
//         name="examname"
//         value={formData?.examname}
//         onChange={handleInputChange}
//       />
//       <input
//         type="text"
//         name="Full_name"
//         value={formData?.Full_name}
//         onChange={handleInputChange}
//       />
//       <input type="file" name="logo" onChange={handleImageChange} />
//       <input type="file" name="exambanner" onChange={handleIconChange} />
//       <button type="submit">
//         {isLoading ? "Adding..." : "Add Category"}
//       </button>
//     </form>
//   );
// };

// export default MyForm;
