import { Box, Button, Grid, InputAdornment } from "@mui/material";
import React, { useRef } from "react";
import {
  BannerImg,
  DescDiv,
  FirstDiv,
  PdfDescField,
  PdfNameField,
  SelectFieldsHeads,
  TitleDiv,
} from "../../styles/bannerstyle/AddBannerFormStyle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ImageDiv,
  UploadPDfTextField,
} from "../../styles/bannerstyle/AddBannerFormStyle";
import pdfupload from "../../assets/pdfupload.png";
import addbannerpic from "../../assets/addbannerpic.png";
import useBannerForm from "../../customhook/banner/useAddBanner";

const AddBannerForm = () => {
  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleAddClick,
    handleRemoveClick,
    handleIncorrectAnswerChange,
    handleFileSelected,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useBannerForm();

  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Box style={{ margin: "20px", padding: "20px", background: "#F6F6F6" }}>
        <Grid container>
          <Grid md={6} sm={6}>
            <FirstDiv style={{}}>
              <TitleDiv>Add APP BANNER</TitleDiv>
              <DescDiv>
                Categorizing by selecting the exam, subject, and chapter, add
                the respective MCQs.
              </DescDiv>
              <form onSubmit={handleSubmit}>
              <div>
                  <SelectFieldsHeads>Title</SelectFieldsHeads>
                </div>
                <div>
                  <PdfDescField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    required
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <SelectFieldsHeads>Name</SelectFieldsHeads>
                </div>
                <div>
                  <PdfDescField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <SelectFieldsHeads>Description</SelectFieldsHeads>
                </div>
                <div>
                  <PdfNameField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    required
                    name="description"
                    value={formData?.description}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <SelectFieldsHeads>Upload Images</SelectFieldsHeads>
                </div>
                {formData.bannerImage.map((image, index) => (
                  <div key={index} style={{ display: "flex", alignItems: "center" }}>
                    <PdfDescField
                      id={`bannerImage${index}`}
                      name="bannerImage"
                      value={image.name}
                      disabled
                      label=""
                      variant="outlined"
                    />
                    <RemoveIcon
                      onClick={() => handleRemoveClick(index)}
                      style={{
                        marginLeft: "4px",
                        cursor: "pointer",
                        background: "#CB0808",
                        borderRadius: "50%",
                        padding: "",
                        color: "white",
                      }}
                    />
                  </div>
                ))}
                <Button
                  onClick={handleFileUploadClick}
                  variant="contained"
                  component="span"
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    background: "#0F77A4",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  <AddIcon />
                  Add Image
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelected}
                  multiple
                />

                <div style={{ marginLeft: "100px" }}>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    style={{
                      marginLeft: "5px",
                      backgroundColor: "#004A80",
                      marginTop: "40px",
                    }}
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                </div>
              </form>
              {isLoading && (
                <p style={{ color: "blue", fontWeight: "bold" }}>Loading...</p>
              )}
              {isError && (
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Error: {error && error.error}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green", fontWeight: "bold" }}>Success!</p>
              )}
            </FirstDiv>
          </Grid>
          <Grid md={6} sm={6}>
            <ImageDiv style={{}}>
              {/* Other content in ImageDiv */}
              <div style={{ alignSelf: "center" }}>
                <BannerImg src={addbannerpic} style={{}} />
              </div>
            </ImageDiv>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AddBannerForm;