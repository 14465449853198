import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const fieldApiSlice  = createApi({
  reducerPath: 'fieldApiSlice',
  tagTypes: ["SUBCATEGORYDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  }),
  endpoints: (builder) => ({
    addsubCategory: builder.mutation({
      query: (data) => ({
        url: 'subcategory',
        method: 'POST',
        body: data,
      }),

      invalidatesTags: ["SUBCATEGORYDATA"],
    
    }),

    getSinglesubCategoryByID: builder.query({
      query: (id) => ({
        url: `subcategory/${id}`,
        method: "GET",
      }),
      providesTags: ["SUBCATEGORYDATA"],
   
    }),

    getSubCategoryData: builder.query({
      query: () => ({
        url: 'subcategory/admin',
        method: 'GET',
      }),
      providesTags: ["SUBCATEGORYDATA"],
    }),

    UpdateSubCategoryData: builder.mutation({
      query: (params) => ({
        url: `subcategory/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivatesubCategory: builder.mutation({
      query: (id) => ({
        url: `subcategory/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["SUBCATEGORYDATA"],
    }),

    getDeActivatesubCategory: builder.mutation({
      query: (id) => ({
        url: `subcategory/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["SUBCATEGORYDATA"],
    }),

    deletesubCategory: builder.mutation({
      query: (id) => ({
        url: `subcategory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SUBCATEGORYDATA"],
     
    }),
  }),
});

export const {useAddsubCategoryMutation, useGetSinglesubCategoryByIDQuery,useGetSubCategoryDataQuery, useUpdateSubCategoryDataMutation, useGetActivatesubCategoryMutation, useGetDeActivatesubCategoryMutation, useDeletesubCategoryMutation  } = fieldApiSlice;
