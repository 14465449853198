import { useNavigate } from "react-router-dom";
// import { useDeleteSubjectMutation, useGetActivateSubjectMutation, useGetDeActivateSubjectMutation } from "../../features/subject/AddSubjectSlice";
import { useDeleteChapterMutation, useGetActivateSubjectMutation, useGetDeActivateSubjectMutation } from "../../features/chapters/ChapterSlice";
// import { useDeletesubCategoryMutation, useGetActivatesubCategoryMutation, useGetDeActivatesubCategoryMutation, useUpdatesubCategoryDataMutation} from "../../features/field/FieldSlice";

export const useChapterTableFunction = () => {
    const navigate = useNavigate();
    const [activate] = useGetActivateSubjectMutation();
    const [deactivate] = useGetDeActivateSubjectMutation();
    const [deleteData] = useDeleteChapterMutation();
 
    const handleView = (id) => {
      navigate(`/ViewSingleChapterCat/${id}`);
    };
    const handleEdit = (id) => {
      navigate(`/EditChapter/${id}`);
    };
  
    const handleDelete = async (id) => {
      // try {
      //   const response = (await deleteData(id).unwrap()) ;
      //   if (response.data.success === true) {
      //     window.alert(response.data.message);
      //   }
      // } catch (error) {
      //   console.error("Error activating banner", error);
      // }

      try {
        const response = await deleteData(id).unwrap();
        if (response) {
          window.alert("Given Chapter " + response.message);
        }
      } catch (error) {
        console.error("Error deleting  Data", error);
      }
    };
  
    const handleActivate = async (id) => {
      try {
        const response = await activate(id).unwrap();
        if (response) {
          window.alert("Given Chapter " + response.message);
        }
      } catch (error) {
        console.error("Error activating banner", error);
      }
    };
   
  
    const handleDeActivate = async (id) => {
      try {
        const response = await deactivate(id).unwrap();
        if (response) {
          window.alert("Given Chapter " + response.message);
        }
      } catch (error) {
        console.error("Error activating banner", error);
      }
    };
  
    return {
      handleView,
      handleEdit,
      handleActivate,
      handleDeActivate,
      handleDelete,
    };
  };
  