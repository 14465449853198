import React from 'react'

import ViewSingleCat from '../../../components/categorycomponents/addexam/ViewSingleCat'


const ViewSingleCategory = () => {
  return (
 <>
<ViewSingleCat/>
 </>
  )
}

export default ViewSingleCategory