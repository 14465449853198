import { useState } from "react";
import { useAddCategoryMutation } from "../../features/category/CategorySlice";

const useCategoryForm = () => {
  const [formData, setFormData] = useState({
    examname: "",
    full_name: "",
    logo: null,
    exambanner: null,
  });

  const [addCategory, { isLoading, isError, isSuccess, error }] =
    useAddCategoryMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      logo: imageFile,
    }));
  };

  const handleIconChange = (event) => {
    const iconFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      exambanner: iconFile,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
   
    try {
      const FormValues = new FormData();
      FormValues.append("examname", formData.examname);
      FormValues.append("full_name", formData.full_name);
      FormValues.append("logo", formData.logo);
      FormValues.append("exambanner", formData.exambanner);
      const response = await addCategory(FormValues).unwrap();
      console.log(response);
     
    } catch (error) {
      console.log(error);
    }
  };

  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    handleImageChange,
    handleIconChange,
    handleSubmit,
  };
};

export default useCategoryForm;
