import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSingleChapterByIDQuery, useUpdateChapterDataMutation } from "../../features/chapters/ChapterSlice";

export const useEditChapter = (id) => {
  const navigate = useNavigate();
  const [editChapterForm, setEditChapterForm] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
    subject: "",
    chapter: "",
    uploadfile: "",
  });
// console.log("editChapterForm", editChapterForm)

  const {
    data: singlechapter,
    isLoading,
    isSuccess,
  } = useGetSingleChapterByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  // const categoryId = singlechapter?.category?.categoryId;
  useEffect(() => {
    if (singlechapter) {
        setEditChapterForm({
        categoryId: singlechapter?.level?.categoryId?._id,
        subcategoryId: singlechapter?.level?.subcategoryId?._id,
        Addlevel: singlechapter?.level?.Addlevel?._id,
        subject: singlechapter?.level?.subject?._id,
        chapter: singlechapter?.level?.chapter,
        uploadfile: singlechapter?.level?.uploadfile,
        // subject: "65cc5de36ca1435880f630da",
        // uploadfile: "http://preparlyapi-env.eba-u4ipsxzx.us-east-1.elasticbeanstalk.com/uploads/chapter-Pg2UnV3cG-chapter.jpeg",

        // exambanner: singlechapter?.category?.exambanner,
        // Update with additional fields if needed
      });
    }
  }, [singlechapter]);

  // console.log("singlechaptersinglechapter",singlechapter)

  const imageUrl = editChapterForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditChapterForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0] || null;
    setEditChapterForm({ ...editChapterForm, uploadfile: file });
  };

  const [UpdateSubCategoryData] = useUpdateChapterDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("categoryId", editChapterForm.categoryId);
      formData.append("subcategoryId", editChapterForm.subcategoryId);
      // formData.append("Addlevel", editChapterForm.Addlevel);
      formData.append("subject", editChapterForm.subject);
      formData.append("chapter", editChapterForm.chapter);
      formData.append("uploadfile", editChapterForm.uploadfile);
    //   formData.append("exambanner", editChapterForm.exambanner);

      // Update with additional fields if needed

      const response = (await UpdateSubCategoryData({
        id,
        data: formData,
      })).data;

      if (response.success === true) {
        window.alert(response.message);
        navigate("/viewChapterTable");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editChapterForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};

