import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetSingleMocktestByIDQuery,
  useUpdateMocktestDataMutation,
} from "../../features/Mock Test/MocktestSlice";

export const useEditMock = (id) => {
  const navigate = useNavigate();
  const [editMocktestForm, setEditMocktestForm] = useState({
    categoryId: "",
    subcategoryId: "",
    mocktestname: "",
    questions: [
      {
        questionId: "",
        question: "",
        correct_answer: "",
        incorrect_answer: "",
      },
    ],
  });
  // console.log("mocktest", editMocktestForm);

  const {
    data: singleMock,
    isLoading,
    isSuccess,
  } = useGetSingleMocktestByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (singleMock) {
      setEditMocktestForm(prevState => ({
        ...prevState,
        categoryId: singleMock?.data?.categoryId?._id,
        subcategoryId: singleMock?.data?.subcategoryId?._id,
        mocktestname: singleMock?.data?.mockname,
        questions: singleMock.data.questions.map(question => ({
          questionId: question.questionId,
          question: question.question,
          correct_answer: question.correct_answer,
          incorrect_answer: question.incorrect_answer,
        })),
      }));
    }
  }, [singleMock]);
  // console.log(singleMock, "singleMock");
  const handleChange = (e, questionIndex, answerIndex) => {
    const { value } = e.target;
    setEditMocktestForm((prevData) => ({
      ...prevData,
      questions: prevData.questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              incorrect_answer: question.incorrect_answer.map((answer, j) =>
                j === answerIndex ? value : answer
              ),
            }
          : question
      ),
    }));
  };

  const handleExam = (e) => {
    const { value, name } = e.target;
    setEditMocktestForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [updateMcqsData] = useUpdateMocktestDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const FormValues = {
        categoryId: editMocktestForm.categoryId,
        subcategoryId: editMocktestForm.subcategoryId,
        mockname: editMocktestForm.mocktestname,
        questions: editMocktestForm.questions.map((question) => ({
          questionId: question.questionId,
          question: question.question,
          correct_answer: question.correct_answer,
          incorrect_answer: question.incorrect_answer,
        })),
      };

      const response = await updateMcqsData({
        id,
        data: FormValues,
      });

      if (response.data.success === true) {
        window.alert(response.data.message);
        navigate("/ViewMock");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editMocktestForm,
    handleChange,
    handleExam,
    handleSubmit,
    isLoading,
    isSuccess,
  };
};
