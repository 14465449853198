import React from "react";
import { useGetCategoryDataQuery } from "../../features/category/CategorySlice";

export const useGetCategory = () => {
  const { data, isSuccess, isLoading } = useGetCategoryDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const transformedData = React.useMemo(() => {
    const getCategoryData = data || [];
    return getCategoryData?.map((catData) => ({
      id: catData?._id,
      examname: catData?.examname, // Replace 'name' with the correct attribute name if needed
      full_name: catData?.full_name, // Assuming catdescription is an array
      logo: catData?.logo,
      exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);

  return {
    CategoryData: transformedData,
    isLoading,
    isSuccess,
  };
};
