import { useNavigate } from "react-router-dom";
import { useGetActivatMcqsMutation, useGetDeActivateMcqsMutation, useDeleteMcqsMutation } from "../../features/mcqs/mcqsSlice";

// import { useDeleteChapterMutation, useGetActivateSubjectMutation, useGetDeActivateSubjectMutation } from "../../features/chapters/ChapterSlice";
// import { useDeletePdfMutation, useGetActivatMcqsMutation, useGetDeActivateMcqsMutation } from "../../features/pdf/PdfSlice";

export const useMCQSTableFunction = () => {
    const navigate = useNavigate();
    const [activate] = useGetActivatMcqsMutation();
    const [deactivate] = useGetDeActivateMcqsMutation();
    const [deleteData] = useDeleteMcqsMutation();
 
    const handleView = (id) => {
      navigate(`/ViewSingleMcqsCat/${id}`);
    };
    const handleEdit = (id) => {
      navigate(`/EditMcqs/${id}`);
    };
  
    const handleDelete = async (id) => {
      // try {
      //   const response = (await deleteData(id).unwrap()) ;
      //   if (response.data.success === true) {
      //     window.alert(response.data.message);
      //   }
      // } catch (error) {
      //   console.error("Error activating banner", error);
      // }

      try {
        const response = await deleteData(id).unwrap();
        if (response) {
          window.alert("Given MCQS " + response.message);
        }
      } catch (error) {
        console.error("Error deleting  Data", error);
      }
    };
  
    const handleActivate = async (id) => {
      try {
        const response = await activate(id).unwrap();
        if (response) {
          window.alert("Given MCQS " + response.message);
        }
      } catch (error) {
        console.error("Error activating banner", error);
      }
    };
   
  
    const handleDeActivate = async (id) => {
      try {
        const response = await deactivate(id).unwrap();
        if (response) {
          window.alert("Given MCQS " + response.message);
        }
      } catch (error) {
        console.error("Error activating banner", error);
      }
    };
  
    return {
      handleView,
      handleEdit,
      handleActivate,
      handleDeActivate,
      handleDelete,
    };
  };
  