import { Select, TextField, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';   




export const SelectFieldsHeads = styled(Typography)({
    fontFamily: "Segoe UI",
    fontWeight: "500",
    fontSize: "17px",
    marginTop: "2px",
    marginLeft: "8px",
  })


  
export const NameField = styled (TextField)({
    width:"440px",
    marginLeft:"8px",
    marginTop:"8px",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    // border:"2 px solid red",
    marginBottom:"6px",
    display:"flex",
    // alignItems:"start",
    justifyContent:"center",
    height: "45px",
    "& fieldset": { border: "none",  outline: 'none',
     },
  
     "@media (max-width: 820px)": {
      width:"275px",
      marginLeft:"2px",
         
       },
  
     "@media (max-width: 768px)": {
      width:"280px",
      marginLeft:"2px",
         
       },
  })
  