import { useNavigate } from "react-router-dom";
import { useDeleteCategoryMutation, useGetActivateCategoryMutation, useGetDeActivateCategoryMutation, useUpdateCategoryDataMutation } from "../../features/category/CategorySlice";

export const useCategoryTableFunction = () => {
    const navigate = useNavigate();
    const [activate] = useGetActivateCategoryMutation();
    const [deactivate] = useGetDeActivateCategoryMutation();
    const [deleteData] = useDeleteCategoryMutation();
 
    const handleView = (id) => {
      navigate(`/viewCategory/${id}`);
    };
    const handleEdit = (id) => {
      navigate(`/editCategory/${id}`);
    };
  
    const handleDelete = async (id) => {
      // try {
      //   const response = (await deleteData(id).unwrap()) ;
      //   if (response.data.success === true) {
      //     window.alert(response.data.message);
      //   }
      // } catch (error) {
      //   console.error("Error activating banner", error);
      // }

      try {
        const response = await deleteData(id).unwrap();
        if (response) {
          window.alert("Given Category " + response.message);
        }
      } catch (error) {
        console.error("Error deleting  Data", error);
      }
    };
  
    const handleActivate = async (id) => {
      try {
        const response = await activate(id).unwrap();
        if (response) {
          window.alert("Given Category " + response.message);
        }
      } catch (error) {
        console.error("Error activating banner", error);
      }
    };
   
  
    const handleDeActivate = async (id) => {
      try {
        const response = await deactivate(id).unwrap();
        if (response) {
          window.alert("Given Category " + response.message);
        }
      } catch (error) {
        console.error("Error activating banner", error);
      }
    };
  
    return {
      handleView,
      handleEdit,
      handleActivate,
      handleDeActivate,
      handleDelete,
    };
  };
  