import React from 'react'
import ViewBannerTable from '../../components/Bannercomponents/ViewBannerTable'

const ViewBanner = () => {
  return (
   <>
   <ViewBannerTable/>
   </>
  )
}

export default ViewBanner