import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useGetSinglePdfByIDQuery } from "../../../features/pdf/PdfSlice";
import { useGetSingleUserByIDQuery } from "../../features/user/UserSlice";
import { Avatar } from "@mui/material";

const ViewSingleUserCat = () => {
  const params = useParams();
  const location = useLocation();

  //   console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleUserByIDQuery(params.id);

  console.log("Userdataaaa", data);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data) {
    return <div>Error loading data...</div>;
  }

  const { MockData } = data;
  // console.log("McqsData", McqsData);

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
        {/* Left Side - User Details */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Profile Picture:
          </Typography>
          <Avatar
            src={data?.profilePicture}
            alt={`${data?.data?.firstName} ${data?.data?.lastName}`}
            style={{ width: "100px", height: "100px" }}
          />

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Username:
          </Typography>
          <Typography>{data?.username}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Email:
          </Typography>
          <Typography>{data?.email}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Phone Number:
          </Typography>
          <Typography>{data?.phonenumber}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            First Name:
          </Typography>
          <Typography>{data?.firstName}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Last Name:
          </Typography>
          <Typography>{data?.lastName}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Role:
          </Typography>
          <Typography>{data?.role}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </Paper>
  );
};

export default ViewSingleUserCat;
