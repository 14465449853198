import React from 'react'

import ViewCat from '../../../components/categorycomponents/viewcategory/ViewCategoryComp'
const ViewCategory = () => {
  return (
   <>
   <ViewCat/>
   </>
  )
}

export default ViewCategory