import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Checkbox,
  IconButton,
  Button, // Import IconButton from Material UI
} from "@mui/material";
// import AddExamButtonComp from "../../../components/globalcomponents/SecondTopBarOutlet/AddExamTopButtons";
import AddFieldButtonComp from "../../globalcomponents/SecondTopBarOutlet/AddExamTopButtons";
// import { useGetCategory } from "../../../customhook/category/useGetSubCategory"; // Replace with your correct path
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";
// import { useGetSubject } from "../../../customhook/subject/useGetSubject";
import { useGetChapter } from "../../../customhook/chapter/useGetChapter";

// Import icons for edit and delete
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SpansButtons } from "../../../styles/categorycompstyles/addexamstyle/ViewExamStyle";
// import { useSubCategoryTableFunction } from "../../../customhook/field-category/useSubCategoryTableFun";
// import { useSubjectTableFunction } from "../../../customhook/subject/useSubjectTableFun";
import { useChapterTableFunction } from "../../../customhook/chapter/useChapterTabelFun";

const ViewChapterTable = () => {
  // Fetching data using your hook
  const { ChapterData, isLoading, isSuccess } = useGetChapter();
  console.log("ChapterData", ChapterData);

  const {
    handleActivate,
    handleDeActivate,
    handleView,
    handleDelete,
    handleEdit,
  } = useChapterTableFunction();
  // const [getdashboard, setgetdashboard] = useState(getdashboard); // Initialize with the data from useGetSubCategory

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set rows per page to 5

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <AddFieldButtonComp />
      <div style={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          style={{ border: "1px solid #DEDEDE" }}
        >
          <Table>
            <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Choose Exam
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>Field Name</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>
                  Choose Field
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Choose Level
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Choose Subjects
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Add Chapter
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Loop through the data and display rows */}
              {ChapterData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {typeof row?.categoryId?.examname === "object"
                      ? "Handle this case"
                      : row?.categoryId?.examname}
                  </TableCell>
                  <TableCell>
                    {typeof row?.subcategoryId?.subname === "object"
                      ? "Handle this case"
                      : row?.subcategoryId?.subname}
                  </TableCell>
                  <TableCell>
                    {row.Addlevel ? row.Addlevel.Addlevel : "No Addlevel"}
                  </TableCell>
                  <TableCell>
                    {row.subject ? row.subject.subject : "No Addlevel"}
                  </TableCell>
                  <TableCell>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginRight: "5px" }}>
                          {row?.chapter}
                          {/* {row?.uploadfile} */}
                        </span>
                        <img
                          src={row.uploadfile}
                          alt="uploadfile"
                          style={{ width: "50px", height: "50px" }}
                          crossOrigin="anonymous"
                        />
                      </li>
                    </ul>
                  </TableCell>

                  {/* ... rest of your table cell data ... */}
                  {/* Action cell with edit and delete icons */}
                  <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row.id)}>
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Table pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ChapterData?.length}
          //   count={SubCategoryData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ViewChapterTable;
