import { Button, IconButton, TablePagination } from "@mui/material";
import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import {
  SameStyleCells,
  SpansButtons,
} from "../../styles/mcqsstyle/ViewMcqsTableStyle";
import ButtonsComponent from "../../components/globalcomponents/SecondTopBarOutlet/ButtonsComponent";
import { useMCQSTableFunction } from "../../customhook/MCQSHook/useMcqsTableFun";
import { useGetMcqs } from "../../customhook/MCQSHook/useGetMcqs";

const ViewMcqsTable = () => {
  const { McqsData, isLoading, isSuccess } = useGetMcqs();
  // console.log("McqsData", McqsData);
  const {
    handleActivate,
    handleDeActivate,
    handleView,
    handleDelete,
    handleEdit,
  } = useMCQSTableFunction();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Sample data for the table (Replace this with your actual data)
  // const rows = [
  //   { id: 1, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
  //   { id: 2, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
  //   { id: 3, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
  //   { id: 4, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
  //   { id: 5, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
  //   // Five more rows
  //   { id: 6, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
  //   { id: 7, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },

  // ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <ButtonsComponent />
      <div style={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          style={{ border: "1px solid #DEDEDE" }}
        >
          <Table>
            <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Exam Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Field</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Level</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Subject</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Chapter</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>MCQS</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Loop through the data and display rows */}
              {McqsData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {row.categoryId ? row.categoryId.examname : "No AddExam Added"}
                  </TableCell>
                 
                  <TableCell>
                    {row.subcategoryId ? row.subcategoryId.subname : "No AddField Added"}
                  </TableCell>
                  <TableCell>
                    {row.addlevel ? row.addlevel.Addlevel : "No Addlevel Added"}
                  </TableCell>
                  <TableCell>
                    {row.subject ? row.subject.subject : "No Subject Added"}
                  </TableCell>
                  <TableCell>
                    {row.chapter ? row.chapter.chapter : "No Chapter Added"}
                  </TableCell>
                  <TableCell>
                    {row.question ? row.question : "No Chapter Added"}
                  </TableCell>
                 
                  <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row.id)}>
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {/* <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const allFieldsFilled = (
                row.examName &&
                row.field &&
                row.level &&
                row.subject &&
                row.chapter &&
                row.pdf &&
                row.status
              );
          
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: '#EEEEEE' }} />
                  </TableCell>
                  <TableCell>{row.examName}</TableCell>
                  <TableCell>{row.field}</TableCell>
                  <TableCell>{row.level}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{row.chapter}</TableCell>
                  <TableCell>{row.pdf}</TableCell>
                  <TableCell style={{ color: 'green', fontWeight: 'bold' }}>{row.status}</TableCell>
                  <TableCell>
                    {allFieldsFilled && (
                      <>
                        <SpansButtons>
                          <i className="fas fa-edit"></i>
                        </SpansButtons>
                        <SpansButtons>
                          <i className="fas fa-eye"></i>
                        </SpansButtons>
                        <SpansButtons>
                          <i className="fas fa-trash"></i>
                        </SpansButtons>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          }
          </TableBody> */}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          // count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ViewMcqsTable;
