import { Box ,Grid,Paper, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';


export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.secondary,
    borderRadius: "10px",
    margin: "10px",
  }));

export const MainBoxContainer = styled(Box)({
    flexGrow: 1, 
    backgroundColor: "#F5F5F5",
    // height:"100%"
})

export const FirstTextContainer = styled("div")({
    marginLeft: "50px", 
    marginTop: "20px" 
})

export const FirstHeading = styled(Typography)({
                   marginLeft: "18px",
                  marginTop: "5px",
                  fontFamily: "Poppins",
                  fontWeight: "800",
                  fontSize: "28px",
                  color: "#004A80",
})

export const FirstPara = styled(Typography)({
    marginLeft: "20px",
    marginTop: "5px",
    color: "#004A80",
    width: "250px",
    fontWeight: "400",
    fontSize: "16px",
    fontFamily: "Imprima",
})

export const MainItemOuterDiv = styled(Grid)({

   

})

export const Mainitem = styled(Item)({
    display: "flex", 
    flexDirection: "column" 
})

export const MainItemInnerDiv = styled("div")({
    alignSelf: "flex-end",
 "@media (max-width: 768px)": {
           alignSelf:"center",
          
         }
    
})

export const MainItemImage = styled("img")({
    marginTop: "80px",
    //  width: "300px",
      // height: "280px" 
})

export const SecondItem = styled(Item)({
//  height:"100%"
})

export const SecondItemMainDiv = styled("div")({
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    // height: "100%",
   })

   export const MobileImage = styled("img")({
    width: "", height: "auto", marginBottom: "30px"
   })


   export const ChartImage = styled("img")({
    width: "",
     height: "auto",
    // marginTop:"60px"
   })

   export const ThirdRowFirstGrid = styled(Grid)({
    // position: "relative",
    // top: "-260px",

    // "@media (max-width: 1280px)": {
    //   top: "-150px",
    // },

    // "@media (max-width: 1024px)": {
    //   top: "-20px",
    // },

    // "@media (max-width: 912px)": {
    //   top: "-20px",
    // },

    // "@media (max-width: 820px)": {
    //   top: "10px",
    // },

    // "@media (max-width: 768px)": {
    //   top: "10px",
    // },
   })


   
   export const ThirdFirstItem = styled(Item)({
    height: "150px",
     borderRadius: "20px" 
   })

   export const ThirdRowSecondGrid = styled(Grid)({
    // position: "relative",
    // top: "-260px",

    // "@media (max-width: 1280px)": {
    //   top: "-150px",
    // },

    // "@media (max-width: 1024px)": {
    //   top: "-20px",
    // },

    // "@media (max-width: 912px)": {
    //   top: "-20px",
    // },

    // "@media (max-width: 820px)": {
    //   top: "10px",
    // },

    // "@media (max-width: 768px)": {
    //   top: "10px",
    // },
   })



   export const ThirdSecondItem= styled(Item)({
    height: "150px",
     borderRadius: "20px" 
   })
