import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useGetSingleVideoByIDQuery } from "../../features/video/VideoSlice";

const ViewSingleVideoCat = () => {
  const params = useParams();
  const location = useLocation();
  const { data, isLoading, isSuccess } = useGetSingleVideoByIDQuery(params.id);
  // console.log("data", data);
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.video) {
    return <div>Error loading data...</div>;
  }

  const { video } = data;
  // console.log("video", video);

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            uploadfile:
          </Typography>
          {/* <video
        controls
        src={video && video?.uploadfile} // Corrected conditional rendering
        style={{
          width: '180px',
          height: '160px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
      </video> */}
          <a href={video?.uploadfile} target="_blank" rel="noopener noreferrer">
            <Typography>{video?.uploadfile}</Typography>
          </a>
          {/* <img
            src={video?.uploadfile}
            alt="Video not Found"
            style={{
              width: "180px",
              height: "160px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          /> */}
        </Grid>
        {/* Right Side - Exam Name, Full Name, and Active Status */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>
          {video?.categoryId && (
            <Typography key={video.categoryId._id}>
              {video?.categoryId}
            </Typography>
          )}
          <Typography variant="h6" color="primary">
            Field Name:
          </Typography>
          {video?.subcategoryId && (
            <Typography key={video.subcategoryId._id}>
              {video?.subcategoryId}
            </Typography>
          )}

          <Typography variant="h6" color="primary">
            Level Name:
          </Typography>
          {video?.Addlevel && (
            <Typography key={video.Addlevel._id}>{video?.Addlevel}</Typography>
          )}
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Subject Name:
          </Typography>
          {video?.subject && (
            <Typography key={video.subject._id}>{video?.subject}</Typography>
          )}
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Chapter Name:
          </Typography>
          {video?.chapter && (
            <Typography key={video.subject._id}>{video?.chapter}</Typography>
          )}
          {/* <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Video Name:
          </Typography>
          {video?.videoname && (
            <Typography key={video?._id}>{video?.videoname}</Typography>
          )} */}
              <Typography variant="h6" color="primary" style={{ marginTop: "10px" }}>
      Video Name:
    </Typography>
    {video?.videoname}
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Description Name:
          </Typography>
          {video?.description && (
            <Typography key={video?._id}>{video?.description}</Typography>
          )}

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Status:
          </Typography>
          <Typography
            style={{
              color: video.isActive ? activeColor : inactiveColor,
            }}
          >
            {video.isActive ? "Active" : "Inactive"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewSingleVideoCat;
