import React from 'react'
import AddPastPaperForm from "../../components/pastpapercomponents/AddPastPaperForm"
const AddPastPaper = () => {
  return (
<>
<AddPastPaperForm/>
</>
  )
}

export default AddPastPaper