import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Layout from '../layout/Layout';

const ProtectedRoute = () => {
  const user = localStorage.getItem("authToken");


  // console.log(user);

  return (
    <>
      {user !== null ? (
        <Outlet />
      ) : (
    <Navigate to="/signin" />
      )}
    </>
  );
}

export default ProtectedRoute;
