import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const PdfSlice = createApi({
  reducerPath: "PdfSlice",
  tagTypes: ["PDFDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addpdf: builder.mutation({
      query: (data) => ({
        url: "pdf",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["PDFDATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),
     getSinglePdfByID: builder.query({
      query: (id) => ({
        url: `pdf/${id}`,
        method: "GET",
      }),
      providesTags: ["PDFDATA"],
   
    }),

    getPdfData: builder.query({
      query: () => ({
        url: 'pdf/admin',
        method: 'GET',
      }),
      providesTags: ["PDFDATA"],
    }),

    UpdatePdfData: builder.mutation({
      query: (params) => ({
        url: `pdf/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivatPdf: builder.mutation({
      query: (id) => ({
        url: `pdf/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["PDFDATA"],
    }),

    getDeActivatePdft: builder.mutation({
      query: (id) => ({
        url: `pdf/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["PDFDATA"],
    }),

    deletePdf: builder.mutation({
      query: (id) => ({
        url: `pdf/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PDFDATA"],
     
    }),
}),
});
export const { useAddpdfMutation, useGetSinglePdfByIDQuery, useGetPdfDataQuery, useUpdatePdfDataMutation, useGetActivatPdfMutation ,useGetDeActivatePdftMutation, useDeletePdfMutation } = PdfSlice;