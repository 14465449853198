import React from "react";

import { Grid,} from "@mui/material";

import exam from "../../assets/exam.png";
import mobile from "../../assets/mobile.png";
import chart from "../../assets/chart.png";
import { useMediaQuery } from "@mui/material";
import {
  MainBoxContainer,
  FirstTextContainer,
  FirstHeading,
  FirstPara,
  Mainitem,
  MainItemInnerDiv,
  MainItemImage,
  SecondItem,
  SecondItemMainDiv,
  MobileImage,
  ChartImage,
  ThirdRowMainGrid,
  ThirdFirstItem,
  ThirdRowFirstGrid,
  ThirdRowSecondGrid,
  ThirdSecondItem,
  MainItemOuterDiv,
} from "./DashboardStyle";

const DashboardComp = () => {
  const isSmallScreen = useMediaQuery("(max-width: 767px)");
  return (
    <MainBoxContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <item>
            <FirstTextContainer>
              <FirstHeading>Preparely</FirstHeading>

              <FirstPara>
                Learn through Video lessons, pdf Documents, mock up exams and
                Mcqs.
              </FirstPara>
            </FirstTextContainer>
          </item>
        </Grid>

        <MainItemOuterDiv
          item
          xs={12}
          sm={8}
          md={8}
          style={{ paddingLeft: "30px" }}
        >
          <Mainitem style={{}}>
            <MainItemInnerDiv style={{}}>
              <div style={{}}>
                <MainItemImage
                  src={exam}
                  alt=""
                  style={{ width: "360px", height: "", objectFit: "cover" }}
                />
              </div>
            </MainItemInnerDiv>
          </Mainitem>

          <div
            style={{
              display: isSmallScreen ? "block" : "flex",
            }}
          >
            <ThirdRowFirstGrid item xs={12} sm={6} md={6} sx={{}}>
              <ThirdFirstItem sx={{}}>xs=6 md=4</ThirdFirstItem>
            </ThirdRowFirstGrid>

            <ThirdRowSecondGrid item xs={12} sm={6} md={6} sx={{}}>
              <ThirdSecondItem sx={{}}>xs=6 md=4</ThirdSecondItem>
            </ThirdRowSecondGrid>
          </div>
        </MainItemOuterDiv>

        <Grid item xs={12} sm={4} md={4} sx={{ margin: 0 }}>
          <SecondItem style={{}}>
            <SecondItemMainDiv
              style={{
                //  width:"100%"
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{}}>
                <MobileImage
                  src={mobile}
                  alt="khan"
                  style={{ width: "177px", height: "auto", objectFit: "cover" }}
                />
              </div>

              <div style={{}}>
                <ChartImage
                  src={chart}
                  alt="khan"
                  style={{
                    width: "205px",
                    height: "auto",
                    objectFit: "cover",
                    marginTop: "",
                  }}
                />
              </div>
            </SecondItemMainDiv>
          </SecondItem>
        </Grid>
      </Grid>
    </MainBoxContainer>
  );
};

export default DashboardComp;
