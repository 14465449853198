import React from 'react'
import ViewExamTable from '../../../components/categorycomponents/addexam/ViewExamTable'


const AddExamTable = () => {
  return (
 <>
<ViewExamTable />
 </>
  )
}

export default AddExamTable