// import React, { useState, useEffect } from "react";
// // import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
// import { MenuItem, Sidebar,Menu, SubMenu } from 'react-pro-sidebar';
// // import subject from "../../../assets/subject.png";
// import dashboard from "../../../assets/dashboard.png";
// // import "./Sidebar.css"; // Add your CSS styles
// import MenuIcon from "@mui/icons-material/Menu";
// import logo from "../../../assets/logo.png";
// import user from "../../../assets/user.png";
// import category from "../../../assets/category.png";
// import pdf from "../../../assets/pdf.png";
// import mcqs from "../../../assets/mcqs.png";
// import videos from "../../../assets/videos.png";
// import mock from "../../../assets/mock.png";
// import pastpaper from "../../../assets/pastpaper.png";
// import banner from "../../../assets/banner.png";
// import {
//   MySidebar,
//   FirstLogo,
//   SideMenuitems,
//   SideMenuMainContainer,
//   SideMenuTextContainer,
//   MenuContainer,
//   SidebarMenuImage,
// } from "../../../styles/SideBarStyle/SideBarStyle";

// const CustomSideBar = () => {
//   // State to control the sidebar's open/close state
//   const [sidebarOpen, setSidebarOpen] = useState(true);

//   // Function to toggle the sidebar's open/close state
//   const toggleSidebar = () => {
//     setSidebarOpen(!sidebarOpen);
//   };

//   // Use effect to adjust the sidebar's state based on screen width
//   useEffect(() => {
//     const handleResize = () => {
//       setSidebarOpen(window.innerWidth >= 768);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return (
//     <div style={{height:"100%",background:"#004A80"}}>
//       {/* Hamburger icon for smaller screens */}
//       {window.innerWidth < 768 && (
//         <div style={{background:"#004A80",color:"white"}} className="menu-icon" onClick={toggleSidebar}>
//           <MenuIcon /> {/* Hamburger icon */}
//         </div>
//       )}

//       {/* Sidebar container */}
//       <MySidebar
//         width="100%"
//         backgroundColor="#004A80"

//       >

//         {/* Sidebar menu */}
//         <MenuContainer
//           style={{
//             width: sidebarOpen || window.innerWidth >= 768 ? "100%" : "0px", // Adjust the width
//           }}
//           menuItemStyles={{
//             button: ({ level }) => {
//               if (level === 0)
//                 return {
//                   "&:hover": {
//                     background: "gray",
//                   },
//                 };
//               if (level === 1)
//                 return {
//                   background: "#004A80",
//                   "&:hover": {
//                     background: "#7a6ace",
//                   },
//                 };
//             },
//           }}
//         >
//           {/* Spacer to create space at the top */}

// <div style={{height:"20px"}}></div>
//           {/* First menu item */}
//           {window.innerWidth > 768 &&
//           <div style={{marginBottom:"20px"}}  >
//           <div style={{ textAlign: 'center', marginTop: '0' }}>
//           <img src={logo} alt="Logo" width="60px" />
//         </div>
//         </div>
//       }

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={dashboard} />
//               </div>
//               <SideMenuTextContainer>Dashboard</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={user} />
//               </div>
//               <SideMenuTextContainer>User</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={category} />
//               </div>
//               <SideMenuTextContainer>Category</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//            <SideMenuitems>
//              <SideMenuMainContainer>
//                <div>
//                  <SidebarMenuImage src={pdf} />
//                </div>
//                <SideMenuTextContainer>PDF</SideMenuTextContainer>
//              </SideMenuMainContainer>
//            </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={mcqs} />
//               </div>
//               <SideMenuTextContainer>MCQ'S</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={videos} />
//               </div>
//               <SideMenuTextContainer>Videos</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={mock} />
//               </div>
//               <SideMenuTextContainer>Mock Test</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={pastpaper} />
//               </div>
//               <SideMenuTextContainer>Past Papers</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//           <SideMenuitems>
//             <SideMenuMainContainer>
//               <div>
//                 <SidebarMenuImage src={banner} />
//               </div>
//               <SideMenuTextContainer>Banners</SideMenuTextContainer>
//             </SideMenuMainContainer>
//           </SideMenuitems>

//         </MenuContainer>

//       </MySidebar>

//     </div>
//   );
// };

// export default CustomSideBar;

import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

// import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
// import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";

import user from "../../../assets/user.png";
import dashboard from "../../../assets/dashboard.png";
import category from "../../../assets/category.png";
import pdf from "../../../assets/pdf.png";
import mcqs from "../../../assets/mcqs.png";
import videos from "../../../assets/videos.png";
import mock from "../../../assets/mock.png";
import pastpaper from "../../../assets/pastpaper.png";
import banner from "../../../assets/banner.png";
import {
  verticalItemStyle,
  submenuStyle,
  MainConatinerDiv,
  LogoDiv,
  FirstMenuItemDiv,
  FirstMenuItemInnerDiv,
  MenuItemImage,
  MyMenu,
} from "../../../styles/SideBarStyle/SideBarStyle";
import logo from "../../../assets/logo.png";
const CustomSideBar = () => {
  return (
    <MainConatinerDiv>
      <LogoDiv>
        <img src={logo} />
      </LogoDiv>
      <Sidebar width="120px" backgroundColor="#094C7D">
       <MyMenu
  menuItemStyles={{
    button: ({ level }) => {
      const commonStyles = {
      
        "&:hover": {
          background: "#063E67",
        },
        // padding: "10px", // Add padding of 10px
        height: "80px", // Add height of 75px
      };

      if (level === 0) {
        return {
          ...commonStyles,
        };
      }

      if (level === 1) {
        return {
          ...commonStyles,
          height: "30px",
          background: "#004A80",
        };
      }
    },
  }}
  // submenuStyles={{
  //   submenu: {
  //     padding: "10px", // Apply padding of 10px to the submenu
  //   },
  // }}
>
<Link to="/" style={{textDecoration: "none" }}>
<FirstMenuItemDiv style={{ padding: "24px" }}>
  <FirstMenuItemInnerDiv>
    <MenuItemImage src={dashboard} alt="" />
  </FirstMenuItemInnerDiv>
  <span style={{ color: "white", textDecoration: "none" }}>Dashboard</span>
</FirstMenuItemDiv>
</Link>


          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle, }}>
                  <MenuItemImage src={user} alt="" />
                  <div>User</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addUser" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewUser" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>
          {/* Other menu items */}

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={category} alt="" />
                  <div>Category</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addCategory" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewCategory" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={pdf} alt="" />
                  <div>PDF</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addPdf" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/view" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 

          </SubMenu>

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={mcqs} alt="" />
                  <div>MCQ'S</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addMcqs" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewMcqs" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={videos} alt="" />
                  <div>Videos</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addVideo" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewVideo" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={mock} alt="" />
                  <div>Mock Test</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addMock" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewMock" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={pastpaper} alt="" />
                  <div>Past Papers</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addPastpaper" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewPastpaper" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>

          <SubMenu
            label={
              <>
                <div style={{ ...verticalItemStyle }}>
                  <MenuItemImage src={banner} alt="" />
                  <div>Banners</div>
                </div>
              </>
            }
            style={{ ...submenuStyle }}
          >
          <MenuItem>
          <Link to="/addBanner" style={{color:"white",textDecoration:"none"}}>Add</Link>
        </MenuItem> 
          
        <MenuItem>
        <Link to="/viewBanner" style={{color:"white",textDecoration:"none"}}>View</Link>
      </MenuItem> 
          </SubMenu>
        </MyMenu>
      </Sidebar>
    </MainConatinerDiv>
  );
};

export default CustomSideBar;
