import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useGetSinglesubCategoryByIDQuery, useUpdateSubCategoryDataMutation } from "../../features/field/FieldSlice";
import { useGetSingleSubjectByIDQuery, useUpdateSubjectDataMutation } from "../../features/subject/AddSubjectSlice";

export const useEditSubject = (id) => {
  const navigate = useNavigate();
  const [editSubjectForm, setEditSubjectForm] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
    subject: "",
    uploadfile: "",
  });

  const {
    data: singlesubject,
    isLoading,
    isSuccess,
  } = useGetSingleSubjectByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    console.log("singlesubject", singlesubject)
    if (singlesubject) {
        setEditSubjectForm({
        categoryId: singlesubject?.level?.categoryId?._id,
        subcategoryId: singlesubject?.level?.subcategoryId?._id,
        Addlevel: singlesubject?.level?.Addlevel?._id,
        subject: singlesubject?.level?.subject,
        uploadfile: singlesubject?.level?.uploadfile,
        // exambanner: singlesubject?.category?.exambanner,
        // Update with additional fields if needed


        // categoryId: singlechapter?.level?.categoryId[0]?._id,
        // subcategoryId: singlechapter?.level?.subcategoryId[0]?._id,
        // Addlevel: singlechapter?.level?.Addlevel?._id,
        // subject: singlechapter?.level?.subject?._id,
        // chapter: singlechapter?.level?.chapter,
        // uploadfile: singlechapter?.level?.uploadfile,
      });
    }
  }, [singlesubject]);
  // console.log("singlesubjectsinglesubjectsinglesubjectsinglesubject", singlesubject)

  // console.log(editSubCategoryForm,"editSubCategoryForm")

  const imageUrl = editSubjectForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditSubjectForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEditSubjectForm({ ...editSubjectForm, uploadfile: file });
  };

  const [UpdateSubCategoryData] = useUpdateSubjectDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("categoryId", editSubjectForm.categoryId);
      formData.append("subcategoryId", editSubjectForm.subcategoryId);
      // formData.append("Addlevel", editSubjectForm.Addlevel);
      formData.append("subject", editSubjectForm.subject);
      formData.append("uploadfile", editSubjectForm.uploadfile);
    //   formData.append("exambanner", editSubjectForm.exambanner);

      // Update with additional fields if needed

      const response = (await UpdateSubCategoryData({
        id,
        data: formData,
      })
      ).data;

      if (response.success === true) {
        window.alert(response.message);
        navigate("/viewSubjectTable");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editSubjectForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};




// import React from "react";
// import { useGetdashboardQuery } from "../../features/field/FieldSlice";
// // import { useGetCategoryDataQuery } from "../../features/category/CategorySlice";

// export const useGetSubCategory = () => {
//   const { data, isSuccess, isLoading } = useGetdashboardQuery(
//     {},
//     {
//       refetchOnMountOrArgChange: true,
//     }
//   );
// // console.log("Abdar khan", transformedData)

//   const transformedData = React.useMemo(() => {
//     const getCategoryData = data || [];
//     return getCategoryData?.map((catData) => ({
//       id: catData?._id,
//       categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
//       subname: catData?.subname, // Assuming catdescription is an array
//       logo: catData?.logo,
//       exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
//       status:catData?.isActive
//     }));
//   }, [data]);

//   return {
//     CategoryData: transformedData,
//     isLoading,
//     isSuccess,
//   };
// };
