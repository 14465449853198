import { Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LeftSideMainContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
})

export const LeftSideLogo = styled("div")({
marginTop:"20px"
})


export const LeftSideFirstHead= styled("div")({
    marginTop: "20px",
    fontFamily: "Poppins",
    color: "#004A80",
    fontSize: "33px",
    fontWeight: "800",
    })


    export const LeftSidePara = styled(Typography)({
      marginTop: "15px",
      fontFamily: "Hind Madurai",
      fontSize: "22px",
      color: "#004A80",
      // display:"flex",
      // justifyContent:"center",
      // alignItems:"center",
      fontWeight: "400",
      width:"500px",


      
      "@media (max-width: 912px)": {
        width:"420px"
      },


      "@media (max-width: 820px)": {
        width:"370px",
        fontSize:"18px",
      },


    "@media (max-width: 768px)": {
      width:"350px"
      
    },


    })
  
      


      
export const RightSideMainGrid = styled(Grid)({
    display:"flex",alignItems:"center",justifyContent:"center"
    })


    export const RightSideMainDiv = styled("div")({
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        })


        export const LoginTextDiv = styled("div")({
            marginTop: "15px",
            textTransform: "capitalize",
            fontFamily: "Istok Web",
            fontSize: "32px",
            fontWeight: "900",
            color: "black",
            })


            export const InfoTextDiv = styled("div")({
                marginTop: "15px",
                fontFamily: "Hind Madurai",
                fontSize: "18px",
                fontWeight: "500",
                })
    


                

            export const LoginTextFields = styled(TextField)({
                marginTop: "15px",
                width: "400px",
                borderRadius: "15px",
                background: "#E9E9E9",
                height: "60px",
                "& fieldset": { border: "none" },

                
    "@media (max-width: 768px)": {
      width:"350px"
    },

                })

    

                export const LoginButton = styled(Button)({
                    marginTop: "23px",
                    width: "400px",
                    height: "60px",
                    borderRadius: "15px",
                    background: " #004A80",
                    
    "@media (max-width: 768px)": {
      width:"350px"
    },

                    })




                    export const LastText = styled("div")({
                        fontFamily: "Hind Madurai",
                        fontSize: "18px",
                        fontWeight: "500",
                        textDecorationLine: "underline",
                        marginTop:"25px"
                        })
    

                        
                    export const IconsImages = styled("img")({
                    marginLeft:"15px"
                        })


                        export const ImagesMainDiv = styled("div")({
                        marginTop:"30px"
                                })
        










      
      
      
      
      
      