import { Button, IconButton, TablePagination } from "@mui/material";
import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import ButtonsComponent from "../../components/globalcomponents/SecondTopBarOutlet/ButtonsComponent";
import { useBANNERTableFunction } from "../../customhook/banner/useBannerTabelFun";
import { useGetBanner } from "../../customhook/banner/useGetBanner";

const ViewBannerTable = () => {
  const { BannerData, isLoading, isSuccess } = useGetBanner();
  // console.log("BannerData", BannerData);
  const {
    handleActivate,
    handleDeActivate,
    handleView,
    handleDelete,
    handleEdit,
  } = useBANNERTableFunction();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <ButtonsComponent />
      <div style={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          style={{ border: "1px solid #DEDEDE" }}
        >
          <Table>
            <TableHead style={{ background: "#F3F3F3", boxShadow: "none" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Title</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Description
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  bannerImage
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Loop through the data and display rows */}
              {BannerData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: "#EEEEEE" }} />
                  </TableCell>
                  <TableCell>
                    {row?.title ? row?.title : "No title Added"}
                  </TableCell><TableCell>
                    {row?.name ? row?.name : "No Name Added"}
                  </TableCell>

                  <TableCell>
                    {row?.description
                      ? row?.description
                      : "No Description Added"}
                  </TableCell>
                  <TableCell>
                    {row?.bannerImages && row?.bannerImages.length > 0
                      ? row.bannerImages?.map((img, index) => (
                          <img
                            key={index}
                            src={img}
                            alt={`Banner ${index + 1}`}
                            style={{
                              width: "100px",
                              height: "50px",
                              marginRight: "10px",
                            }}
                            crossOrigin="anonymous"
                          />
                        ))
                      : "No Images Added"}
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={() =>
                        row.status
                          ? handleDeActivate(row.id)
                          : handleActivate(row.id)
                      }
                    >
                      {row.status ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <i
                          className="fas fa-edit"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <i
                          className="fas fa-trash"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                      <IconButton onClick={() => handleView(row.id)}>
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: "17px", color: "#004A80" }}
                        ></i>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ViewBannerTable;
