import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const BannerSlice  = createApi({
  reducerPath: 'BannerSlice',
  tagTypes: ["BANNERDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  }),
  endpoints: (builder) => ({
    addBanner: builder.mutation({
      query: (data) => ({
        url: 'banner',
        method: 'POST',
        body: data,
      }),

      invalidatesTags: ["BANNERDATA"],
    
    }),

    getSingleBannerByID: builder.query({
      query: (id) => ({
        url: `banner/${id}`,
        method: "GET",
      }),
      providesTags: ["BANNERDATA"],
   
    }),

    getBannerData: builder.query({
      query: () => ({
        url: 'banner/admin',
        method: 'GET',
      }),
      providesTags: ["BANNERDATA"],
    }),

    UpdateBannerData: builder.mutation({
      query: (params) => ({
        url: `banner/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivateBanner: builder.mutation({
      query: (id) => ({
        url: `banner/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["BANNERDATA"],
    }),

    getDeActivateBanner: builder.mutation({
      query: (id) => ({
        url: `banner/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["CATEGORYDATA"],
    }),

    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `banner/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BANNERDATA"],
     
    }),
  }),
});

export const { useAddBannerMutation, useUpdateBannerDataMutation, useDeleteBannerMutation, useGetSingleBannerByIDQuery,  useGetBannerDataQuery, useGetActivateBannerMutation ,useGetDeActivateBannerMutation,} = BannerSlice;
