import {  Button, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';


  
export  const ButtonsMainDiv = styled('div')({
  display: "flex", flexDirection: "row",justifyContent:"space-between" ,margin:"15px",
  "@media (max-width: 767px)" :{
  display:"flex",

   alignItems:"center",
    margin:"2px"

  }
});

export  const DeleteButton = styled(Button)({
  backgroundColor: "#B8050C", padding: "8px 16px" ,
  "@media (max-width: 767px)" :{
padding:"2px 6px",
marginTop:"5px"
  }
});

export  const SpanText = styled("span")({
  marginTop: "5px", textTransform: "capitalize",
  })


export  const DeleteButtonImg = styled("img")({
  width: "14px", height: "18px", marginRight: "8px" ,
  "@media (max-width: 767px)" :{
    marginTop:"4px",
  width:"12px",
  height:"13px",
  marginRight:"",

 
  }
});

export  const SortButton = styled(Button)({
  backgroundColor: "#004A80",
   padding: "8px 16px" ,

  "@media (max-width: 767px)" :{
padding:"2px 6px",
marginLeft:"3px"
  }
});

export  const SortButtonImg = styled("img")({
  width: "20px", height: "18px", marginRight: "8px" ,
  marginTop:"4px",
  "@media (max-width: 767px)" :{
    marginTop:"3px",
  width:"14px",
  height:"12px",
  // marginRight:"2px"
  }
});


export  const AddNewButton = styled(Button)({
  background: "#004A80",
   padding: "8px 16px",
    marginLeft: "auto",
  "@media (max-width: 767px)" :{
    padding: "3px 6px",
    marginRight:"5px"
  
  }
});




export  const LastButtonsDiv = styled('div')({
  display: "flex", 
  "@media (max-width: 767px)" :{
   marginTop:"5px",
   display:"flex",
   alignItems:"center"

  }
});





export  const MySearch = styled('div')({
    marginTop:"2px",
    display: 'flex',
    padding:'3px',
    alignItems: 'center',
    position: 'relative',
    height:"45px",
    borderRadius: '8px',
    border:"1px solid #1E5076",
    marginLeft:"20px",
    backgroundColor: '#F6F6F6',
  
  
   
    color:"black",
    '&:hover': {
      backgroundColor: '#F6F6F6',
      color:'black'
    },
    marginRight: '50px',
    width: '90%',

    "@media (max-width: 767px)" :{
   width:"86%",
   marginLeft:"5px",
   height:"35px",
      }
  });
  
  export  const MySearchIcon = styled('div')({
    padding: '9px',
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'#004A80'
  });
  
  export  const MyInputBase = styled(InputBase)({
    color: 'inherit',
    padding: '0px',
    marginLeft:"10px",
    paddingRight: '0px',
    transition: 'width',
    width: '90%',
  });