import React, { useState } from "react";
import AddExams from "../../../components/categorycomponents/addexam/AddExams";
import AddFields from "../../../components/categorycomponents/addfield/AddFields";
import AddLevels from "../../../components/categorycomponents/addlevel/AddLevels";
import AddChapters from "../../../components/categorycomponents/addchapter/AddChapters";
import AddSubjects from "../../../components/categorycomponents/addsubject/AddSubjects";
const AddCategory = () => {
  const [activePage, setActivePage] = useState('AddExams');

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    <>
      {/* Show the appropriate page based on activePage state */}
      {activePage === 'AddExams' && <AddExams onPageChange={handlePageChange} />}
      {activePage === 'AddFields' && <AddFields onPageChange={handlePageChange} />}
      {activePage === 'AddLevels' && <AddLevels onPageChange={handlePageChange} />}
      {activePage === 'AddChapters' && <AddChapters onPageChange={handlePageChange} />}
      {activePage === 'AddSubjects' && <AddSubjects onPageChange={handlePageChange} />}
      {/* Other pages with onPageChange prop */}
    </>
  );
};

export default AddCategory;
