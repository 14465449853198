import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const McqsSlice = createApi({
  reducerPath: "McqsSlice",
  tagTypes: ["MCQSDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addmcqs: builder.mutation({
      query: (data) => ({
        url: "mcq",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["MCQSDATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),
     getSingleMcqsByID: builder.query({
      query: (id) => ({
        url: `mcq/${id}`,
        method: "GET",
      }),
      providesTags: ["MCQSDATA"],
   
    }),

    getMcqsData: builder.query({
      query: () => ({
        url: 'mcq/admin',
        method: 'GET',
      }),
      providesTags: ["MCQSDATA"],
    }),

    UpdateMcqsData: builder.mutation({
      query: (params) => ({
        url: `mcq/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivatMcqs: builder.mutation({
      query: (id) => ({
        url: `mcq/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["MCQSDATA"],
    }),

    getDeActivateMcqs: builder.mutation({
      query: (id) => ({
        url: `mcq/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["MCQSDATA"],
    }),

    deleteMcqs: builder.mutation({
      query: (id) => ({
        url: `mcq/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MCQSDATA"],
     
    }),
}),
});
export const { useAddmcqsMutation, useGetSingleMcqsByIDQuery, useGetMcqsDataQuery, useUpdateMcqsDataMutation, useGetActivatMcqsMutation, useGetDeActivateMcqsMutation, useDeleteMcqsMutation} = McqsSlice;