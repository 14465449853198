import React from "react";
import { useGetBannerDataQuery } from "../../features/banner/BannerSlice";

  

export const useGetBanner = () => {
  const { data, isSuccess, isLoading, isError } = useGetBannerDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
console.log("data", data)
  const transformedDataNew = React.useMemo(() => {
    const getBannerData = data || [];

    return getBannerData?.map((catData) => ({
      id: catData?._id,
      title: catData?.title, // Replace 'name' with the correct attribute name if needed
      name: catData?.name, // Replace 'name' with the correct attribute name if needed
      description: catData?.description, // Assuming catdescription is an array
      bannerImages: catData.bannerImage, 
    //   addlevel: catData?.addlevel, // Assuming catdescription is an array
    //   subject: catData?.subject, // Assuming catdescription is an array
    //   chapter: catData?.chapter, // Assuming catdescription is an array
    //   pdfname: catData?.pdfname, // Assuming catdescription is an array
    //   description: catData?.description, // Assuming catdescription is an array
    //   question: catData?.question, // Assuming catdescription is an array
    //   logo: catData?.logo,
    //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);



  return {
    BannerData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
