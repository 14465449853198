import { TextField, Typography, Avatar, InputAdornment } from "@mui/material";
import { React, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import addpdfimg from "../../assets/addpdfimg.png";

import pdfupload from "../../assets/pdfupload.png";

import {
  FirstDiv,
  MyTextField,
  InnerMainDiv,
  DescDiv,
  SubMainDiv,
  MySelect,
  TitleDiv,
  ButtonsDiv,
  ImageDiv,
  SelectFieldsHeads,
  PdfNameField,
  PdfDescField,
  UploadPDfTextField,
  ButtonsInnerDiv,
} from "../../styles/mockteststyle/AddMocktestStyle";
import { useRef } from "react";
import { useGetCategory } from "../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../customhook/field-category/useGetSubCategory";
import { TextFields } from "../../styles/categorycompstyles/addexamstyle/AddExamsStyle";
import usePastpaperForm from "../../customhook/PastpaperHook/useAddPastpaper";

const AddPaperForm = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();

  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleQuestionChange,
    handleCorrectAnswerChange,
    handleIncorrectAnswerChange,
    handleAddQuestion,
    handleRemoveQuestion,
    handleIconChange,
    isError,
    isLoading,
    isSuccess,
    error,
  } = usePastpaperForm();

  const selectedExamId = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === formData?.categoryId
    );
    return selectedCategory?.id;
  }, [formData?.categoryId, CategoryData]);

  // Extracting selected exam's name based on its ID
  const selectedExamName = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === selectedExamId
    );
    return selectedCategory?.examname;
  }, [selectedExamId, CategoryData]);
  // console.log("formDataformDataformDataformDataformData", formData);
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    // Do something with the selected file, like upload it
    console.log("Selected file:", file);
  };

  return (
    <>
      <FirstDiv style={{ backgroundColor: "#F6F6F6" }}>
        <TitleDiv>Add PAST PAPER</TitleDiv>
        <DescDiv>
          Categorizing by selecting the exam, subject, and chapter, add the
          respective MCQs.
        </DescDiv>
        <form onSubmit={handleSubmit}>
          <InnerMainDiv style={{ display: "flex" }}>
            <div>
              <div>
                <SelectFieldsHeads>Select Exam</SelectFieldsHeads>
              </div>
              <div>
                <FormControl sx={{ m: 1 }} size="" required>
                  <MySelect
                    value={formData?.categoryId}
                    onChange={handleInputChange}
                    name="categoryId"
                    required
                  >
                    {CategoryData &&
                      CategoryData.map((Examname) => (
                        <MenuItem key={Examname?.id} value={Examname?.id}>
                          {Examname?.examname}
                        </MenuItem>
                      ))}
                  </MySelect>
                </FormControl>
              </div>
              <div>
                <div>
                  <SelectFieldsHeads>Select Field</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={formData?.subname}
                      onChange={handleInputChange}
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData.map((Fieldname) => (
                          <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                            {Fieldname?.subname}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Past Paper Name
                  </div>
                  <PdfDescField
                    type="text"
                    name="papername"
                    value={formData?.papername}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {/* <div>
              <SelectFieldsHeads>Mock Test Name</SelectFieldsHeads>
            </div> */}
                {/* <div>
              <PdfDescField id="outlined-basic" label="" variant="outlined" />
            </div> */}
              </div>
            </div>

            <SubMainDiv style={{}}>
              {/* Question and answer fields */}
              {formData.questions.map((question, index) => (
                <div key={index}>
                  <Typography variant="h6">Question {index + 1}</Typography>
                  <TextField
                    label="Question"
                    value={question.question}
                    onChange={(e) =>
                      handleQuestionChange(index, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    multiline
                    rows={2}
                    required
                  />
                  <TextField
                    label="Correct Answer"
                    value={question.correct_answer}
                    onChange={(e) =>
                      handleCorrectAnswerChange(index, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    required
                  />
                  {/* Incorrect answers */}
                  {question.incorrect_answer.map((answer, i) => (
                    <TextField
                      key={i}
                      label={`Incorrect Answer ${i + 1}`}
                      value={answer}
                      onChange={(e) =>
                        handleIncorrectAnswerChange(index, i, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                  ))}
                  {/* Button to remove the question */}

                  <RemoveIcon
                    onClick={() => handleRemoveQuestion(index)}
                    style={{
                      marginLeft: "4px",
                      cursor: "pointer",
                      background: "#CB0808",
                      borderRadius: "50%",
                      padding: "",
                      color: "white",
                    }}
                  />
                </div>
              ))}
            </SubMainDiv>
          </InnerMainDiv>
          <ButtonsDiv style={{}}>
            <ButtonsInnerDiv style={{}}>
              <div style={{}}>
                <Button
                  type="submit"
                  style={{
                    margin: "15px",
                    background: "#004A80",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
                <Button
                  style={{ marginLeft: "7px", backgroundColor: "#004A80" }}
                  variant="contained"
                  onClick={handleAddQuestion}
                >
                  ADD MORE
                </Button>
              </div>
            </ButtonsInnerDiv>
          </ButtonsDiv>
        </form>
        {isLoading && (
          <p style={{ color: "blue", fontWeight: "bold" }}>Loading...</p>
        )}
        {isError && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Error: {error && error.error}
          </p>
        )}
        {isSuccess && (
          <p style={{ color: "green", fontWeight: "bold" }}>Success!</p>
        )}
      </FirstDiv>
    </>
  );
};

export default AddPaperForm;
