import { Select, TextField, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';   







  
export const NameField = styled (TextField)({
    width:"300px",
    marginLeft:"15px",
    marginTop:"8px",
    borderRadius: "10px",
    backgroundColor: "#E9E9E9",
    // border:"2 px solid red",
    marginBottom:"6px",
    display:"flex",
    // alignItems:"start",
    justifyContent:"center",
    height: "40px",
    "& fieldset": { border: "none",  outline: 'none',
     },
  
     "@media (max-width: 820px)": {
      width:"275px",
      marginLeft:"2px",
         
       },
  
     "@media (max-width: 768px)": {
      width:"280px",
      marginLeft:"2px",
         
       },
  })
  