import React from 'react'
import TopBar from "../components/globalcomponents/topbar/TopBar"
// import SideBar from '../components/globalcomponents/sidebar/SideBar'
import { Outlet } from 'react-router-dom'
import CustomSideBar from '../components/globalcomponents/sidebar/SideBar'
import AddPdf from '../pages/pdf/AddPdf'
// import SignIn from '../components/authcomponents/signin/SignIn'

const Layout = () => {
  return (
    <>
    <div style={{width:"100%", display:"flex",}}>
    <div style={{width:"130px", }}>
     <CustomSideBar />
    </div>
  
    <div style={{width:"100%",}}>
      <div style={{}}><TopBar/></div>
     <div style={{}}><Outlet/></div>
    
    </div>

    </div>
  
    </>
  )
}

export default Layout


