import React from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormControl, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEditBanner } from "../../customhook/banner/useEditbanner";

const EditBanner = () => {
  const { id } = useParams();
  const {
    editBannerForm,
    handleChange,
    handleFileChange,
    handleDeleteImage,
    handleSubmit,
    isLoading,
    isSuccess,
    isError,
    updateSucess,
  } = useEditBanner(id || "");
  console.log("iserror", isError)

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isSuccess) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh" marginY="1rem">
        <Paper elevation={3} style={{ width: "300px", padding: "10px", margin: "0 0" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Edit Banner
          </Typography>
          <form onSubmit={handleSubmit}>
            <div>
            <Box>
                <Typography>Title</Typography>
              </Box>
              <FormControl sx={{ mb: 1 }} fullWidth>
                <TextField
                  style={{ width: "283px" }}
                  value={editBannerForm?.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </FormControl>
              <Box>
                <Typography>Name</Typography>
              </Box>
              <FormControl sx={{ mb: 1 }} fullWidth>
                <TextField
                  style={{ width: "283px" }}
                  value={editBannerForm?.name}
                  onChange={handleChange}
                  name="name"
                  required
                />
              </FormControl>
              <Box>
                <Typography>Description</Typography>
              </Box>
              <FormControl sx={{ mb: 1 }} fullWidth>
                <TextField
                  style={{ width: "283px" }}
                  value={editBannerForm?.description}
                  onChange={handleChange}
                  name="description"
                  required
                />
              </FormControl>
              <Box>
                <Typography>Banner Images</Typography>
              </Box>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {editBannerForm?.bannerImage?.map((image, index) => {
                  const imageUrl = image instanceof File ? URL.createObjectURL(image) : image;
                  return (
                    <div key={index} style={{ display: 'inline-block', position: 'relative' }}>
                      <img
                        src={imageUrl}
                        alt={`Banner ${index + 1}`}
                        style={{ width: "100px", height: "50px", marginRight: "10px" }}
                        crossOrigin="anonymous"
                      />
                      <IconButton
                        onClick={() => handleDeleteImage(index)}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                })}
                <input
                  type="file"
                  name="bannerImage"
                  onChange={handleFileChange}
                  multiple
                />
              </div>
            </div>
            
              <Typography mt={1} sx={{color:'red', display: isError ? 'block' : 'none'}}>please change atleast two pictures </Typography>
            
            <Box textAlign="center" mt={2} mb={0}>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }

  return null;
};

export default EditBanner;
