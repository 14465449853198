import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const VideoSlice = createApi({
  reducerPath: "VideoSlice",
  tagTypes: ["VIDEODATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addvedio: builder.mutation({
      query: (data) => ({
        url: "video",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["VIDEODATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),
     getSingleVideoByID: builder.query({
      query: (id) => ({
        url: `video/${id}`,
        method: "GET",
      }),
      providesTags: ["VIDEODATA"],
   
    }),

    getVideoData: builder.query({
      query: () => ({
        url: 'video/admin',
        method: 'GET',
      }),
      providesTags: ["VIDEODATA"],
    }),

    UpdateVideoData: builder.mutation({
      query: (params) => ({
        url: `video/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivatVideo: builder.mutation({
      query: (id) => ({
        url: `video/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["VIDEODATA"],
    }),

    getDeActivateVideo: builder.mutation({
      query: (id) => ({
        url: `video/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["VIDEODATA"],
    }),

    deleteVideo: builder.mutation({
      query: (id) => ({
        url: `video/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VIDEODATA"],
     
    }),
}),
});
export const { useAddvedioMutation, useGetSingleVideoByIDQuery, useGetVideoDataQuery, useUpdateVideoDataMutation, useGetActivatVideoMutation ,useGetDeActivateVideoMutation, useDeleteVideoMutation } = VideoSlice;