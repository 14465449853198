import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { Navigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;
  console.log(baseUrl)
  const login = async (userData) => {
    try {
      const response = await axios.post(`${baseUrl}user/signin`, userData);
     
      const { user, token } = response.data;
      setUser(user);
      
      localStorage.setItem("userData", JSON.stringify(user));
      localStorage.setItem("authToken", token);
    } catch (error) {
      console.error("Authentication error:", error);
      throw new Error("Authentication failed"); // Throw an error on authentication failure
    }
  };

  return (
    <AuthContext.Provider value={{ user, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
