import React from "react";
// import { useGetSubCategoryDataQuery } from "../../features/field/FieldSlice";
import { useGetSubjectDataQuery } from "../../features/subject/AddSubjectSlice";

  

export const useGetSubject = () => {
  const { data, isSuccess, isLoading, isError } = useGetSubjectDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );


  const transformedDataNew = React.useMemo(() => {
    const getSubjectData = data || [];

    return getSubjectData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
      Addlevel: catData?.Addlevel, // Assuming catdescription is an array
      subject: catData?.subject, // Assuming catdescription is an array
      uploadfile: catData?.uploadfile,
    //   logo: catData?.logo,
    //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);



  return {
    SubjectData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
