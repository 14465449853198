import { Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FirstDiv = styled("div")({
  margin: "20px",
  padding: "20px",
  
  "@media (max-width: 1024px)": {
    margin: "10px",
    padding: "10px",
  },

  
  "@media (max-width: 820px)": {
    margin: "10px",
    padding: "10px",
  },

  
  "@media (max-width: 767px)": {
    margin: "0px",
    padding: "0px",
  },

 
});

export const TitleDiv = styled("div")({
  fontFamily: "Poppins",
  fontWeight: "800",
  fontSize: "26px",
  color: "#06375A",
  marginLeft: "30px",
  "@media (max-width: 767px)": {
    marginLeft: "0px",
  },
});

export const SelectFieldsHeads = styled(Typography)({
  fontFamily: "Segoe UI",
  fontWeight: "600",
  fontSize: "20px",
  marginTop: "2px",
  marginLeft: "15px",
});

export const InnerMainDiv = styled("div")({
  marginLeft: "30px",
  "@media (max-width: 767px)": {
    flexDirection: "column",
    marginLeft: "0px",
  },
});

export const DescDiv = styled("div")({
  width: "400px",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "16px",
  color: "#1F1E24",

  lineHeight: "27.5px",
  marginLeft: "30px",
  "@media (max-width: 767px)": {
    width: "280px",
    fontSize: "15px",
    marginLeft: "0px",
  },
});

export const SubMainDiv = styled("div")({
  marginLeft: "50px",


  "@media (max-width: 820px)": {
    marginLeft: "0px",
  },

   

  "@media (max-width: 768px)": {
    marginLeft: "0px",
  },

 
});

export const MySelect = styled(Select)({
  width: "340px",

  borderRadius: "10px",
  background: "#E9E9E9",
  height: "45px",
  "& fieldset": { border: "none", outline: "none" },

  
  "@media (max-width: 820px)": {
    width: "270px",
  },

  "@media (max-width: 767px)": {
    width: "260px",
  },

});

export const PdfNameField = styled(TextField)({
  width: "340px",
  marginLeft: "8px",
  marginTop: "8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  marginBottom: "6px",
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  height: "90px",
  "& fieldset": { border: "none", outline: "none" },
 

  "@media (max-width: 820px)": {
    width: "270px",
  },

  "@media (max-width: 768px)": {
    width: "270px",
  },
})

export const PdfDescField = styled(TextField)({
  width: "340px",
  marginLeft: "8px",
  marginTop: "8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  marginBottom: "7px",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  height: "45px",

  "& fieldset": { border: "none", outline: "none" },


  "@media (max-width: 820px)": {
    width: "270px",
  },

  
  "@media (max-width: 768px)": {
    width: "275px",
  },
  "@media (max-width: 767px)": {
    width: "230px",
  },
});

export const ButtonsDiv = styled("div")({
  paddingBottom: "60px",
  marginTop: "50px",
  display: "",
  justifyContent: "",
  alignItems: "",
  "@media (max-width: 767px)": {
    marginTop: "20px",
    flexDirection: "column",
    paddingBottom: "70px",
  },
});

export const ButtonsInnerDiv = styled("div")({
  position: "absolute",
  left: "32%",

  "@media (max-width: 820px)": {
    position: "absolute",
    left: "50%",
  },

  "@media (max-width: 912px)": {
    position: "absolute",
    left: "52%",
  },

  "@media (max-width: 1024px)": {
    position: "absolute",
    left: "47%",
  },

  "@media (max-width: 767px)": {
    position: "absolute",
    left: "42%",
  },
});
