import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const addLevelSlice = createApi({
  reducerPath: "addLevelSlice",
  tagTypes: ["CATEGORYDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addLevel: builder.mutation({
      query: (data) => ({
        url: "level",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["LEVELDATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),

    getSingleLevelByID: builder.query({
      query: (id) => ({
        url: `level/${id}`,
        method: "GET",
      }),
      providesTags: ["LEVELDATA"],
    }),

    getDashboardData: builder.query({
      query: () => ({
        url: "level/admin",
        method: "GET",
      }),
      providesTags: ["LEVELDATA"],
    }),

    UpdateLevelData: builder.mutation({
      query: (params) => ({
        url: `level/${params.id}`,
        method: "PUT",
        body: params.data,
      }),
    }),

    getActivateLevel: builder.mutation({
      query: (id) => ({
        url: `level/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["LEVELDATA"],
    }),

    getDeActivateLevel: builder.mutation({
      query: (id) => ({
        url: `level/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["LEVELDATA"],
    }),

    deleteLevel: builder.mutation({
      query: (id) => ({
        url: `level/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LEVELDATA"],
    }),
  }),
});

export const {
  useAddLevelMutation,
  useGetSingleLevelByIDQuery,
  useGetDashboardDataQuery,
  useUpdateLevelDataMutation,
  useGetActivateLevelMutation,
  useGetDeActivateLevelMutation,
  useDeleteLevelMutation
} = addLevelSlice;
