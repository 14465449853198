import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditCategory } from "../../../customhook/category/useEditCategory";
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import { useEditSubLevel } from "../../../customhook/level/useEditSubLevelCategory";
// import { useEditSubCategory } from "../../../customhook/field-category/useEditSubCategory";
import { useEditSubject } from "../../../customhook/subject/useEditSubject";

import {
  MySelect,
  SelectFieldsHeads,
} from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
import { FormControl, MenuItem, Select } from "@mui/material";
// import { useGetCategory } from "../../../customhook/category/useGetCategory";
import { useGetSubject } from "../../../customhook/subject/useGetSubject";
// import useFieldCategoryForm from "../../../customhook/field-category/useAddSubCategory";
// import useLevelForm from "../../../customhook/level/useAddLevel";
import useSubjectForm from "../../../customhook/subject/useAddSubject";

import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";
import { useGetCategory } from "../../../customhook/category/useGetCategory";
import { useGetDashboard } from "../../../customhook/level/useGetLevel";

const EditSubject = () => {
  const { SubjectData } = useGetSubject();
  const { SubCategoryData } = useGetSubCategory();
  const { CategoryData } = useGetCategory();
  const { SubLevelData } = useGetDashboard();

  // console.log(SubjectData, "SubjectData");

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editSubjectForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,
  } = useEditSubject(id || "");
  console.log(editSubjectForm, "editSubjectForm");
  const {
    formData,
    // handleSubmit,
    handleInputChange,
    // handleImageChange,
    // handleIconChange,
    // isError,
    // isLoading,
    // isSuccess,
    // error,
  } = useSubjectForm();
  const selectedExamId = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === editSubjectForm?.categoryId
    );
    return selectedCategory?.id;
  }, [editSubjectForm?.categoryId, CategoryData]);

  // Extracting selected exam's name based on its ID
  const selectedExamName = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === selectedExamId
    );
    return selectedCategory?.examname;
  }, [selectedExamId, CategoryData]);

    // console.log("selectedExamName",selectedExamName)
  // console.log("abdarkhankhel",issuccess)
  // console.log("formData", formData);

  if (isloading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (issuccess) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        marginY="1rem"
      >
        <Paper
          elevation={3}
          style={{ width: "300px", padding: "10px", margin: "0 0" }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Edit SubLevel
          </Typography>
          <form onSubmit={handleSubmit}>
            <div>
              <Box>
                <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "285px" }}
                    value={editSubjectForm?.categoryId}
                    onChange={handleChange}
                    name="categoryId"
                  >
                    {CategoryData &&
                      CategoryData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.examname}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Box>
                  <SelectFieldsHeads>Choose Field</SelectFieldsHeads>
                </Box>
                <div>
                  <FormControl sx={{ mb: 1 }} size="">
                    <Select
                      style={{ width: "285px" }}
                      value={editSubjectForm?.subcategoryId}
                      onChange={handleChange}
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData?.map(
                          (category) => (
                            // category?.subcategoryId?.map((exam) => (
                            <MenuItem key={category?.id} value={category?.id}>
                              {category?.subname}
                            </MenuItem>
                          )
                          // ))
                        )}

                      {/* {SubLevelData&&
                      SubLevelData.map((Examname) => (
                        <MenuItem key={Examname?.id} value={Examname?.id}>
                          {Examname?.examname}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <Box>
                <SelectFieldsHeads>Choose Level</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 2 }} size="">
                  <Select
                    // style={{ width: "285px" }}
                      value={selectedExamName === "MDCAT" ? "" : editSubjectForm?.Addlevel || ""}

                    // value={editSubjectForm?.Addlevel}
                    onChange={handleChange}
                    name="Addlevel"
                    disabled={selectedExamName === "MDCAT"}
                    style={{  width: "285px" ,
                      pointerEvents:
                      editSubjectForm.categoryId === "MDCAT" ? "none" : "auto",
                    }}
                  >
                    {SubLevelData &&
                      SubLevelData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.Addlevel}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="subject"
                  id="subject"
                  name="subject"
                  //   value={editSubjectForm?.Addlevel}

                  value={editSubjectForm?.subject}
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  type="file"
                  id="uploadfile"
                  name="uploadfile"
                  onChange={handleFileChange}
                />
                {editSubjectForm?.uploadfile && (
                  <img
                    src={editSubjectForm?.uploadfile}
                    alt="Subject File Preview"
                    className="preview-image"
                    style={{ marginTop: "4px", width: "100%", height: "120px" }}
                    crossOrigin="anonymous"
                  />
                )}
              </Box>
            </div>

            <Box textAlign="center" mt={2} mb={0}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="custom-btn"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }
};

export default EditSubject;

// import React from "react";
// import { useNavigate, useParams } from "react-router-dom";
// // import { useEditCategory } from "../../../customhook/category/useEditCategory";
// // import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

// import CircularProgress from "@mui/material/CircularProgress";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// // import { useEditSubCategory } from "../../../customhook/field-category/useEditSubCategory";
// import { useEditSubject } from "../../../customhook/subject/useEditSubject";

// import {
//   MySelect,
//   SelectFieldsHeads,
// } from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
// import { FormControl, MenuItem } from "@mui/material";
// import { useGetCategory } from "../../../customhook/category/useGetCategory";
// import useFieldCategoryForm from "../../../customhook/field-category/useAddSubCategory";

// const EditSubject = () => {
//   const { CategoryData } = useGetCategory();

//   const navigate = useNavigate();
//   const { id } = useParams();
//   const {
//     editSubjectForm,
//     handleChange,
//     handleFileChange,
//     handleSubmit,
//     isloading,
//     issuccess,
//   } = useEditSubject(id || "");
//   const {
//     formData,
//     // handleSubmit,
//     handleInputChange,
//     // handleImageChange,
//     // handleIconChange,
//     // isError,
//     // isLoading,
//     // isSuccess,
//     // error,
//   } = useFieldCategoryForm();
//   console.log("formData",formData)
//   // console.log("abdarkhankhel",issuccess)

//   if (isloading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="50vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (issuccess) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="80vh"
//         marginY="1rem"
//       >
//         <Paper
//           elevation={3}
//           style={{ width: "300px", padding: "10px", margin: "0 0" }}
//         >
//           <Typography variant="h5" align="center" gutterBottom>
//             Edit Subject
//           </Typography>
//           <form onSubmit={handleSubmit}>
//             <div>
//               <Box>
//                 <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
//               </Box>
//               <div>
//                 <FormControl sx={{ mb: 1 }} size="">
//                   <MySelect
//                     value={formData?.categoryId}
//                     onChange={handleInputChange}
//                     name="categoryId"
//                   >
//                     {CategoryData &&
//                       CategoryData.map((Examname) => (
//                         <MenuItem key={Examname?.id} value={Examname?.id}>
//                           {Examname?.examname}
//                         </MenuItem>
//                       ))}
//                   </MySelect>
//                 </FormControl>
//               </div>

//               {/* <Box mb={1}>
//               <TextField
//                 fullWidth
//                 label="categoryId"
//                 id="categoryId"
//                 name="categoryId"
//                 value={editSubCategoryForm?.categoryId?.examname}
//                 onChange={handleChange}
//                 required
//               />
//             </Box> */}
//               <Box mb={1}>
//                 <TextField
//                   fullWidth
//                   label="subname"
//                   id="subname"
//                   name="subname"
//                   value={editSubjectForm?.subname}
//                   onChange={handleChange}
//                   required
//                 />
//               </Box>
//             </div>
//             <Box mb={1}>
//               <TextField
//                 fullWidth
//                 type="file"
//                 id="logo"
//                 name="logo"
//                 onChange={handleFileChange}
//               />
//               {editSubjectForm?.logo && (
//                 <img
//                   src={editSubjectForm?.logo}
//                   alt="Logo Preview"
//                   className="preview-image"
//                   style={{ marginTop: "4px", width: "100%", height: "120px" }}
//                 />
//               )}
//             </Box>
//             <Box mb={1}>
//               <TextField
//                 fullWidth
//                 type="file"
//                 id="exambanner"
//                 name="exambanner"
//                 onChange={handleFileChange}
//               />
//               {editSubjectForm?.exambanner && (
//                 <img
//                   src={editSubjectForm?.exambanner}
//                   alt="Exam Banner Preview"
//                   className="preview-image"
//                   style={{ marginTop: "4px", width: "100%", height: "120px" }}
//                 />
//               )}
//             </Box>
//             <Box textAlign="center" mt={2} mb={0}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 className="custom-btn"
//               >
//                 Update
//               </Button>
//             </Box>
//           </form>
//         </Paper>
//       </Box>
//     );
//   }
// };

// export default EditSubject;
