import { TextField, Typography,Avatar,InputAdornment } from "@mui/material";
import { React, useMemo } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";
import addpdfimg from "../../assets/addpdfimg.png";

import pdfupload from "../../assets/pdfupload.png"

import {
  FirstDiv,
  MyTextField,
  InnerMainDiv,
  DescDiv,
  SubMainDiv,
  MySelect,
  TitleDiv,
  ButtonsDiv,
  ImageDiv,
  SelectFieldsHeads,
  PdfNameField,
  PdfDescField,
  UploadPDfTextField
} from "../../styles/videostyle/AddVideoFormStyle";
import { useRef } from "react";
import { useGetCategory } from "../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../customhook/field-category/useGetSubCategory";
import { useGetDashboard } from "../../customhook/level/useGetLevel";
import { useGetSubject } from "../../customhook/subject/useGetSubject";
import { useGetChapter } from "../../customhook/chapter/useGetChapter";
import useVideoForm from "../../customhook/vedioHook/useAddVIDEO";
import { TextFields } from "../../styles/categorycompstyles/addexamstyle/AddExamsStyle";

const AddVideo = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();
  const { SubLevelData } = useGetDashboard();
  const { SubjectData } = useGetSubject();
  const { ChapterData } = useGetChapter();


  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleVideoChange,
    handleRemoveClick,
    handleIconChange,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useVideoForm();




  const selectedExamId = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === formData?.categoryId
    );
    return selectedCategory?.id;
  }, [formData?.categoryId, CategoryData]);

  // Extracting selected exam's name based on its ID
  const selectedExamName = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === selectedExamId
    );
    return selectedCategory?.examname;
  }, [selectedExamId, CategoryData]);

  // console.log("Form Data", formData);

  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    // Do something with the selected file, like upload it
    console.log("Selected file:", file);
  };



  return (
    <>
      <FirstDiv style={{ backgroundColor: "#F6F6F6" }}>
        <TitleDiv>Add Video</TitleDiv>
        <DescDiv>
        Categorizing by selecting the exam, subject, and chapter,  add the respective Videos.
        </DescDiv>
        <form onSubmit={handleSubmit}>
          <InnerMainDiv style={{ display: "flex" }}>
            <div>
              <div>
                <SelectFieldsHeads>Select Exam</SelectFieldsHeads>
              </div>
              <div>
                <FormControl sx={{ m: 1 }} size="" required>
                  <MySelect
                    value={formData?.categoryId}
                    onChange={handleInputChange}
                    name="categoryId"
                    required
                  >
                    {CategoryData &&
                      CategoryData.map((Examname) => (
                        <MenuItem key={Examname?.id} value={Examname?.id}>
                          {Examname?.examname}
                        </MenuItem>
                      ))}
                  </MySelect>
                </FormControl>
              </div>

              <div>
                <div>
                  <SelectFieldsHeads>Select Field</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={formData?.subname}
                      onChange={handleInputChange}
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData.map((Fieldname) => (
                          <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                            {Fieldname?.subname}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>

                <div>
                  <SelectFieldsHeads>Select Level</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={selectedExamName === "MDCAT" ? "" : formData?.Addlevel || ""}
                      // value={formData?.Addlevel}
                      onChange={handleInputChange}
                      name="Addlevel"
                      disabled={selectedExamName === "MDCAT"}
                    >
                      {SubLevelData &&
                        SubLevelData.map((Fieldname) => (
                          <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                            {Fieldname?.Addlevel}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>

                <div>
                  <SelectFieldsHeads>Select Subject</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={formData?.subject}
                      onChange={handleInputChange}
                      name="subject"
                      required
                    >
                      {SubjectData &&
                        SubjectData.map((subjectItem) => (
                          <MenuItem
                            key={subjectItem?.id}
                            value={subjectItem?.id}
                          >
                            {subjectItem?.subject}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>
              </div>

              <div>
                <SelectFieldsHeads>Select Chapter</SelectFieldsHeads>
              </div>
              <div>
                <FormControl sx={{ m: 1 }} size="">
                  <MySelect
                    value={formData?.chapter}
                    onChange={handleInputChange}
                    name="chapter"
                    required
                  >
                    {ChapterData &&
                      ChapterData.map((chapterItem) => (
                        <MenuItem key={chapterItem?.id} value={chapterItem?.id}>
                          {chapterItem?.chapter}
                        </MenuItem>
                      ))}
                  </MySelect>
                </FormControl>
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  fontFamily: "Segoe UI",
                }}
              >
                Add video Name
              </div>
              <TextFields
                type="text"
                name="videoname"
                required
                value={formData?.videoname}
                onChange={handleInputChange}
                placeholder="Enter PDF Name"
                style={{ display: "flex", justifyContent: "center" }}
              />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  fontFamily: "Segoe UI",
                }}
              >
                Description
              </div>
              <TextFields
                type="text"
                name="description"
                required
                value={formData?.description}
                onChange={handleInputChange}
                placeholder="Enter PDF description"
                style={{ display: "flex", justifyContent: "center" }}
              />

              {/* <div>
                <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="imageInput">
                  <Button
                    variant="text"
                    style={{
                      color: "white",
                      padding: "7px",
                      borderRadius: "10px",
                      background: "#004A80",
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      document.getElementById("imageInput").click()
                    }
                  >
                    {formData.uploadfile
                      ? formData.uploadfile.name
                      : "+ Add Image"}{" "}
                  </Button>
                </label>
              </div> */}

              <div
                style={{
                  marginTop: "13px",
                  display: "",
                  justifyContent: "",
                  alignItems: "",
                  flexDirection: "",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Upload video 
               </div>
                <label htmlFor="imageInput">
                  <UploadPDfTextField
                    type="file"
                    name="uploadfile"
                    onChange={handleVideoChange}
                    required
                  />
                </label>
              </div>
            </div>
            {/* <SubMainDiv style={{}}>
              <div>
                <SelectFieldsHeads>PDF Name</SelectFieldsHeads>
              </div>
              <div>
                <PdfNameField
                  value={formData?.pdfname} // Pass the value from form data
                  onChange={handleInputChange} // Pass the change handler
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                />
              </div>

              <div>
                <SelectFieldsHeads>Description</SelectFieldsHeads>
              </div>
              <div>
                <PdfDescField id="outlined-basic" label="" variant="outlined" />
              </div>
              <div>
                <SelectFieldsHeads>Upload PDF</SelectFieldsHeads>
              </div>

              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelected}
                />
                <UploadPDfTextField
                  onClick={handleFileUploadClick}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={pdfupload}
                          alt="PDF Upload"
                          style={{
                            marginRight: "8px",
                            height: "24px",
                            width: "auto",
                          }}
                        />
                        <span>upload your PDF here, or browse</span>{" "}
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </SubMainDiv> */}
          </InnerMainDiv>
          <ButtonsDiv style={{}}>
            <div>
              <Button
                type="submit"
                style={{
                  margin: "15px",
                  background: "#004A80",
                  borderRadius: "10px",
                }}
                variant="contained"
              >
                SUBMIT
              </Button>
              <Button
                style={{ marginLeft: "5px", backgroundColor: "#004A80" }}
                variant="contained"
              >
                ADD MORE
              </Button>
            </div>

            <ImageDiv>
              <img src={addpdfimg} style={{ height: "200px" }} />
            </ImageDiv>
          </ButtonsDiv>
        </form>
        {isLoading && (
          <p style={{ color: "blue", fontWeight: "bold" }}>Loading...</p>
        )}
        {isError && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Error: {error && error.error}
          </p>
        )}
        {isSuccess && (
          <p style={{ color: "green", fontWeight: "bold" }}>Success!</p>
        )}
      </FirstDiv>
    </>
  );
};

export default AddVideo;
