import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useGetSingleLevelByIDQuery } from "../../../features/level/AddLevelSlice";

const ViewSingleLevelCat = () => {
  const params = useParams();
  const location=useLocation()
 
  console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleLevelByIDQuery(params.id);
  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAa", data);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.level) {
    return <div>Error loading data...</div>;
  }

  const { level } = data;
  // console.log(level, "level");

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Grid container spacing={3} style={{ padding: "20px" }}>
      {/* Full Width - Logo, Exambanner, Examname, Full_name, and Active Status */}
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: "20px", textAlign: "left" }}>
          <Grid container spacing={3}>
            {/* Right Side - Exam Name, Full Name, and Active Status */}
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>
          {level?.categoryId && (
            <Typography key={level.categoryId._id}>
              {level?.categoryId.examname}
            </Typography>
          )}

          <Typography variant="h6" color="primary">
            Field Name:
          </Typography>
          {level?.subcategoryId && (
            <Typography key={level.subcategoryId._id}>
              {level?.subcategoryId.subname}
            </Typography>
          )}

              <Typography
                variant="h6"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Field Name:
              </Typography>
              <Typography>{level.Addlevel}</Typography>

              <Typography
                variant="h6"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Status:
              </Typography>
              <Typography
                style={{
                  color: level.isActive ? activeColor : inactiveColor,
                }}
              >
                {level.isActive ? "Active" : "Inactive"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ViewSingleLevelCat;
