import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditCategory } from "../../../customhook/category/useEditCategory";
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import { useEditSubject } from "../../../customhook/subject/useEditSubject";
import { useEditChapter } from "../../../customhook/chapter/useEditChapter";

import {
  MySelect,
  SelectFieldsHeads,
} from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
import { FormControl, MenuItem, Select } from "@mui/material";
// import { useGetCategory } from "../../../customhook/category/useGetCategory";
import useChapterForm from "../../../customhook/chapter/useAddChapter";
// import useSubjectForm from "../../../customhook/subject/useAddSubject";
import { useGetCategory } from "../../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";
import { useGetDashboard } from "../../../customhook/level/useGetLevel";
import { useGetSubject } from "../../../customhook/subject/useGetSubject";
import { useGetChapter } from "../../../customhook/chapter/useGetChapter";

const EditChapter = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();
  const { SubLevelData } = useGetDashboard();
  const { SubjectData } = useGetSubject();
  const { ChapterData } = useGetChapter();

  // console.log(CategoryData, "CategoryDataCategoryDataCategoryData");

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editChapterForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,
  } = useEditChapter(id || "");
  // console.log(editChapterForm, "editChapterForm");
  const {
    formData,
    // handleSubmit,
    handleInputChange,
    // handleImageChange,
    // handleIconChange,
    // isError,
    // isLoading,
    // isSuccess,
    // error,
  } = useChapterForm();
  //   console.log("formData",formData)
  // console.log("abdarkhankhel",issuccess)
  console.log("editChapterForm?.uploadfile", editChapterForm?.uploadfile);

  if (isloading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (issuccess) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        marginY="1rem"
      >
        <Paper
          elevation={3}
          style={{ width: "300px", padding: "10px", margin: "0 0" }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Edit Chapter
          </Typography>
          <form onSubmit={handleSubmit}>
            <div>
              <Box>
                <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
              </Box>
              <div>
                {/* sssssss */}
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editChapterForm?.categoryId}
                    defaultValue={editChapterForm?.categoryId}
                    onChange={handleChange}
                    name="categoryId"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {CategoryData &&
                      CategoryData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.examname}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Box>
                  <SelectFieldsHeads>Choose Field</SelectFieldsHeads>
                </Box>
                <div>
                  <FormControl sx={{ mb: 1 }} size="">
                    <Select
                      style={{ width: "283px" }}
                      value={editChapterForm?.subcategoryId}
                      defaultValue={editChapterForm?.categoryId}
                      onChange={handleChange}
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData?.map(
                          (category) => (
                            // category?.subcategoryId?.map((exam) => (
                            <MenuItem key={category?.id} value={category?.id}>
                              {category?.subname}
                            </MenuItem>
                          )
                          // ))
                        )}
                      {/* {SubLevelData&&
                      SubLevelData.map((Examname) => (
                        <MenuItem key={Examname?.id} value={Examname?.id}>
                          {Examname?.examname}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <Box>
                <SelectFieldsHeads>Choose Level</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editChapterForm?.Addlevel}
                    defaultValue={editChapterForm?.Addlevel}
                    onChange={handleChange}
                    name="Addlevel"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {SubLevelData &&
                      SubLevelData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.Addlevel}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <Box>
                <SelectFieldsHeads>Choose Subject</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "283px" }}
                    value={editChapterForm?.subject}
                    defaultValue={editChapterForm?.subject}
                    onChange={handleChange}
                    name="subject"
                  >
                    {SubjectData &&
                      SubjectData?.map((SubjectData) => (
                        // category?.categoryId?.map((exam) => (
                        <MenuItem key={SubjectData?.id} value={SubjectData?.id}>
                          {SubjectData?.subject}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <Box mb={1}>
                <TextField
                  fullWidth
                  label="chapter"
                  id="chapter"
                  name="chapter"
                  value={editChapterForm?.chapter}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box mb={1}>
                <TextField
                  fullWidth
                  type="file"
                  id="uploadfile"
                  name="uploadfile"
                  onChange={handleFileChange}
                />
                {editChapterForm?.uploadfile && (
                  <img
                    src={editChapterForm?.uploadfile}
                    alt="Logo Preview"
                    className="preview-image"
                    style={{ marginTop: "4px", width: "100%", height: "120px" }}
                    crossOrigin="anonymous"
                  />
                )}
              </Box>
            </div>

            <Box textAlign="center" mt={2} mb={0}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="custom-btn"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }
};

export default EditChapter;

// import React from "react";
// import { useNavigate, useParams } from "react-router-dom";
// // import { useEditCategory } from "../../../customhook/category/useEditCategory";
// // import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

// import CircularProgress from "@mui/material/CircularProgress";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// // import { useEditSubCategory } from "../../../customhook/field-category/useEditSubCategory";
// import { useEditSubject } from "../../../customhook/subject/useEditSubject";

// import {
//   MySelect,
//   SelectFieldsHeads,
// } from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
// import { FormControl, MenuItem } from "@mui/material";
// import { useGetCategory } from "../../../customhook/category/useGetCategory";
// import useFieldCategoryForm from "../../../customhook/field-category/useAddSubCategory";

// const EditSubject = () => {
//   const { CategoryData } = useGetCategory();

//   const navigate = useNavigate();
//   const { id } = useParams();
//   const {
//     editChapterForm,
//     handleChange,
//     handleFileChange,
//     handleSubmit,
//     isloading,
//     issuccess,
//   } = useEditSubject(id || "");
//   const {
//     formData,
//     // handleSubmit,
//     handleInputChange,
//     // handleImageChange,
//     // handleIconChange,
//     // isError,
//     // isLoading,
//     // isSuccess,
//     // error,
//   } = useFieldCategoryForm();
//   console.log("formData",formData)
//   // console.log("abdarkhankhel",issuccess)

//   if (isloading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="50vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (issuccess) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="80vh"
//         marginY="1rem"
//       >
//         <Paper
//           elevation={3}
//           style={{ width: "300px", padding: "10px", margin: "0 0" }}
//         >
//           <Typography variant="h5" align="center" gutterBottom>
//             Edit Subject
//           </Typography>
//           <form onSubmit={handleSubmit}>
//             <div>
//               <Box>
//                 <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
//               </Box>
//               <div>
//                 <FormControl sx={{ mb: 1 }} size="">
//                   <MySelect
//                     value={formData?.categoryId}
//                     onChange={handleInputChange}
//                     name="categoryId"
//                   >
//                     {CategoryData &&
//                       CategoryData.map((Examname) => (
//                         <MenuItem key={Examname?.id} value={Examname?.id}>
//                           {Examname?.examname}
//                         </MenuItem>
//                       ))}
//                   </MySelect>
//                 </FormControl>
//               </div>

//               {/* <Box mb={1}>
//               <TextField
//                 fullWidth
//                 label="categoryId"
//                 id="categoryId"
//                 name="categoryId"
//                 value={editSubCategoryForm?.categoryId?.examname}
//                 onChange={handleChange}
//                 required
//               />
//             </Box> */}
//               <Box mb={1}>
//                 <TextField
//                   fullWidth
//                   label="subname"
//                   id="subname"
//                   name="subname"
//                   value={editChapterForm?.subname}
//                   onChange={handleChange}
//                   required
//                 />
//               </Box>
//             </div>
//             <Box mb={1}>
//               <TextField
//                 fullWidth
//                 type="file"
//                 id="logo"
//                 name="logo"
//                 onChange={handleFileChange}
//               />
//               {editChapterForm?.logo && (
//                 <img
//                   src={editChapterForm?.logo}
//                   alt="Logo Preview"
//                   className="preview-image"
//                   style={{ marginTop: "4px", width: "100%", height: "120px" }}
//                 />
//               )}
//             </Box>
//             <Box mb={1}>
//               <TextField
//                 fullWidth
//                 type="file"
//                 id="exambanner"
//                 name="exambanner"
//                 onChange={handleFileChange}
//               />
//               {editChapterForm?.exambanner && (
//                 <img
//                   src={editChapterForm?.exambanner}
//                   alt="Exam Banner Preview"
//                   className="preview-image"
//                   style={{ marginTop: "4px", width: "100%", height: "120px" }}
//                 />
//               )}
//             </Box>
//             <Box textAlign="center" mt={2} mb={0}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 className="custom-btn"
//               >
//                 Update
//               </Button>
//             </Box>
//           </form>
//         </Paper>
//       </Box>
//     );
//   }
// };

// export default EditSubject;
