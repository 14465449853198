import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSinglePastpaperByIDQuery, useUpdatePastpaperDataMutation } from "../../features/Pastpaper/PastPaperSilce";

export const useEditPastpaper = (id) => {
  const navigate = useNavigate();
  const [editPastpaperForm, setEditPastpaperForm] = useState({
    categoryId: "",
    subcategoryId: "",
    papername: "",
    questions: [
      {
        questionId: "",
        question: "",
        correct_answer: "",
        incorrect_answer: "",
      },
    ],
  });

  const {
    data: singlePastpaper,
    isLoading,
    isSuccess,
  } = useGetSinglePastpaperByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (singlePastpaper) {
      setEditPastpaperForm({
        categoryId: singlePastpaper?.data?.categoryId?._id || "",
        subcategoryId: singlePastpaper?.data?.subcategoryId?._id || "",
        papername: singlePastpaper?.data?.papername || "",
        questions: singlePastpaper.data.questions.map(question => ({
          questionId: question.questionId,
          question: question.question,
          correct_answer: question.correct_answer,
          incorrect_answer: question.incorrect_answer,
        })),
      });
    }
  }, [singlePastpaper]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setEditPastpaperForm((prevData) => ({
      ...prevData,
      questions: prevData.questions.map((question, i) =>
        i === index ? { ...question, [name]: value } : question
      ),
    }));
  };

  const handleExam = (e) => {
    const { value, name } = e.target;
    setEditPastpaperForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [updateMcqsData] = useUpdatePastpaperDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const FormValues = {
        categoryId: editPastpaperForm.categoryId,
        subcategoryId: editPastpaperForm.subcategoryId,
        papername: editPastpaperForm.papername,
        questions: editPastpaperForm.questions.map((question) => ({
          questionId: question.questionId,
          question: question.question,
          correct_answer: question.correct_answer,
          incorrect_answer: question.incorrect_answer,
        })),
      };

      const response = await updateMcqsData({
        id,
        data: FormValues,
      });

      if (response.data.success === true) {
        window.alert(response.data.message);
        navigate("/ViewPastPaper");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editPastpaperForm,
    handleChange,
    handleExam,
    handleSubmit,
    isLoading,
    isSuccess,
  };
};
