import React from 'react'
import AddUserForm from '../../components/usercomponents/AddUserForm'

const AddUser = () => {
  return (
    <>
    <AddUserForm/>
    </>
  )
}

export default AddUser