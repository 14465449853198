import React from "react";
import deleteicon from "../../../assets/deleteicon.png"
import sort from "../../../assets/sort.png"
import {
  MySearch,
  MySearchIcon,
  MyInputBase,
  ButtonsMainDiv,
  LastButtonsDiv,
  DeleteButton,
  DeleteButtonImg,
  SortButton,
  SortButtonImg,
  AddNewButton,
  SpanText,
} from "./ButtonsCompStyle";
const ButtonsComponent = () => {
  return (
    <>
      <ButtonsMainDiv >
        <div>
          <DeleteButton style={{}} variant="contained">
            <DeleteButtonImg src={deleteicon} alt="Image" />
            <SpanText>
              delete
            </SpanText>
          </DeleteButton>
        </div>

        <LastButtonsDiv >
          <div>
            <SortButton variant="contained" >
              <SortButtonImg src={sort} alt="Image"  />
              <SpanText>
                Sort
              </SpanText>
            </SortButton>
          </div>

          <div>
            <div>
              <MySearch>
                <MyInputBase placeholder="Search here" />
                <MySearchIcon></MySearchIcon>
              </MySearch>
            </div>
          </div>

          <div>
            <AddNewButton variant="contained">
              <SpanText >
                +AddNew
              </SpanText>
            </AddNewButton>
          </div>
        </LastButtonsDiv>
      </ButtonsMainDiv>
    </>
  );
};

export default ButtonsComponent;
