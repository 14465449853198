import React from 'react'
import ViewSingleSubCat from '../../../components/categorycomponents/addfield/ViewSingleSubCat'

const ViewSingleSubcategory = () => {
  return (
 <>
<ViewSingleSubCat/>
 </>
  )
}

export default ViewSingleSubcategory