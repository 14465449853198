import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useGetSingleChapterByIDQuery, useUpdateChapterDataMutation } from "../../features/chapters/ChapterSlice";
// import { useGetSinglePdfByIDQuery, useUpdatePdfDataMutation} from "../../features/pdf/PdfSlice";
import { useGetSingleMcqsByIDQuery, useUpdateMcqsDataMutation } from "../../features/mcqs/mcqsSlice";

export const useEditMcqs = (id) => {
  const navigate = useNavigate();
  const [editMcqsForm, setEditMcqsForm] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
    subject: "",
    chapter: "",
    question: "",
    correct_answer: "",
    incorrect_answers: ["","",""],
  });
  
  const {
    data: singleMcqs,
    isLoading,
    isSuccess,
  } = useGetSingleMcqsByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  // console.log("singleMcqs--------", singleMcqs?.data?.incorrect_answer)
  // const categoryId = singlechapter?.category?.categoryId;
  useEffect(() => {
    if (singleMcqs) {
      setEditMcqsForm({
        categoryId: singleMcqs?.data?.categoryId?._id,
        subcategoryId: singleMcqs?.data?.subcategoryId?._id,
        Addlevel: singleMcqs?.data?.addlevel?._id,
        subject: singleMcqs?.data?.subject?._id,
        chapter: singleMcqs?.data?.chapter?._id,
        question: singleMcqs?.data?.question,
        correct_answer: singleMcqs?.data?.correct_answer,
        incorrect_answers: singleMcqs?.data?.incorrect_answer || ["", "", ""] // Initialize with empty strings if not provided
        // uploadfile: singlepdf?.level?.uploadfile,
        // subject: "65cc5de36ca1435880f630da",
        // uploadfile: "http://preparlyapi-env.eba-u4ipsxzx.us-east-1.elasticbeanstalk.com/uploads/chapter-Pg2UnV3cG-chapter.jpeg",

        // exambanner: singlechapter?.category?.exambanner,
        // Update with additional fields if needed
      });
    }
  }, [singleMcqs]);
    // console.log(singlepdf, "singlepdfsinglepdfsinglepdfsinglepdfsinglepdf");

  const imageUrl = editMcqsForm.exambanner;

  const handleChange = (e, index) => {
    console.log("handleChange",index)
    const { value } = e.target;
    setEditMcqsForm((prevData) => ({
      ...prevData,
      incorrect_answers: prevData.incorrect_answers.map((answer, i) => i === index ? value : answer)
    }));
  };

  const handleExam = (e) => {

    const { value,name } = e.target;
    setEditMcqsForm((prevData) => ({
      ...prevData,
     [name]:value
    }));
  };



//   const handleFileChange = (e) => {
//     const file = e.target.files?.[0] || null;
//     setEditMcqsForm({ ...editMcqsForm, uploadfile: file });
//   };

  const [UpdateMcqsData] = useUpdateMcqsDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const formData = new FormData();
      console.log("formate date",editMcqsForm)

      formData.append("categoryId", editMcqsForm.categoryId);
      formData.append("subcategoryId", editMcqsForm.subcategoryId);
      // formData.append("addlevel", editMcqsForm.Addlevel);
      formData.append("subject", editMcqsForm.subject);
      formData.append("chapter", editMcqsForm.chapter);
      formData.append("question", editMcqsForm.question);
      formData.append("correct_answer", editMcqsForm.correct_answer);

      // Append each item in the incorrect_answers array separately
      editMcqsForm.incorrect_answers.forEach((answer, index) => {
        formData.append(`incorrect_answer[${index}]`, answer);
      });

      // Update with additional fields if needed

      const response = (
        await UpdateMcqsData({
          id,
          data: editMcqsForm,
        })
      ).data;
      // console.log("responseresponseresponse", response);
      if (response.success === true) {
        window.alert(response.message);
        navigate("/viewMcqs");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return {
    editMcqsForm,
    handleChange,
    // handleFileChange,
    handleExam,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};
