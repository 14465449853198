
import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { NameField, SelectFieldsHeads } from "../../styles/modalsStyles/ResetPassStyle";

const ResetPassModal = ({ open, handleClose }) => {
    
  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="forget-password-modal"
    aria-describedby="modal-to-reset-password"
  >
    <div
      style={{
        backgroundColor: "#FFFFFF",
        position: "absolute",
        top: "50%",
        left: "50%",
        width:"500px",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: "30px",
        borderRadius: "16px",
      }}
    >
      {/* Add your content for the modal here */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          style={{
            fontWeight: "500",
            marginLeft: "8px",
            color: "#004A80",
            fontSize: "20px",
          }}
        >
          Reset Your Password
        </Typography>
        <Close onClick={handleClose} style={{ cursor: "pointer" }} />
      </Box>
      <div>
        <Typography style={{ marginLeft: "8px", fontSize: "13px" }}>
        Please a new Passwords that you haven't used  before
        </Typography>
      </div>
     
      <div>
        <div>
          <NameField
           
            variant="outlined"
            placeholder="Enter New Password"
          />
        </div>

        <div>
        <NameField
       
          variant="outlined"
          placeholder="Confirm New Password"
        />
      </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "13px",
          marginRight:"30px"
        }}
      >
        <Button
          style={{
            textTransform: "capitalize",
            background: "#004A80",
            color: "white",
          }}
        
        >
          Reset
        </Button>
      </div>
    </div>
  </Modal>
  );
};

export default ResetPassModal;
