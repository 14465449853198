import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useGetSingleLevelByIDQuery } from "../../../features/level/AddLevelSlice";
import { useGetSingleSubjectByIDQuery } from "../../../features/subject/AddSubjectSlice";
import { useGetSingleChapterByIDQuery } from "../../../features/chapters/ChapterSlice";

const ViewSingleChapterCat = () => {
  const params = useParams();
  const location = useLocation();

  //   console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleChapterByIDQuery(
    params.id
  );
  //   console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAa", data);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.level) {
    return <div>Error loading data...</div>;
  }

  const { level } = data;
  // console.log(data, "level");

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            uploadfile:
          </Typography>
          <img
            src={level?.uploadfile}
            alt="Logo"
            style={{
              width: "180px",
              height: "160px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            crossOrigin="anonymous"
          />
        </Grid>
        {/* Right Side - Exam Name, Full Name, and Active Status */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>
          {level?.categoryId && (
            <Typography key={level.categoryId._id}>
              {level?.categoryId.examname}
            </Typography>
          )}

          <Typography variant="h6" color="primary">
            Field Name:
          </Typography>
          {level?.subcategoryId && (
            <Typography key={level.subcategoryId._id}>
              {level?.subcategoryId.subname}
            </Typography>
          )}

          <Typography variant="h6" color="primary">
            Level Name:
          </Typography>
          {level?.Addlevel && (
            <Typography key={level?.Addlevel?._id}>
              {level?.Addlevel?.Addlevel}
            </Typography>
          )}
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Subject Name:
          </Typography>
          {level?.subject && (
            <Typography key={level.subject._id}>
              {level?.subject.subject}
            </Typography>
          )}

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Chapter Name:
          </Typography>
          {/* {level?.subject && ( */}
            <Typography key={level?._id}>
              {level?.chapter}
            </Typography>
          {/* )} */}

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Status:
          </Typography>
          <Typography
            style={{
              color: level.isActive ? activeColor : inactiveColor,
            }}
          >
            {level.isActive ? "Active" : "Inactive"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewSingleChapterCat;
