import React from 'react'
// import Layout from '../../layout/Layout'
import PdfForm from '../../components/pdfcomponent/pdfform/PdfForm'

const AddPdf = () => {
  return (
   <>
   <PdfForm />
  
  
   
   </>
  )
}

export default AddPdf