import { TextField, Typography, Avatar, InputAdornment } from "@mui/material";
import { React, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import addpdfimg from "../../assets/addpdfimg.png";

import pdfupload from "../../assets/pdfupload.png";
import useMcqsForm from "../../customhook/MCQSHook/useAddMCQS";

import {
  FirstDiv,
  MyTextField,
  InnerMainDiv,
  DescDiv,
  SubMainDiv,
  MySelect,
  TitleDiv,
  ButtonsDiv,
  ImageDiv,
  SelectFieldsHeads,
  PdfNameField,
  PdfDescField,
  UploadPDfTextField,
  ButtonsInnerDiv,
} from "../../styles/mcqsstyle/AddMcqsStyle";
import { useRef } from "react";
import { useGetCategory } from "../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../customhook/field-category/useGetSubCategory";
import { useGetDashboard } from "../../customhook/level/useGetLevel";
import { useGetSubject } from "../../customhook/subject/useGetSubject";
import { useGetChapter } from "../../customhook/chapter/useGetChapter";
import { TextFields } from "@mui/icons-material";

const AddMcqsForm = () => {
  const { CategoryData } = useGetCategory();
  const { SubCategoryData } = useGetSubCategory();
  const { SubLevelData } = useGetDashboard();
  const { SubjectData } = useGetSubject();
  const { ChapterData } = useGetChapter();

  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleAddClick,
    handleRemoveClick,
    handleIncorrectAnswerChange,
    handleIconChange,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useMcqsForm();
  const selectedExamId = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === formData?.categoryId
    );
    return selectedCategory?.id;
  }, [formData?.categoryId, CategoryData]);

  // Extracting selected exam's name based on its ID
  const selectedExamName = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === selectedExamId
    );
    return selectedCategory?.examname;
  }, [selectedExamId, CategoryData]);
  // console.log("formDataformDataformDataformDataformData", formData);
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    // Do something with the selected file, like upload it
    console.log("Selected file:", file);
  };

  return (
    <>
      <FirstDiv style={{ backgroundColor: "#F6F6F6" }}>
        <TitleDiv>Add MCQS</TitleDiv>
        <DescDiv>
          Categorizing by selecting the exam, subject, and chapter, add the
          respective MCQs.
        </DescDiv>
        <form onSubmit={handleSubmit}>
          <InnerMainDiv style={{ display: "flex" }}>
            <div>
              <div>
                <SelectFieldsHeads>Select Exam</SelectFieldsHeads>
              </div>
              <div>
                <FormControl sx={{ m: 1 }} size="" required>
                  <MySelect
                    value={formData?.categoryId}
                    onChange={handleInputChange}
                    name="categoryId"
                    required
                  >
                    {CategoryData &&
                      CategoryData.map((Examname) => (
                        <MenuItem key={Examname?.id} value={Examname?.id}>
                          {Examname?.examname}
                        </MenuItem>
                      ))}
                  </MySelect>
                </FormControl>
              </div>

              <div>
                <div>
                  <SelectFieldsHeads>Select Field</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={formData?.subname}
                      onChange={handleInputChange}
                      name="subcategoryId"
                      required
                    >
                      {SubCategoryData &&
                        SubCategoryData.map((Fieldname) => (
                          <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                            {Fieldname?.subname}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>

                <div>
                  <SelectFieldsHeads>Select Level</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={selectedExamName === "MDCAT" ? "" : formData?.addlevel || ""}
                      // value={formData?.addlevel}
                      onChange={handleInputChange}
                      name="addlevel"
                      disabled={selectedExamName === "MDCAT"}

                    >
                      {SubLevelData &&
                        SubLevelData.map((Fieldname) => (
                          <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                            {Fieldname?.Addlevel}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>

                <div>
                  <SelectFieldsHeads>Select Subject</SelectFieldsHeads>
                </div>
                <div>
                  <FormControl sx={{ m: 1 }} size="">
                    <MySelect
                      value={formData?.subject}
                      onChange={handleInputChange}
                      name="subject"
                      required
                    >
                      {SubjectData &&
                        SubjectData.map((subjectItem) => (
                          <MenuItem
                            key={subjectItem?.id}
                            value={subjectItem?.id}
                          >
                            {subjectItem?.subject}
                          </MenuItem>
                        ))}
                    </MySelect>
                  </FormControl>
                </div>
              </div>

              <div>
                <SelectFieldsHeads>Select Chapter</SelectFieldsHeads>
              </div>
              <div>
                <FormControl sx={{ m: 1 }} size="">
                  <MySelect
                    value={formData?.chapter}
                    onChange={handleInputChange}
                    name="chapter"
                    required
                  >
                    {ChapterData &&
                      ChapterData.map((chapterItem) => (
                        <MenuItem key={chapterItem?.id} value={chapterItem?.id}>
                          {chapterItem?.chapter}
                        </MenuItem>
                      ))}
                  </MySelect>
                </FormControl>
              </div>
            </div>

        
            <SubMainDiv style={{}}>
              <div>
                <SelectFieldsHeads>Question</SelectFieldsHeads>
              </div>
              <div>
                <PdfNameField
                  id="outlined-basic"
                  name="question"
                  required
                  value={formData?.question}
                  onChange={handleInputChange}
                  label=""
                  variant="outlined"
                />
              </div>

              <div>
                <SelectFieldsHeads>Correct Answer</SelectFieldsHeads>
              </div>
              <div>
                <PdfDescField
                  id="outlined-basic"
                  name="correct_answer"
                  required
                  value={formData?.correct_answer}
                  onChange={handleInputChange}
                  label=""
                  variant="outlined"
                />
              </div>
             
              <div>
                <SelectFieldsHeads>Incorrect Answer</SelectFieldsHeads>
              </div>
              {formData.incorrect_answer.map((answer, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <PdfDescField
                    id={`incorrect_answer_${index}`}
                    name="incorrect_answer"
                    value={answer}
                    onChange={(e) =>
                      handleIncorrectAnswerChange(index, e.target.value)
                    }
                    label=""
                    variant="outlined"
                  />
                  <RemoveIcon
                    onClick={() => handleRemoveClick(index)}
                    style={{
                      marginLeft: "4px",
                      cursor: "pointer",
                      background: "#CB0808",
                      borderRadius: "50%",
                      padding: "",
                      color: "white",
                    }}
                  />
                </div>
              ))}
              <AddIcon
                onClick={handleAddClick}
                style={{
                  marginLeft: "5px",
                  cursor: "pointer",
                  background: "#0F77A4",
                  borderRadius: "50%",
                  padding: "",
                  color: "white",
                }}
              />
            </SubMainDiv>
          </InnerMainDiv>
          <ButtonsDiv style={{}}>
            <ButtonsInnerDiv style={{}}>
              <div style={{}}>
                <Button
                  type="submit"
                  style={{
                    margin: "15px",
                    background: "#004A80",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                >
                  SUBMIT
                </Button>
                <Button
                  style={{ marginLeft: "7px", backgroundColor: "#004A80" }}
                  variant="contained"
                >
                  ADD MORE
                </Button>
              </div>
            </ButtonsInnerDiv>
          </ButtonsDiv>
        </form>
        {isLoading && (
          <p style={{ color: "blue", fontWeight: "bold" }}>Loading...</p>
        )}
        {isError && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Error: {error && error.error}
          </p>
        )}
        {isSuccess && (
          <p style={{ color: "green", fontWeight: "bold" }}>Success!</p>
        )}
      </FirstDiv>
    </>
  );
};

export default AddMcqsForm;
