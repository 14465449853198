import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async () => {
    // Client-side validation for email and password
    if (!user.email || !user.password) {
      setError("Email and password are required");
      return;
    }

    try {
      // Call the login function from the useAuth context
      await login(user);

      // If login is successful, you can navigate to the desired page
      navigate("/");
    } catch (error) {
      // console.error("Error during login:", error);
      setError("Email and password do not match.");
    }
  };

  return { user, handleSubmit, handleChange, error };
};
