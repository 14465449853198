import { useState } from "react";
import { useAddsubCategoryMutation } from "../../features/field/FieldSlice";

const useFieldCategoryForm = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    subname: "",
    // logo: null,
    // exambanner: null,
  });

  const [addsubCategory, { isLoading, isError, isSuccess, error }] =
  useAddsubCategoryMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  //  const handleImageChange = (event) => {
  //   const imageFile = event.target.files[0];
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     logo: imageFile,
  //   }));
  // };

  const handleIconChange = (event) => {
    const iconFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      exambanner: iconFile,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
  
    try {
      const response = await addsubCategory(formData).unwrap();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  

  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    // handleImageChange,
    handleIconChange,
    handleSubmit,
  };
};

export default useFieldCategoryForm;