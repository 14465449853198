// store.js
import { configureStore } from "@reduxjs/toolkit";
import { categoryApiSlice } from "../features/category/CategorySlice"; // Your API slice file
import { fieldApiSlice } from "../features/field/FieldSlice";
import { addLevelSlice } from "../features/level/AddLevelSlice";
import { addSubjectSlice } from "../features/subject/AddSubjectSlice";
import { ChapterSlice } from "../features/chapters/ChapterSlice";
import { PdfSlice } from "../features/pdf/PdfSlice";
import { McqsSlice } from "../features/mcqs/mcqsSlice";
import { VideoSlice } from "../features/video/VideoSlice";
import { MocktestSlice } from "../features/Mock Test/MocktestSlice";
import { PastpaperSlice } from "../features/Pastpaper/PastPaperSilce";
import { BannerSlice } from "../features/banner/BannerSlice";
import { UserSlice } from "../features/user/UserSlice";


export const store = configureStore({
  reducer: {
    [categoryApiSlice.reducerPath]: categoryApiSlice.reducer,
    [fieldApiSlice.reducerPath]: fieldApiSlice.reducer,
    [addLevelSlice.reducerPath]: addLevelSlice.reducer,
    [addSubjectSlice.reducerPath]: addSubjectSlice.reducer,
    [ChapterSlice.reducerPath]: ChapterSlice.reducer,
    [PdfSlice.reducerPath]: PdfSlice.reducer,
    [McqsSlice.reducerPath]: McqsSlice.reducer,
    [VideoSlice.reducerPath]: VideoSlice.reducer,
    [MocktestSlice.reducerPath]: MocktestSlice.reducer,
    [PastpaperSlice.reducerPath]: PastpaperSlice.reducer,
    [BannerSlice.reducerPath]: BannerSlice.reducer,
    [UserSlice.reducerPath]: UserSlice.reducer,

  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware()
  .concat(categoryApiSlice.middleware)
  .concat(fieldApiSlice.middleware)
  .concat(addLevelSlice.middleware)
  .concat(addSubjectSlice.middleware)
  .concat(ChapterSlice.middleware)
  .concat(PdfSlice.middleware)
  .concat(McqsSlice.middleware)
  .concat(VideoSlice.middleware)
  .concat(MocktestSlice.middleware)
  .concat(PastpaperSlice.middleware)
  .concat(BannerSlice.middleware)
  .concat(UserSlice.middleware)

});

export default store;