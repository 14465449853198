import React from 'react'
import ViewMcqsTable from '../../components/mcqscomponents/ViewMcqsTable'

const ViewMcqs = () => {
  return (
   <>
   <ViewMcqsTable/>
   </>
  )
}

export default ViewMcqs