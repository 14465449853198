import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  MenuItem,
} from "@mui/material";
import view from "../../../assets/view.png";
import edit from "../../../assets/edit.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  LoginTextFields,
  ParaDiv,
  UploadPDfTextField,
  HeadDiv,
  containerStyle,
  rowStyle,
  ButtonsMainDiv,
  ButtonsInnerDiv,
  TextFields,
} from "../../../styles/categorycompstyles/addsubjectstyle/AddSubjectStyle";
import pdfupload from "../../../assets/pdfupload.png";
import examfield from "../../../assets/examfield.png";
import { useRef, useState } from "react";
import {
  MySelect,
  SelectFieldsHeads,
} from "../../../styles/categorycompstyles/addsubjectstyle/AddSubjectStyle";
import ButtonComponent from "../ButtonComp/ButtonComp";
import { useGetCategory } from "../../../customhook/category/useGetCategory";
import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";
import useSubjectForm from "../../../customhook/subject/useAddSubject";
import { useGetDashboard } from "../../../customhook/level/useGetLevel";
const AddSubjects = ({ onPageChange }) => {
  const [activePage, setActivePage] = useState("AddSubjects");
  const { SubCategoryData } = useGetSubCategory();
  const { SubLevelData } = useGetDashboard();
  // console.log("SubLevelData", SubLevelData);
  const { CategoryData } = useGetCategory();
  const {
    formData,
    handleSubmit,
    handleInputChange,
    handleImageChange,
    handleRemoveClick,
    handleIconChange,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useSubjectForm();
  const selectedExamId = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === formData?.categoryId
    );
    return selectedCategory?.id;
  }, [formData?.categoryId, CategoryData]);

  // Extracting selected exam's name based on its ID
  const selectedExamName = useMemo(() => {
    const selectedCategory = CategoryData?.find(
      (category) => category.id === selectedExamId
    );
    return selectedCategory?.examname;
  }, [selectedExamId, CategoryData]);
  return (
    <>
      <Box sx={{ margin: "20px" }}>
        <Grid container>
          <Grid
            style={{ background: "#F6F6F6", margin: "0px" }}
            item
            xs={12}
            sm={12}
            md={6}
          >
            <div style={{ margin: "10px" }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{
                    background: "#094C7D",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    textTransform: "capitalize",

                    color: "#FFFFFF",
                    paddingLeft: "18px",
                  }}
                  href="#contained-buttons"
                >
                  <img
                    src={edit}
                    alt="Your Image"
                    style={{ marginRight: "8px", width: "15px" }}
                  />
                  Edit
                </Button>

                <Button
                  style={{
                    background: "#E8E8E8",
                    textTransform: "capitalize",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    color: "#094C7D",
                  }}
                  variant=""
                  href="/ViewSubjectTable"
                >
                  <img
                    src={view}
                    alt="Your Image"
                    style={{ marginRight: "6px", width: "18px" }}
                  />
                  View
                </Button>
              </div>
            </div>
            <HeadDiv style={{}}>
              <div>
                <Typography
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "24px",
                    fontWeight: "bolder",
                    color: "#06375A",
                  }}
                >
                  Add Subject
                </Typography>
              </div>
              <ParaDiv style={{}}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    letterSpacing: "1px",
                  }}
                >
                  Under each Exam, we'll provide the exam name and accompany it
                  with a visual representation.
                </Typography>
              </ParaDiv>
            </HeadDiv>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div>
                    <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
                  </div>
                  <div>
                    <FormControl sx={{ m: 1 }} size="">
                      <MySelect
                        value={formData?.categoryId}
                        onChange={handleInputChange}
                        name="categoryId"
                        required
                      >
                        {CategoryData &&
                          CategoryData.map((Examname) => (
                            <MenuItem key={Examname?.id} value={Examname?.id}>
                              {Examname?.examname}
                            </MenuItem>
                          ))}
                      </MySelect>
                    </FormControl>
                  </div>
                  {/* <div>
          <FormControl sx={{ m: 1 }} size="">
            <MySelect>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </MySelect>
          </FormControl>
        </div> */}

                  <div>
                    <SelectFieldsHeads>Choose Field</SelectFieldsHeads>
                  </div>
                  <div>
                    <FormControl sx={{ m: 1 }} size="">
                      <MySelect
                        value={formData?.subname}
                        onChange={handleInputChange}
                        name="subcategoryId"
                      >
                        {SubCategoryData &&
                          SubCategoryData.map((Fieldname) => (
                            <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                              {Fieldname?.subname}
                            </MenuItem>
                          ))}
                      </MySelect>
                    </FormControl>
                  </div>
                  {/* <div>
                    <FormControl sx={{ m: 1 }} size="">
                      <MySelect>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </MySelect>
                    </FormControl>
                  </div> */}

                  <div>
                    <SelectFieldsHeads>Choose Level</SelectFieldsHeads>
                  </div>
                  <div>
                    <FormControl sx={{ m: 1 }} size="">
                      <MySelect
                        value={selectedExamName === "MDCAT" ? "" : formData?.Addlevel || ""}
                        // value={formData?.Addlevel}
                        onChange={handleInputChange}
                        name="Addlevel"
                        // disabled={formData.categoryId === "6601475e80cef2d079d913cb"} // Conditionally disable based on categoryId value
                        disabled={selectedExamName === "MDCAT"}
                        style={{
                          pointerEvents:
                            formData.categoryId === "MDCAT" ? "none" : "auto",
                        }}
                      >
                        {SubLevelData &&
                          SubLevelData.map((Fieldname) => (
                            <MenuItem key={Fieldname?.id} value={Fieldname?.id}>
                              {Fieldname?.Addlevel}
                            </MenuItem>
                          ))}
                      </MySelect>
                    </FormControl>
                  </div>
                  {/* <div>
                    <FormControl sx={{ m: 1 }} size="">
                      <MySelect>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </MySelect>
                    </FormControl>
                  </div> */}

                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Segoe UI",
                        marginLeft: "10px",
                      }}
                    >
                      Add Subject
                    </div>
                    <div
                      style={{
                        marginTop: "7px",
                        display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        // marginLeft: "8px",
                        flexGrow: 1,
                        // border:"2px solid black"
                      }}
                    >
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput" // Assign an id to the input element
                          onChange={handleImageChange}
                          required
                          // style={{border:"2px solid red" }}
                        />
                      <TextFields
                        type="text"
                        name="subject"
                        value={formData?.subject}
                        onChange={handleInputChange}
                        placeholder="Enter Subject Name"
                        style={{ display: "flex", justifyContent: "center", marginTop:"10px"  }}
                        required
                      />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <RemoveIcon
                          style={{
                            color: "white",
                            marginLeft: "3px",
                            cursor: "pointer",
                            marginLeft: "5px",
                            backgroundColor: "#CB0808",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                          onClick={handleRemoveClick} // Add onClick handler to remove icon
                        />
                        <AddIcon
                          style={{
                            color: "white",
                            marginLeft: "3px",
                            cursor: "pointer",
                            backgroundColor: "#0F77A4",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    style={{
                      margin: "15px",
                      background: "#004A80",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </form>
                 {/* Add inline styles for the messages */}
          {isLoading && (
            <p style={{ color: "blue", fontWeight: "bold" }}>Loading...</p>
          )}
          {isError && (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Error: {error && error.error}
            </p>
          )}
          {isSuccess && (
            <p style={{ color: "green", fontWeight: "bold" }}>Success!</p>
          )}
          </Grid>
     
          <Grid item xs={12} sm={12} md={6}>
            <ButtonsMainDiv>
              <ButtonsInnerDiv>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddExams"
                      active={activePage === "AddExams"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddFields"
                      active={activePage === "AddFields"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddLevels"
                      active={activePage === "AddLevels"} // Pass active prop
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddSubjects"
                      active={activePage === "AddSubjects"} // Pass active prop
                    />
                    <ButtonComponent
                      onClick={onPageChange}
                      pageName="AddChapters"
                      active={activePage === "AddChapters"} // Pass active prop
                    />
                  </div>
                </div>
              </ButtonsInnerDiv>
            </ButtonsMainDiv>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddSubjects;
