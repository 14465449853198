import React from "react";
import { useGetDashboardDataQuery } from "../../features/level/AddLevelSlice";

export const useGetDashboard = () => {
  const { data, isSuccess, isLoading } = useGetDashboardDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const transformedData = React.useMemo(() => {
    const getCategoryData = data || [];
    return getCategoryData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
      Addlevel: catData?.Addlevel, // Assuming catdescription is an array

    //   logo: catData?.logo,
    //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status:catData?.isActive
    }));
  }, [data]);
  // console.log("Data", data)

  return {
    SubLevelData: transformedData,
    isLoading,
    isSuccess,
  };
};
