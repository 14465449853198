import React from "react";
import { useGetUserDataQuery } from "../../features/user/UserSlice";

  

export const useGetUser = () => {
  const { data, isSuccess, isLoading, isError } = useGetUserDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
// console.log("User", data)
  const transformedDataNew = React.useMemo(() => {
    const getUserData = data || [];

    return getUserData?.map((catData) => ({
      id: catData?._id,
      username: catData.username, 
      firstName: catData?.firstName,
      lastName: catData?.lastName,
      email: catData?.email,
      role: catData.role, 
      phonenumber: catData.phonenumber, 
      status:catData?.isActive
    }));
  }, [data]);



  return {
    UserData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
