import React from 'react'
import ViewUserTable from '../../components/usercomponents/ViewUserTable'

const ViewUser = () => {
  return (
   <>
   <ViewUserTable/>
   </>
  )
}

export default ViewUser