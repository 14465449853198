import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const UserSlice  = createApi({
  reducerPath: 'UserSlice',
  tagTypes: ["USERDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  }),
  endpoints: (builder) => ({
    // addBanner: builder.mutation({
    //   query: (data) => ({
    //     url: 'banner',
    //     method: 'POST',
    //     body: data,
    //   }),

    //   invalidatesTags: ["USERDATA"],
    
    // }),

    getSingleUserByID: builder.query({
      query: (id) => ({
        url: `user/${id}`,
        method: "GET",
      }),
      providesTags: ["USERDATA"],
   
    }),

    getUserData: builder.query({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
      providesTags: ["USERDATA"],
    }),

    UpdateUserData: builder.mutation({
      query: (params) => ({
        url: `user/passwordupdate`,
        // url: `banner/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    // getActivateBanner: builder.mutation({
    //   query: (id) => ({
    //     url: `banner/${id}/activate`,
    //     method: "PUT", // 'Put' should be 'PUT' in uppercase
    //   }),
    //   invalidatesTags: ["USERDATA"],
    // }),

    // getDeActivateBanner: builder.mutation({
    //   query: (id) => ({
    //     url: `banner/${id}/deactivate`,
    //     method: "PUT", // 'Put' should be 'PUT' in uppercase
    //   }),
    //   invalidatesTags: ["CATEGORYDATA"],
    // }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["USERDATA"],
     
    }),
  }),
});

export const { useGetUserDataQuery, useGetSingleUserByIDQuery, useUpdateUserDataMutation, useDeleteUserMutation } = UserSlice;
