import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useGetSinglePdfByIDQuery } from "../../../features/pdf/PdfSlice";
import { useGetSingleMcqsByIDQuery } from "../../features/mcqs/mcqsSlice";
import { useGetSingleBannerByIDQuery } from "../../features/banner/BannerSlice";

const ViewSingleBannerCat = () => {
  const params = useParams();
  const location = useLocation();

  //   console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleBannerByIDQuery(params.id);
  // console.log(
  //   "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAa",
  //   data?.data
  // );
 console.log("data", data);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data) {
    return <div>Error loading data...</div>;
  }

  const { banner } = data;
  // console.log("McqsData", McqsData);

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Banner Images:
          </Typography>
          {banner.bannerImage.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Banner ${index + 1}`}
              style={{ width: "40%", height:"40%", marginRight:"10px", marginBottom: "10px" }}
              crossorigin='anonymous'
              />
          ))}
        </Grid>
        {/* Right Side - Exam Name, Full Name, and Active Status */}
        <Grid item xs={12} sm={6}>
        <Typography variant="h6" color="primary">
            Title:
          </Typography>
          <Typography>{banner.title}</Typography>
          <Typography variant="h6" color="primary">
            Name:
          </Typography>
          <Typography>{banner.name}</Typography>
          <Typography variant="h6" color="primary">
            Description:
          </Typography>
          <Typography>{banner.description}</Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Status:
          </Typography>
          <Typography
            style={{
              color: banner.isActive ? activeColor : inactiveColor,
            }}
          >
            {banner.isActive ? "Active" : "Inactive"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
      );
};

export default ViewSingleBannerCat;
