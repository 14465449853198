import { Button, Grid, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';





export const ParaDiv = styled("div")({
   
    width:"450px",
    
"@media (max-width: 768px)": {
width:"300px"
},

    })

    export const HeadDiv = styled("div")({
   
    marginLeft:"70px",
        
    "@media (max-width: 768px)": {
   marginLeft:"0px"
    },
    
        })

export const TextFields = styled(TextField)({
    // marginTop: "15px",
    width: "300px",
    borderRadius: "15px",
    background: "#E9E9E9",
    
//   marginLeft:"10px",
    height: "43px",
    "& fieldset": { border: "none" },

    
"@media (max-width: 768px)": {
width:"200px",
margin:"5px"
},

    })


    
export const UploadPDfTextField = styled (TextField)({
    width:"400px",
  marginTop:"10px",
//   marginLeft:"8px",
    borderRadius: "10px",
    background: "#E9E9E9",
    height: "45px",
  
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    "& fieldset": { border: "none",  outline: 'none',
     },
     "@media (max-width: 768px)": {
      width:"300px",
      margin:"5px"
      
         
       },
  })

  export const ButtonsMainDiv = styled("div")({
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "@media (max-width:768px)":{
        margin:"5px",
      
    }
  }) 
  
 export  const ButtonsInnerDiv = styled ("div")({
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
    "@media (max-width:768px)":{
        margin:"5px",
    }
  });


  export const SelectFieldsHeads = styled(Typography)({
    fontFamily: "Segoe UI",
    fontWeight: "600",
    fontSize: "20px",
    marginTop: "10px",
    marginLeft: "10px",
  })

  
  export const MySelect = styled (Select)({
    width:"400px",
 
    borderRadius: "10px",
    background: "#E9E9E9",
    height: "45px",
    "& fieldset": { border: "none",  outline: 'none',
     },
     "@media (max-width: 768px)": {
      width:"300px"
         
       },
 })