import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
} from "react-router-dom";
import Layout from "./layout/Layout";
import DashboardComp from "./components/Dashboard/Dashboard";
import SignIn from "./components/authcomponents/signin/SignIn";
import ProtectedRoute from "./protectedRoutes/ProtectedRoute";

import AddCategory from "./pages/category/examcategory/AddCategory";
import ViewCategory from "./pages/category/examcategory/ViewCategory";
import AddPdf from "./pages/pdf/AddPdf";
import ViewPdf from "./pages/pdf/ViewPdf";
import AddMcqs from "./pages/mcqs/AddMcqs";
import ViewMcqs from "./pages/mcqs/ViewMcqs";
import AddVideo from "./pages/video/AddVideo";
import ViewVideo from "./pages/video/ViewVideo";
import AddMock from "./pages/mocktest/AddMock";
import ViewMock from "./pages/mocktest/ViewMock";
import AddPastPaper from "./pages/pastpaper/AddPastPaper";
import ViewPastPaper from "./pages/pastpaper/ViewPastPaper";
import AddBanner from "./pages/banner/AddBanner";
import ViewBanner from "./pages/banner/ViewBanner";
import AddUser from "./pages/user/AddUser";
import ViewUser from "./pages/user/ViewUser";
import ViewExamTable from "./pages/category/examcategory/AddExamTable";
import ViewSingleCategory from "./pages/category/examcategory/ViewSingleCategory";
import EditCat from "./pages/category/examcategory/EditCategory";
import ViewFieldTable from "./components/categorycomponents/addfield/ViewFieldTable";
import EditSubCat from "./pages/category/fieldcategory/editSubCat";
import ViewSingleSubcategory from "./pages/category/fieldcategory/viewsinglesubcategory";
import ViewLevelTable from "./components/categorycomponents/addlevel/ViewLevelTable";
import ViewSingleLevelCat from "./components/categorycomponents/addlevel/ViewSingleLevelCat";
import EditSubLevel from "./components/categorycomponents/addlevel/EditSubLevel";
import ViewSubjectTable from "./components/categorycomponents/addsubject/ViewSubjectTable";
import EditSubject from "./components/categorycomponents/addsubject/EditSubject";
import ViewSingleSubjectCat from "./components/categorycomponents/addsubject/ViewSingleSubjectCat";
import ViewChapterTable from "./components/categorycomponents/addchapter/ViewChapterTable";
import ViewSingleChapterCat from "./components/categorycomponents/addchapter/ViewSingleChapterCat";
import EditChapter from "./components/categorycomponents/addchapter/EditChapter";
import ViewSinglePdfCat from "./components/pdfcomponent/pdfform/ViewSinglePdfCat";
import EditPdf from "./components/pdfcomponent/pdfform/EditPdf";
import ViewSingleMcqsCat from "./components/mcqscomponents/ViewSingleMcqsCat";
import EditMcqs from "./components/mcqscomponents/EditMcqs";
import EditVideo from "./components/videocomponents/EditVideo";
import ViewSingleVideoCat from "./components/videocomponents/ViewSingleVideoCat";
import EditMocktest from "./components/mocktestcomponents/EditMocktext";
import ViewSingleMockCat from "./components/mocktestcomponents/ViewSingleMockCat";
import EditPastpaper from "./components/pastpapercomponents/EditPastpaper";
import ViewSinglePastpaperCat from "./components/pastpapercomponents/ViewSinglePastpaperCat";
import EditBanner from "./components/Bannercomponents/EditBanner";
import ViewSingleBannerCat from "./components/Bannercomponents/ViewSingleBannerCat";
import ViewSingleUserCat from "./components/usercomponents/ViewSingleUser";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute />, // Use ProtectedRoute as the main element
      children: [
        {
          path: "/", // Define a protected route for the Layout
          element: <Layout />,
          children: [
            {
              path: "/", // Nested route for Dashboard
              element: <DashboardComp />,
            },

            {
              path: "/addUser", // Define the path for AddPdf
              element: <AddUser />, // Assuming AddPdf component exists and should be rendered here
            },
            {
              path: "/viewUser", // Define the path for AddPdf
              element: <ViewUser />, // Assuming AddPdf component exists and should be rendered here
            },
            {
              path: "/viewSingleUserCat/:id", // Define the path for ViewSingleCategory
              element: <ViewSingleUserCat />, // Render the ViewSingleCategory component here
            },
            // EXAM
            {
              path: "/addCategory", // Define the path for AddCategory
              element: <AddCategory />, // Render the AddCategory component here
            },

            {
              path: "/viewCategory", // Define the path for ViewCategory
              element: <ViewCategory />, // Render the ViewCategory component here
            },
            {
              path: "/viewCategory/:id", // Define the path for ViewSingleCategory
              element: <ViewSingleCategory />, // Render the ViewSingleCategory component here
            },

            {
              path: "/editCategory/:id", // Define the path for EditCategory
              element: <EditCat />, // Render the EditCategory component here
            },

            {
              path: "/viewExamTable", // Define the path for ViewExam
              element: <ViewExamTable />, // Render the ViewExam component here
            },
            // Field
            {
              path: "/ViewFieldTable", // Define the path for ViewField
              element: <ViewFieldTable />, // Render the ViewField component here
            },
            {
              path: "/ViewSubCategory/:id", // Define the path for ViewSingleField
              element: <ViewSingleSubcategory />, // Render the ViewSingleField component here
            },
            {
              path: "/editSubCategory/:id", // Define the path for EditField
              element: <EditSubCat />, // Render the EditField component here
            },
            // Level
            {
              path: "/ViewLevelTable", // Define the path for ViewLevel
              element: <ViewLevelTable />, // Render the ViewLevel component here
            },
            {
              path: "/ViewSingleLevelCat/:id", // Define the path for AddField
              element: <ViewSingleLevelCat />, // Render the AddField component here
            },
            {
              path: "/EditSubLevel/:id", // Define the path for AddCategory
              element: <EditSubLevel />, // Render the AddCategory component here
            },
            // Subject
            {
              path: "/ViewSubjectTable", // Define the path for AddField
              element: <ViewSubjectTable />, // Render the AddField component here
            },
            {
              path: "/ViewSingleSubjectCat/:id", // Define the path for AddField
              element: <ViewSingleSubjectCat />, // Render the AddField component here
            },
            {
              path: "/EditSubject/:id", // Define the path for AddCategory
              element: <EditSubject />, // Render the AddCategory component here
            },
            // Chapter
            {
              path: "/ViewChapterTable", // Define the path for AddField
              element: <ViewChapterTable />, // Render the AddField component here
            },
            {
              path: "/ViewSingleChapterCat/:id", // Define the path for AddField
              element: <ViewSingleChapterCat />, // Render the AddField component here
            },
            {
              path: "/EditChapter/:id", // Define the path for AddCHAPTER
              element: <EditChapter />, // Render the AddCHAPTER component here
            },

            // pdf
            {
              path: "/addPdf", // Define the path for AddPdf
              element: <AddPdf />, // Assuming AddPdf component exists and should be rendered here
            },
            {
              path: "/view", // Define the path for ViewPdf
              element: <ViewPdf />, // Assuming ViewPdf component exists and should be rendered here
            },
            {
              path: "/ViewSinglePdfCat/:id", // Define the path for ViewSinglePDF
              element: <ViewSinglePdfCat />, // Render the ViewSinglePDF component here
            },
            {
              path: "/EditPdf/:id", // Define the path for PDFEdit
              element: <EditPdf />, // Render the PDFEdit component here
            },
            // MCQS
            {
              path: "/addMcqs", // Define the path for AddMCQS
              element: <AddMcqs />, // Render the AddMSQS component here
            },
            {
              path: "/ViewSingleMcqsCat/:id", // Define the path for ViewSinglePDF
              element: <ViewSingleMcqsCat />, // Render the ViewSinglePDF component here
            },
            {
              path: "/viewMcqs", // Define the path for ViewCategory
              element: <ViewMcqs />, // Render the ViewCategory component here
            },
            {
              path: "/EditMcqs/:id", // Define the path for PDFEdit
              element: <EditMcqs />, // Render the PDFEdit component here
            },
            {
              path: "/addvideo", // Define the path for AddCategory
              element: <AddVideo />, // Render the AddCategory component here
            },
            {
              path: "/viewVideo", // Define the path for ViewCategory
              element: <ViewVideo />, // Render the ViewCategory component here
            },
            {
              path: "/EditVideo/:id", // Define the path for PDFEdit
              element: <EditVideo />, // Render the PDFEdit component here
            },
            {
              path: "/ViewSingleVideoCat/:id", // Define the path for ViewSinglePDF
              element: <ViewSingleVideoCat />, // Render the ViewSinglePDF component here
            },

            {
              path: "/addMock", // Define the path for AddCategory
              element: <AddMock />, // Render the AddCategory component here
            },
            {
              path: "/viewMock", // Define the path for ViewCategory
              element: <ViewMock />, // Render the ViewCategory component here
            },
            {
              path: "/EditMocktest/:id", // Define the path for PDFEdit
              element: <EditMocktest />, // Render the PDFEdit component here
            },
            {
              path: "/ViewSingleMockCat/:id", // Define the path for ViewSinglePDF
              element: <ViewSingleMockCat />, // Render the ViewSinglePDF component here
            },

            {
              path: "/addPastpaper", // Define the path for AddCategory
              element: <AddPastPaper />, // Render the AddCategory component here
            },
            {
              path: "/viewPastpaper", // Define the path for ViewCategory
              element: <ViewPastPaper />, // Render the ViewCategory component here
            },
            {
              path: "/EditPastpaper/:id", // Define the path for PDFEdit
              element: <EditPastpaper />, // Render the PDFEdit component here
            },
            {
              path: "/ViewSinglePastpaperCat/:id", // Define the path for ViewSinglePDF
              element: <ViewSinglePastpaperCat />, // Render the ViewSinglePDF component here
            },

            {
              path: "/addBanner", // Define the path for AddCategory
              element: <AddBanner />, // Render the AddCategory component here
            },
            {
              path: "/viewBanner", // Define the path for ViewCategory
              element: <ViewBanner />, // Render the ViewCategory component here
            },
            {
              path: "/EditBanner/:id", // Define the path for PDFEdit
              element: <EditBanner />, // Render the PDFEdit component here
            },
            {
              path: "/ViewSingleBannerCat/:id", // Define the path for ViewSinglePDF
              element: <ViewSingleBannerCat />, // Render the ViewSinglePDF component here
            },
          ],
        },
      ],
    },
    {
      path: "signin",
      element: <SignIn />,
    },
  ]);

  return (
    <RouterProvider router={router}>
      <Routes />
    </RouterProvider>
  );
}

export default App;
