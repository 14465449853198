import { Select, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';  

export const SameStyleCells = styled (TableCell) ({
    color: "#062E4B",
    fontSize: "14px",
    fontWeight: "600",
})

export const SpansButtons = styled ("span")({
    margin: "4px",
    color: "#004A80",
    fontSize: "17px",
})