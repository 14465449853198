import React from "react";
// import { useGetMcqsDataQuery } from "../../features/mcqs/";
import { useGetMocktestDataQuery } from "../../features/Mock Test/MocktestSlice";

export const useGetMocktest = () => {
  const { data, isSuccess, isLoading, isError } = useGetMocktestDataQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  // console.log("data", data)
  const transformedDataNew = React.useMemo(() => {
    const getMocktestData = data || [];

    return getMocktestData?.map((catData) => ({
      id: catData?._id,
      categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
      subcategoryId: catData?.subcategoryId, // Assuming catdescription is an array
        // addlevel: catData?.addlevel, // Assuming catdescription is an array
        mockname: catData?.mockname, // Assuming catdescription is an array
      //   chapter: catData?.chapter, // Assuming catdescription is an array
      //   pdfname: catData?.pdfname, // Assuming catdescription is an array
      questions: catData.questions.map((question) => ({
        questionId: question.questionId,
        question: question.question,
        correct_answer: question.correct_answer,
        incorrect_answer: question.incorrect_answer,
        _id: question._id,
      })),
      //   logo: catData?.logo,
      //   exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
      status: catData?.isActive,
    }));
  }, [data]);

  return {
    MocktestData: transformedDataNew,
    isLoading,
    isSuccess,
  };
};
