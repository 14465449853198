import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const MocktestSlice = createApi({
  reducerPath: "MocktestSlice",
  tagTypes: ["MOCKTESTDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addmocktest: builder.mutation({
      query: (data) => ({
        url: "mocktest",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["MOCKTESTDATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),
     getSingleMocktestByID: builder.query({
      query: (id) => ({
        url: `mocktest/${id}`,
        method: "GET",
      }),
      providesTags: ["MOCKTESTDATA"],
   
    }),

    getMocktestData: builder.query({
      query: () => ({
        url: 'mocktest/admin',
        method: 'GET',
      }),
      providesTags: ["MOCKTESTDATA"],
    }),

    UpdateMocktestData: builder.mutation({
      query: (params) => ({
        url: `mocktest/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivatMocktest: builder.mutation({
      query: (id) => ({
        url: `mocktest/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["MOCKTESTDATA"],
    }),

    getDeActivateMocktest: builder.mutation({
      query: (id) => ({
        url: `mocktest/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["VIDEODATA"],
    }),

    deleteMocktest: builder.mutation({
      query: (id) => ({
        url: `mocktest/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MOCKTESTDATA"],
     
    }),
}),
});
export const { useAddmocktestMutation, useGetSingleMocktestByIDQuery, useGetMocktestDataQuery, useUpdateMocktestDataMutation, useGetActivatMocktestMutation ,useGetDeActivateMocktestMutation, useDeleteMocktestMutation } = MocktestSlice;