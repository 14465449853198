import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from 'react-pro-sidebar';

// import { MenuItem, Sidebar,Menu } from 'react-pro-sidebar';

//  export const MySidebar = styled(Sidebar)({
//    //  height: "100%",
    
//     backgroundColor: "#004A80",

//  })

//  export const FirstLogo = styled("div")({
//     backgroundColor: "#004A80",
//     display: "flex",
//     justifyContent: "center",
//     paddingTop: "20px",
//     width: "100%", // Set the width to 100%  
//  })

//  export const MenuContainer = styled(Menu)({
//     backgroundColor: "#004A80",

    
//  })

//  export const SideMenuitems = styled(MenuItem)({
//     marginTop: "12px",
//     paddingTop: "10px",
//     paddingBottom: "10px",
    
//  })

//  export const SideMenuMainContainer = styled("div")({
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//  })

//  export const SidebarMenuImage = styled("img")({

//     width:"20px",
//      filter: "brightness(0) invert(1)" 
//  })


//  export const SideMenuTextContainer = styled("div")({
//     marginTop: "5px",
//     fontWeight: "300",
//     fontFamily: "Montserrat",
//     fontSize: "12px",
//     color: "white",
//  })

 
//  export const SubMenuItems = styled("div")({
// /* Your styles for the submenu items */
//  })




 
export const MainConatinerDiv = styled("div")({
  height: "100%", 
  backgroundColor: "#094C7D"
})

export const LogoDiv = styled("div")({
  display:"flex", justifyContent:"center", padding:"20px"
})

// SideBarStyles.jsx
export const verticalItemStyle = {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
  //  justifyContent:"center"
 };


 export const FirstMenuItemDiv = styled(MenuItem)({
  ...verticalItemStyle,

  height: "75px",

 


})

 
export const FirstMenuItemInnerDiv = styled("div")({
  display: "flex", justifyContent: "center",
  
})

export const MenuItemImage = styled("img")({
  margin: "6px", width: "20px"
})


export const MyMenu = styled(Menu)({
  background: "#094C7D", color: "white" 
})
 
 export const submenuStyle = {
   "& .sidebar-btn-toggle": {
     display: "none",
   },
 };
 
