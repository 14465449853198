import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditCategory } from "../../../customhook/category/useEditCategory";
// import { useGetSubCategory } from "../../../customhook/field-category/useGetSubCategory";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEditSubCategory } from "../../../customhook/field-category/useEditSubCategory";
import {
  MySelect,
  SelectFieldsHeads,
} from "../../../styles/categorycompstyles/addfieldstyle/AddFieldsStyle";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useGetCategory } from "../../../customhook/category/useGetCategory";
import useFieldCategoryForm from "../../../customhook/field-category/useAddSubCategory";

const EditSubCategory = () => {
  const { CategoryData } = useGetCategory();

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    editSubCategoryForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading,
    issuccess,
  } = useEditSubCategory(id || "");
  const {
    formData,
    // handleSubmit,
    handleInputChange,
    // handleImageChange,
    // handleIconChange,
    // isError,
    // isLoading,
    // isSuccess,
    // error,
  } = useFieldCategoryForm();
  console.log("editSubCategoryForm", editSubCategoryForm);
  // console.log("editSubCategoryForm?.categoryId",editSubCategoryForm?.categoryId)

  if (isloading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (issuccess) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        marginY="1rem"
      >
        <Paper
          elevation={3}
          style={{ width: "300px", padding: "10px", margin: "0 0" }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Edit SubCategory
          </Typography>
          <form onSubmit={handleSubmit}>
            <div>
              <Box>
                <SelectFieldsHeads>Choose Exam</SelectFieldsHeads>
              </Box>
              <div>
                <FormControl sx={{ mb: 1 }} size="">
                  <Select
                    style={{ width: "280px" }}
                    value={editSubCategoryForm?.categoryId}
                    onChange={handleChange}
                    name="categoryId"
                  >
                    {/* {SubLevelData?.categoryId && SubLevelData?.categoryId?.map((SubLevelData) => ( */}
                    {CategoryData &&
                      CategoryData?.map(
                        (CategoryData) => (
                          // category?.categoryId?.map((exam) => (
                          <MenuItem
                            key={CategoryData?.id}
                            value={CategoryData?.id}
                          >
                            {CategoryData?.examname}
                          </MenuItem>
                        )
                        // ))}
                        // ))
                      )}
                  </Select>
                </FormControl>
              </div>
              <Box mb={1}>
                <TextField
                  fullWidth
                  label="subname"
                  id="subname"
                  name="subname"
                  value={editSubCategoryForm?.subname}
                  onChange={handleChange}
                  required
                />
              </Box>
            </div>
            {/* <Box mb={1}>
              <TextField
                fullWidth
                type="file"
             
                id="logo"
                name="logo"
                onChange={handleFileChange}
              />
              {editSubCategoryForm?.logo && (
                <img
                  src={editSubCategoryForm?.logo}
                  alt="Logo Preview"
                  className="preview-image"
                  style={{ marginTop: "4px", width: "100%", height: "120px" }}
                />
              )}
            </Box> */}
            {/* <Box mb={1}>
              <TextField
                fullWidth
                type="file"
             
                id="exambanner"
                name="exambanner"
                onChange={handleFileChange}
              />
              {editSubCategoryForm?.exambanner && (
                <img
                  src={editSubCategoryForm?.exambanner}
                  alt="Exam Banner Preview"
                  className="preview-image"
                  style={{ marginTop: "4px", width: "100%", height: "120px" }}
                />
              )}
            </Box> */}
            <Box textAlign="center" mt={2} mb={0}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="custom-btn"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    );
  }
};

export default EditSubCategory;

// import React, { useState, useEffect } from "react";
// // import {
// //   useGetsingleSubategoryByIDQuery,
// //   useUpdateCategoryDataMutation,
// // } from "../../features/category/CategorySlice";
// import { useGetSinglesubCategoryByIDQuery, useUpdatesubCategoryDataMutation } from "../../../features/field/FieldSlice";
// import { useNavigate } from "react-router-dom";

// export const useEditSubCategory = (id) => {
//   const navigate = useNavigate();
//   const [editSubCategoryForm, seteditSubCategoryForm] = useState({
//     categoryId: "",
//     subname: "",
//     logo: "",
//     exambanner: "",
//     // Add more fields as needed
//   });

//   const {
//     data: singleSubategory,
//     isLoading,
//     isSuccess,
//   } = useGetSinglesubCategoryByIDQuery(id, {
//     refetchOnMountOrArgChange: true,
//   });

//   useEffect(() => {
//     if (singleSubategory) {
//       seteditSubCategoryForm({
//         categoryId: singleSubategory?.category?.categoryId,
//         subname: singleSubategory?.category?.subname,
//         logo: singleSubategory?.category?.logo,
//         exambanner: singleSubategory?.category?.exambanner,
//         // Update with additional fields if needed
//       });
//     }
//   }, [singleSubategory]);

//   const imageUrl = editSubCategoryForm.exambanner;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     seteditSubCategoryForm((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files?.[0] || null;
//     seteditSubCategoryForm({ ...editSubCategoryForm, exambanner: file });
//   };

//   const [UpdateCategoryData] = useUpdatesubCategoryDataMutation();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const formData = new FormData();

//       formData.append("categoryId", editSubCategoryForm.categoryId);
//       formData.append("subname", editSubCategoryForm.subname);
//       formData.append("logo", editSubCategoryForm.logo);
//       formData.append("exambanner", editSubCategoryForm.exambanner);

//       // Update with additional fields if needed

//       const response = (await UpdateCategoryData({
//         id,
//         data: formData,
//       })).data;

//       if (response.success === true) {
//         window.alert(response.message);
//         navigate("/ViewFieldTable");
//         // navigate("/viewExamTable");
//       }
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };

//   return {
//     editSubCategoryForm,
//     handleChange,
//     handleFileChange,
//     handleSubmit,
//     isloading: isLoading,
//     issuccess: isSuccess,
//     imageUrl,
//   };
// };
