import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSinglesubCategoryByIDQuery, useUpdateSubCategoryDataMutation } from "../../features/field/FieldSlice";

export const useEditSubCategory = (id) => {
  const navigate = useNavigate();
  const [editSubCategoryForm, setEditSubCategoryForm] = useState({
    categoryId: "",
    subname: "",
    // logo: "",
    // exambanner: "",
    // Add more fields as needed
  });

  const {
    data: singlecategory,
    isLoading,
    isSuccess,
  } = useGetSinglesubCategoryByIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (singlecategory) {
      setEditSubCategoryForm({
        categoryId: singlecategory?.category?.categoryId?._id,
        subname: singlecategory?.category?.subname,
        // logo: singlecategory?.category?.logo,
        // exambanner: singlecategory?.category?.exambanner,
        // Update with additional fields if needed
      });
    }
  }, [singlecategory]);


  const imageUrl = editSubCategoryForm.exambanner;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditSubCategoryForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0] || null;
    setEditSubCategoryForm({ ...editSubCategoryForm, exambanner: file });
  };

  const [UpdateSubCategoryData] = useUpdateSubCategoryDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const jsonData = {
        categoryId: editSubCategoryForm.categoryId,
        subname: editSubCategoryForm.subname,
        // Add more fields as needed
      };
  
      const response = await UpdateSubCategoryData({
        id,
        data: jsonData,
      });
  
      if (response.data.success === true) {
        window.alert(response.data.message);
        navigate("/viewFieldTable");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  
  return {
    editSubCategoryForm,
    handleChange,
    handleFileChange,
    handleSubmit,
    isloading: isLoading,
    issuccess: isSuccess,
    imageUrl,
  };
};




// import React from "react";
// import { useGetdashboardQuery } from "../../features/field/FieldSlice";
// // import { useGetCategoryDataQuery } from "../../features/category/CategorySlice";

// export const useGetSubCategory = () => {
//   const { data, isSuccess, isLoading } = useGetdashboardQuery(
//     {},
//     {
//       refetchOnMountOrArgChange: true,
//     }
//   );
// // console.log("Abdar khan", transformedData)

//   const transformedData = React.useMemo(() => {
//     const getCategoryData = data || [];
//     return getCategoryData?.map((catData) => ({
//       id: catData?._id,
//       categoryId: catData?.categoryId, // Replace 'name' with the correct attribute name if needed
//       subname: catData?.subname, // Assuming catdescription is an array
//       logo: catData?.logo,
//       exambanner: catData?.exambanner, // Assuming exambanner holds the banner image
//       status:catData?.isActive
//     }));
//   }, [data]);

//   return {
//     CategoryData: transformedData,
//     isLoading,
//     isSuccess,
//   };
// };
