import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import logo2 from "../../../assets/logo2.png";
import logimg from "../../../assets/logimg.png";
import fb from "../../../assets/fb.png";
import x from "../../../assets/x.png";
import insta from "../../../assets/insta.png";
import lk from "../../../assets/lk.png";

import ForgotPassModal from "../../modals/ForgotPassModal";

import {
  LeftSideMainContainer,
  LeftSideLogo,
  LeftSideFirstHead,
  LeftSidePara,
  RightSideMainGrid,
  RightSideMainDiv,
  LoginTextDiv,
  InfoTextDiv,
  LoginTextFields,
  LoginButton,
  LastText,
  IconsImages,
  ImagesMainDiv,
} from "../../../styles/authcomponents/signin/styleSignIn";
import { useState } from "react";
import { useLogin } from "../../../customhook/useLogin";
const SignIn = () => {
  const {user,handleSubmit, handleChange,error} = useLogin()
  // console.log(user)
  

  
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  


  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <LeftSideMainContainer style={{}}>
            <LeftSideLogo style={{}}>
              <img src={logo2} alt="" />
            </LeftSideLogo>
            <LeftSideFirstHead style={{}}>Preparely</LeftSideFirstHead>
            <div>
              <LeftSidePara style={{}}>
                A New way to Learn Learn Through Video Lesson, PDF Documents,
                Mock Up Exam And MCQS.
              </LeftSidePara>
            </div>

            <div style={{ marginTop: "20px" }}>
              <img src={logimg} alt="" style={{width:"100%",height:"100%",objectFit:"cover"}} />
            </div>
          </LeftSideMainContainer>
        </Grid>

        <RightSideMainGrid item xs={12} sm={6} md={6} sx={{}}>
          <RightSideMainDiv>
            <LoginTextDiv style={{}}>Login</LoginTextDiv>
            <InfoTextDiv style={{}}>
              Where Information Meets Innovation.
            </InfoTextDiv>
            <form >
            <div>
              <LoginTextFields 
              name="email"
              placeholder="Username" sx={{}}
              onChange={handleChange}
              // type="email"
              value={user.email}
              />
            </div>

            <div>
              <LoginTextFields 
              name="password"
              placeholder="Password" sx={{}}
              onChange={handleChange}
              type="password"
              value={user.password}
              />
            </div>

            <div>
              <LoginButton style={{}} variant="contained" onClick={handleSubmit} >
                Let's Start
              </LoginButton>
            </div>
            {error && <Typography variant="body2" color="error">{error}</Typography>}
            </form>
            <LastText onClick={openModal} style={{cursor:"pointer"}}>Forget Password?</LastText>
             <ForgotPassModal open={modalOpen} handleClose={closeModal} />
            <ImagesMainDiv>
              <IconsImages src={fb} alt="" />
              <IconsImages src={x} alt="" />
              <IconsImages src={insta} alt="" />
              <IconsImages src={lk} alt="" />
            </ImagesMainDiv>
          </RightSideMainDiv>
        </RightSideMainGrid>
      </Grid>
    </>
  );
};

export default SignIn;

