// ButtonComponent.js
import React from 'react';
import { Button } from '@mui/material';
const ButtonComponent = ({ onClick, pageName, active }) => {
  return (
    <Button
      onClick={() => onClick(pageName)}
      style={{
          margin: "8px",
  borderRadius: "10px",
  // background: "#F4F4F4",
  color: "black",
  textTransform: "capitalize",
        // Your button styles here
        backgroundColor: active ? '#004A80' : '#F4F4F4', // Change color based on active state
       color:active ? 'white' : "black"
        // Other styles
      }}
    >
      {pageName}
    </Button>
  );
};

export default ButtonComponent;
