import { useState } from "react";
// import { useAddchapterMutation } from "../../features/chapters/ChapterSlice";
import { useAddpdfMutation } from "../../features/pdf/PdfSlice";

const usePdfForm = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
    subject: "",
    chapter: "",
    pdfname: "",
    description: "",
    uploadfile: "",
  });

  const [addpdf, { isLoading, isError, isSuccess, error }] =
  useAddpdfMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
   const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      uploadfile: imageFile,
    }));
  };
  const handleRemoveClick = () => {
    setFormData({
      uploadfile: null,
      chapter: '', // Clear subject field
    });
  };
  const handleAddClick = () => {
    document.getElementById('imageInput').click();
  };

  const handleIconChange = (event) => {
    const iconFile = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      exambanner: iconFile,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
   
    try {
      const FormValues = new FormData();
      FormValues.append("categoryId", formData.categoryId);
      FormValues.append("subcategoryId", formData.subcategoryId);
      // FormValues.append("Addlevel", formData.Addlevel);
      if (formData.Addlevel === "" && formData.Addlevel) {
        FormValues.append("Addlevel", formData.Addlevel);
      }
      FormValues.append("subject", formData.subject);
      FormValues.append("chapter", formData.chapter);
      FormValues.append("pdfname", formData.pdfname);
      FormValues.append("description", formData.description);
      FormValues.append("uploadfile", formData.uploadfile);
    //   FormValues.append("exambanner", formData.exambanner);
      const response = await addpdf(FormValues).unwrap();
      console.log(response);
     
    } catch (error) {
      console.log(error);
    }
  };

  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    handleImageChange,
    handleIconChange,
    handleSubmit,
    handleRemoveClick,
    handleAddClick,
  };
};

export default usePdfForm;