import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useGetSingleLevelByIDQuery } from "../../../features/level/AddLevelSlice";
import { useGetSingleSubjectByIDQuery } from "../../../features/subject/AddSubjectSlice";

const ViewSingleSubjectCat = () => {
  const params = useParams();
  const location = useLocation();

  //   console.log("params",location)
  const { data, isLoading, isSuccess } = useGetSingleSubjectByIDQuery(
    params.id
  );
  // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAa", isSuccess);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.level) {
    return <div>Error loading data...</div>;
  }

  const { level } = data;
  console.log(data, "level");

  const activeColor = "#4CAF50"; // Green
  const inactiveColor = "#f44336"; // Red

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", textAlign: "left", margin: "15px" }}
    >
      <Grid container spacing={3}>
        {/* Right Side - Exam Name, Full Name, and Active Status */}

        <Grid item xs={6}>
          <Typography variant="h6" color="primary">
            uploadfile:
          </Typography>
          <img
            src={level?.uploadfile}
            alt="Logo"
            style={{
              width: "150px",
              height: "auto",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            crossOrigin="anonymous"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="primary">
            Exam Name:
          </Typography>
          {Array.isArray(level.categoryId) ? (
            level.categoryId.map((category) => (
              <Typography key={category._id}>{category.examname}</Typography>
            ))
          ) : (
            <Typography>{level.categoryId.examname}</Typography>
          )}

          <Typography variant="h6" color="primary">
            Field Name:
          </Typography>
          {Array.isArray(level.categoryId) ? (
            level.subcategoryId.map((subcategory) => (
              <Typography key={subcategory._id}>{subcategory.subname}</Typography>
            ))
          ) : (
            <Typography>{level.subcategoryId.subname}</Typography>
          )}

          {/* <Typography variant="h6" color="primary">
            Level Name:
          </Typography>
          {Array.isArray(level.Addlevel) ? (
            level.Addlevel.map((Addlevel) => (
              <Typography key={Addlevel._id}>{Addlevel.Addlevel}</Typography>
            ))
          ) : (
            <Typography>{level.Addlevel.Addlevel}</Typography>
          )} */}

          {/* <Typography
                variant="h6"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Field Name:
              </Typography> */}
          {/* <Typography>{level.subcategoryId[0].subname}</Typography> */}
          {/* <Typography>{level?.subcategoryId}</Typography> */}

          {/* {location?.state?.subcategoryId?.map((name)=>(
                <Typography>
                {name.subname}
                </Typography>
              ))} */}
          
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Subject Name:
          </Typography>
          <Typography>{level?.subject}</Typography>

          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Status:
          </Typography>
          <Typography
            style={{
              color: level.isActive ? activeColor : inactiveColor,
            }}
          >
            {level.isActive ? "Active" : "Inactive"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewSingleSubjectCat;
