import React from 'react'
import AddVideoFrom from "../../components/videocomponents/AddVideoForm"
const AddVideo = () => {
  return (
  <>
<AddVideoFrom/>
  </>
  )
}

export default AddVideo