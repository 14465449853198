import { useState } from "react";
import { useAddBannerMutation } from "../../features/banner/BannerSlice";

const useBannerForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    description: "",
    bannerImage: [],
  });

  const [addBanner, { isLoading, isError, isSuccess, error }] =
    useAddBannerMutation();

  const handleRemoveClick = (index) => {
    const updatedBannerImages = [...formData.bannerImage];
    updatedBannerImages.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      bannerImage: updatedBannerImages,
    }));
  };

  const handleAddClick = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bannerImage: [...prevFormData.bannerImage, ""],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIncorrectAnswerChange = (index, value) => {
    const updatedBannerImages = [...formData.bannerImage];
    updatedBannerImages[index] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      bannerImage: updatedBannerImages,
    }));
  };

  const handleFileSelected = (event) => {
    const files = Array.from(event.target.files);
    setFormData((prevFormData) => ({
      ...prevFormData,
      bannerImage: [...prevFormData.bannerImage, ...files],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("title", formData.title);
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("description", formData.description);
      formData.bannerImage.forEach((image) => {
        formDataToSubmit.append("bannerImage", image);
      });

      const response = await addBanner(formDataToSubmit).unwrap();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    handleAddClick,
    handleRemoveClick,
    handleIncorrectAnswerChange,
    handleFileSelected,
    handleSubmit,
  };
};

export default useBannerForm;