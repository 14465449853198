import React from 'react'
import ViewMockTable from '../../components/mocktestcomponents/ViewMockTable'

const ViewMock = () => {
  return (
 <>
 <ViewMockTable/>
 </>
  )
}

export default ViewMock