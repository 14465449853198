import React from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useGetSinglesubCategoryByIDQuery } from '../../../features/field/FieldSlice';

const ViewSingleSubCat = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetSinglesubCategoryByIDQuery(id);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.category) {
    return <div>Error loading data...</div>;
  }

  const { category } = data;
  const activeColor = '#4CAF50'; // Green
  const inactiveColor = '#f44336'; // Red

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      {/* Full Width - Logo, Exambanner, Examname, Full_name, and Active Status */}
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px', textAlign: 'left' }}>
          <Grid container spacing={3}>
            {/* Left Side - Logo and Exambanner */}
            {/* <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Logo:
              </Typography>
              <img
                src={category.logo}
                alt="Logo"
                style={{ width: '150px', height: 'auto', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              />

              <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
                Banner:
              </Typography>
              <img
                src={category.exambanner}
                alt="Banner"
                style={{
                  width: '150px',
                  height: 'auto',
                  borderRadius: '8px',
                  marginTop: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid> */}

            {/* Right Side - Exam Name, Full Name, and Active Status */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Exam Name:
              </Typography>
              <Typography>{category.categoryId.examname}</Typography>
              {/* <Typography>{category.categoryId}</Typography> */}

              <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
                Field Name:
              </Typography>
              <Typography>{category.subname}</Typography>

              <Typography variant="h6" color="primary" style={{ marginTop: '10px' }}>
                Status:
              </Typography>
              <Typography style={{ color: category.isActive ? activeColor : inactiveColor }}>
                {category.isActive ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ViewSingleSubCat;
