import React from "react";
import deleteicon from "../../../assets/deleteicon.png"
import sort from "../../../assets/sort.png"
import {
  MySearch,
  MySearchIcon,
  MyInputBase,
  ButtonsMainDiv,
  LastButtonsDiv,
  DeleteButton,
  DeleteButtonImg,
  SortButton,
  SortButtonImg,
  AddNewButton,
  SpanText,
} from "./ButtonsCompStyle";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import edit from "../../../assets/edit.png"
import view from "../../../assets/view.png"
const AddExamButtonComp = () => {
  return (
    <>
      <ButtonsMainDiv >
        <div>
          <DeleteButton style={{}} variant="contained">
            <DeleteButtonImg src={deleteicon} alt="Image" />
            <SpanText>
              delete
            </SpanText>
          </DeleteButton>
        </div>

        <LastButtonsDiv >
        <div>
            <AddNewButton  variant="contained">
              <SpanText >
                +AddNew
              </SpanText>
            </AddNewButton>
          </div>
          <div>
            <SortButton style={{marginLeft:"10px"}} variant="contained" >
              <SortButtonImg src={sort} alt="Image"  />
              <SpanText>
                Sort
              </SpanText>
            </SortButton>
          </div>

          <div>
            <div>
              <MySearch>
                <MyInputBase placeholder="Search here" />
                <MySearchIcon></MySearchIcon>
              </MySearch>
            </div>
          </div>

          

          <div style={{ marginTop: "6px" }}>
          <div style={{ display: "flex", justifyContent: "center",alignItems:"" }}>
            <Button
              style={{
                background: "#094C7D",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "capitalize",

                color: "#FFFFFF",
                paddingLeft: "18px",
              }}
              href=""
            >
              <img
                src={edit}
                alt="Your Image"
                style={{ marginRight: "8px", width: "20px" }}
              />
              Edit
            </Button>

            <Link
            to="" // Replace this with the actual path or URL
            style={{
              textDecoration: 'none', // Remove underline if necessary
              background: '#E8E8E8',
              textTransform: 'capitalize',
              borderTopRightRadius: '20px',
              borderBottomRightRadius: '20px',
              color: '#094C7D',
              display: 'flex',
              alignItems: 'center',
              padding: '10px 15px', // Adjust padding as needed
            }}
          >
            <img
              src={view}
              alt="Your Image"
              style={{ marginRight: '6px', width: '22px' }}
            />
            View
          </Link>
          </div>
        </div>
        </LastButtonsDiv>
      </ButtonsMainDiv>
    </>
  );
};

export default AddExamButtonComp;
