import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Hidden } from '@mui/material';
import { useAuth } from '../../../context/AuthContext'; 

import logo from "../../../assets/logo.png";
import { MyAvatar } from '../../../styles/TopBarStyle/TopBarStyle';

import {
  MyAppBar,MyToolbar,MyLogo,MyIconsContainer,MyIcon,MySearch,MySearchIcon,MyInputBase

} from "../../../styles/TopBarStyle/TopBarStyle"

const TopBar = () => {
  const user = JSON.parse(localStorage.getItem("userData"));

  return (
    <MyAppBar position="static">
      <MyToolbar>
        <Hidden>
          <MySearch>
            <MyInputBase placeholder="" />
            <MySearchIcon>
              <SearchIcon />
            </MySearchIcon>
          </MySearch>
        </Hidden>
        <MyIconsContainer>
          <MyIcon color="">
            <MailIcon />
          </MyIcon>
          <MyIcon color="">
            <NotificationsIcon />
          </MyIcon>
          <Hidden smDown>
            <Typography variant="subtitle1" color="" style={{color:"#004A80", marginRight: '15px',fontFamily:"Poppins",fontWeight:"400",fontSize:"16px" }}>
              {user && user.username ? user.username : 'Guest'}
            </Typography>
          </Hidden>
          <Hidden smDown>
            <MyAvatar />
          </Hidden>
        </MyIconsContainer>
      </MyToolbar>
    </MyAppBar>
  );
};

export default TopBar;
