import React from 'react'
import AddMcqsForm from '../../components/mcqscomponents/AddMcqsForm'

const AddMcqs = () => {
  return (
   <>
   <AddMcqsForm/>
   </>
  )
}

export default AddMcqs