import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let token = localStorage.getItem("authToken");

export const PastpaperSlice = createApi({
  reducerPath: "PastpaperSlice",
  tagTypes: ["PAPERDATA"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
  endpoints: (builder) => ({
    addpaper: builder.mutation({
      query: (data) => ({
        url: "pastpaper",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["PAPERDATA"], //REFRESH LEVELDATA WHICH WAS CACHED
    }),
     getSinglePastpaperByID: builder.query({
      query: (id) => ({
        url: `pastpaper/${id}`,
        method: "GET",
      }),
      providesTags: ["PAPERDATA"],
   
    }),

    getPastpaperData: builder.query({
      query: () => ({
        url: 'pastpaper/admin',
        method: 'GET',
      }),
      providesTags: ["PAPERDATA"],
    }),

    UpdatePastpaperData: builder.mutation({
      query: (params) => ({
        url: `pastpaper/${params.id}`,
        method: "PUT",
        body: params.data,
      }),

    }),

    getActivatPastpapertest: builder.mutation({
      query: (id) => ({
        url: `pastpaper/${id}/activate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["PAPERDATA"],
    }),

    getDeActivatePastpapertest: builder.mutation({
      query: (id) => ({
        url: `pastpaper/${id}/deactivate`,
        method: "PUT", // 'Put' should be 'PUT' in uppercase
      }),
      invalidatesTags: ["VIDEODATA"],
    }),

    deletePastpapertest: builder.mutation({
      query: (id) => ({
        url: `pastpaper/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PAPERDATA"],
     
    }),
}),
});
export const { useAddpaperMutation, useGetSinglePastpaperByIDQuery, useGetPastpaperDataQuery, useUpdatePastpaperDataMutation, useGetActivatPastpapertestMutation ,useGetDeActivatePastpapertestMutation, useDeletePastpapertestMutation } = PastpaperSlice;