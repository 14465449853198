import { Button, TablePagination } from "@mui/material";
import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import { SameStyleCells, SpansButtons } from "./ViewCategoryStyle";
import ButtonsComponent from "../../globalcomponents/SecondTopBarOutlet/ButtonsComponent";

const ViewCat = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Sample data for the table (Replace this with your actual data)
  const rows = [
    { id: 1, examName: 'MDCAT', field: '.', level: 'Part 1', subject: 'English', chapter: 'Young Seagull', pdf: 'PDF', status: 'Active' },
    { id: 2, examName: 'MDCAT', field: '.', level: 'Part 1', subject: 'English', chapter: 'Young Seagull', pdf: 'PDF', status: 'Active' },
    { id: 3, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
    { id: 4, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
    { id: 5, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
    // Five more rows
    { id: 6, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },
    { id: 7, examName: '', field: '', level: '', subject: '', chapter: '', pdf: '', status: '' },

  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <ButtonsComponent />
      <div style={{ padding: '20px' }}>
      <TableContainer component={Paper} style={{ border: '1px solid #DEDEDE' }}>
        <Table>
          <TableHead style={{ background: '#F3F3F3', boxShadow: 'none' }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Exam Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Field</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Level</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Subject</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Chapter</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>PDF</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const allFieldsFilled = (
                row.examName &&
                row.field &&
                row.level &&
                row.subject &&
                row.chapter &&
                row.pdf &&
                row.status
              );
          
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox style={{ color: '#EEEEEE' }} />
                  </TableCell>
                  <TableCell>{row.examName}</TableCell>
                  <TableCell>{row.field}</TableCell>
                  <TableCell>{row.level}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{row.chapter}</TableCell>
                  <TableCell>{row.pdf}</TableCell>
                  <TableCell style={{ color: 'green', fontWeight: 'bold' }}>{row.status}</TableCell>
                  <TableCell>
                    {allFieldsFilled && (
                      <>
                    
                        <SpansButtons>
                          <i className="fas fa-eye"></i>
                        </SpansButtons>
                       
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          }
          
          
          
          
          
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </>
  );
};

export default ViewCat;