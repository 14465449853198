import { Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FirstDiv = styled("div")({
  marginLeft: "40px",
  "@media (max-width: 767px)": {
    marginLeft: "5px",
  },
});

export const TitleDiv = styled("div")({
  fontFamily: "Poppins",
  fontWeight: "800",
  fontSize: "26px",
  color: "#06375A",
  //   marginLeft: "30px",
  "@media (max-width: 767px)": {
    marginLeft: "0px",
  },
});

export const SelectFieldsHeads = styled(Typography)({
  fontFamily: "Segoe UI",
  fontWeight: "600",
  fontSize: "20px",
  marginTop: "2px",
  marginLeft: "10px",
});

export const DescDiv = styled("div")({
  width: "400px",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "16px",
  color: "#1F1E24",

  lineHeight: "27.5px",
  //   marginLeft: "30px",
  "@media (max-width: 767px)": {
    width: "280px",
    fontSize: "15px",
    marginLeft: "0px",
  },
});

export const PdfNameField = styled(TextField)({
  width: "340px",
  //   marginLeft: "8px",
  marginTop: "8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  marginBottom: "6px",
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  height: "90px",
  "& fieldset": { border: "none", outline: "none" },

  "@media (max-width: 820px)": {
    width: "270px",
  },

  "@media (max-width: 768px)": {
    width: "280px",
  },
});

export const PdfDescField = styled(TextField)({
  width: "340px",
  //   marginLeft: "8px",
  marginTop: "8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  marginBottom: "7px",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  height: "45px",

  "& fieldset": { border: "none", outline: "none" },

  "@media (max-width: 820px)": {
    width: "270px",
  },

  "@media (max-width: 768px)": {
    width: "275px",
  },
  "@media (max-width: 767px)": {
    width: "280px",
  },
});

export const UploadPDfTextField = styled(TextField)({
  width: "340px",
  marginTop: "10px",
  //   marginLeft:"8px",
  borderRadius: "10px",
  background: "#E9E9E9",
  height: "45px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& fieldset": { border: "none", outline: "none" },

  "@media (max-width: 820px)": {
    width: "277px",
    marginLeft: "2px",
  },

  "@media (max-width: 768px)": {
    width: "280px",
    marginLeft: "2px",
  },
});

export const ImageDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems:"end",
  height: "400px", 


  "@media (max-width: 768px)": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "@media (max-width: 767px)": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const BannerImg = styled("img")({
  width: "400px",
  height: "200px",

  "@media (max-width: 912px)": {
    marginTop: "10px",
    marginLeft:"50px",
    width: "260px",
    height: "200px",
  },

  "@media (max-width: 820px)": {
    marginTop: "10px",
    marginLeft:"50px",
    width: "230px",
    height: "180px",
  },

  "@media (max-width: 768px)": {
    marginTop: "10px",
    marginLeft:"50px",
    width: "230px",
    height: "180px",
  },

  "@media (max-width: 767px)": {
    marginTop: "10px",
 marginLeft:"13px",
    width: "270px",
    height: "200px",
  },
});
