import { useState } from "react";
import { useAddLevelMutation } from "../../features/level/AddLevelSlice";

const useLevelForm = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    subcategoryId: "",
    Addlevel: "",
  });
  const [addLevel, { isLoading, isError, isSuccess, error }] =
    useAddLevelMutation();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // console.log(formData,"abbcc")
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const FormValues = new FormData();
      // FormValues.append("categoryId", formData.categoryId);
      // FormValues.append("subcategoryId", formData.subname);
      // FormValues.append("Addlevel", formData.level);
      // console.log('form values from add level',FormValues);
      const response = await addLevel(formData);
      console.log('response->',response);
    } catch (error) {
      console.log('error->',error);
    }
  };
  return {
    formData,
    isLoading,
    isError,
    isSuccess,
    error,
    handleInputChange,
    handleSubmit,
  };
};
export default useLevelForm;
